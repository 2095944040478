import { useQuery } from '@tanstack/react-query';
import { ModuleConfig } from 'pages/AnalyticsV2/types';
import experienceGraphService from 'services/api/experience-graph.api';

type UseExperienceGraphsResult = {
  graphs: ModuleConfig[];
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
};

export default function useExperienceGraphs(): UseExperienceGraphsResult {
  const queryKey = ['experience-graphs'];

  const {
    data: moduleConfigs,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => experienceGraphService.getExperienceGraphs(),
    refetchOnWindowFocus: false,
  });

  const graphs = moduleConfigs?.data?.length ? moduleConfigs.data : [];

  return {
    graphs,
    isLoading,
    isError,
    isFetching,
  };
}
