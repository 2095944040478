import { getPushPayloadType } from 'store/push/push.selector';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { generateActionlinkFieldKey } from 'helpers/push.helper';
import { getBackendJourneyErrors } from 'helpers/error.helper';
import { isUrlValid, generateBasicValidator } from 'validators/ExperienceCanvas/common.validator';
import { ValidatorObject, ValidatorData, JourneyError } from 'validators/ExperienceCanvas/types';
import { RootState } from 'store/store';
import { PushFormFields, PushUniversalActionTypes } from 'components/ExperienceCanvas/constants';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';

export const PushValidators = (() => {
  const validator: ValidatorObject = {};
  // create all automatic validators (using basic validation)
  for (const [key, value] of Object.entries(PushFormFields)) {
    validator[key] = generateBasicValidator(value.basicValidation);
  }
  // overrides
  validator['url'] = (data: ValidatorData, key: string, isDraft = false) => {
    const allowNonHttps = data['allowNonHttps']?.value;

    return allowNonHttps
      ? generateBasicValidator('string')(data, key, isDraft)
      : generateBasicValidator('url')(data, key, isDraft);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validator['actionScheme'] = (data: ValidatorData, key: string, isDraft = false) => {
    const value = data[key]?.value;
    return typeof value !== 'string' || !isUrlValid(value, false)
      ? { type: ERROR_TYPES.ERROR, err: 'Invalid action link.' }
      : null;
  };
  validator['contentId'] = generateBasicValidator('string');
  // create actionscheme validators
  PushUniversalActionTypes.forEach((at) => {
    at.fields.forEach((f, idx) => {
      const key = generateActionlinkFieldKey(at, idx);
      validator[key] = (data: ValidatorData, key: string, isDraft = false) => {
        const bypassStrictValidation = data['bypassStrictValidation']?.value;

        return bypassStrictValidation
          ? generateBasicValidator('string', false)(data, key, isDraft)
          : generateBasicValidator(at.basicValidation, false)(data, key, isDraft);
      };
    });
  });

  return validator;
})();

export function getPushErrorList(state: RootState, pushRefId: string, checkJourneyErrors = true): JourneyError[] {
  const pushType = getPushPayloadType(state, pushRefId)?.type ?? 'basic';
  const push = state.te.push.byRefId[pushRefId];
  const defaultLang = getDefaultLanguage(state.settings.languages);

  // gather a list of localizations to validate
  // empty keys are not added ... except if they are the default language
  const localizations = new Set<string>([defaultLang]);
  for (const [lang, val] of Object.entries(push.title ?? {})) if (val) localizations.add(lang);
  for (const [lang, val] of Object.entries(push.alert ?? {})) if (val) localizations.add(lang);
  for (const [lang, val] of Object.entries(push.url ?? {})) if (val) localizations.add(lang);
  for (const [lang, val] of Object.entries(push.actionLinkScheme ?? {})) if (val) localizations.add(lang);

  const errors: JourneyError[] = [];

  for (const lang of localizations) {
    const validationData: ValidatorData = {};
    validationData['title'] = { value: push.title?.[lang] ?? '' };
    validationData['alert'] = { value: push.alert?.[lang] ?? '' };

    if (pushType === 'weblink') {
      validationData['url'] = { value: push.url?.[lang] ?? '' };
      validationData['allowNonHttps'] = { value: true };
    }
    if (pushType === 'actionlink') {
      validationData['actionScheme'] = { value: push.actionLinkScheme?.[lang] ?? '' };
      validationData['bypassStrictValidation'] = { value: true };
    }
    if (pushType === 'content') {
      const pushDeps = state.te.journey.dependencyGraph?.[push.refId]?.requires;
      const contentRefId = pushDeps?.find((dep) => dep.var === '{{dep.content-id.0}}')?.refId;
      const contentId = state.te.content.byRefId[contentRefId ?? '']?.id;
      validationData['contentId'] = { value: contentId ?? '' };
    }

    const localizationErrors: JourneyError[] = [];

    for (const key of Object.keys(validationData)) {
      const error = PushValidators[key]?.(validationData, key);
      if (error)
        localizationErrors.push({
          ...error,
          err: `Push (${lang === defaultLang ? '' : `${lang}, `}${PushFormFields[key]?.displayName ?? key}): ${
            error.err
          }`,
        });
    }

    if (lang === defaultLang) {
      errors.push(...localizationErrors);
    } else {
      const realErrors = localizationErrors.filter((error) => error.type === ERROR_TYPES.ERROR);
      if (realErrors.length > 0) {
        errors.push(...realErrors);
      } else if (localizationErrors.length > 0) {
        errors.push({
          type: ERROR_TYPES.INCOMPLETE,
          err: `Form values for ${lang.toUpperCase()} localizations are incomplete.`,
          nonblocking: true,
          category: 'localization',
        });
      }
    }
  }

  if (!checkJourneyErrors) return errors;
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    const paths = Object.values(PushFormFields).map((field) => field.path);
    return (err.component && err.component === 'push') || (err.path && !!paths.find((path) => err.path.includes(path)));
  });

  errors.push(...filteredErrors);

  return errors;
}
