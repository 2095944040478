import { useQueries } from '@tanstack/react-query';
import useExperienceGraphs from 'pages/AnalyticsV2/CustomAnalytics/hooks/useExperienceGraphs';
import { ModuleConfig, ChartData } from 'pages/AnalyticsV2/types';
import experienceGraphService from 'services/api/experience-graph.api';

type UseExperienceGraphsDataResult = {
  graphs: ModuleConfig[];
  isGraphsLoading: boolean;
  isGraphsError: boolean;
  isGraphsFetching: boolean;
  graphsData: ChartData[];
  isGraphsDataLoading: boolean;
  isGraphsDataError: boolean;
  isGraphsDataFetching: boolean;
};

export default function useExperienceGraphsData(): UseExperienceGraphsDataResult {
  const {
    graphs,
    isLoading: isGraphsLoading,
    isError: isGraphsError,
    isFetching: isGraphsFetching,
  } = useExperienceGraphs();

  const graphDataQueries = useQueries({
    queries: graphs.map((graph) => ({
      queryKey: ['experience-graph', { id: graph.id }],
      queryFn: () => experienceGraphService.getExperienceGraphData(graph.id),
      refetchOnWindowFocus: false,
      enabled: !!graph.id,
    })),
  });

  const graphsData = graphs.map((_, index) => {
    const { data } = graphDataQueries[index];

    return data || ({ data: [], moduleConfig: {} } as unknown as ChartData);
  });

  const isGraphsDataLoading = graphDataQueries.some((query) => query.isLoading);
  const isGraphsDataError = graphDataQueries.some((query) => query.isError);
  const isGraphsDataFetching = graphDataQueries.some((query) => query.isFetching);

  return {
    graphs,
    isGraphsLoading,
    isGraphsError,
    isGraphsFetching,
    graphsData,
    isGraphsDataLoading,
    isGraphsDataError,
    isGraphsDataFetching,
  };
}
