import './ZoneAttributes.scss';
import '../ZoneModuleSetup/ZoneModuleSetup.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { FlightButton, FlightLabelSearch, FlightTextInput } from '@flybits/design-system';
import { validateZoneAttributes } from '../zone.validators';
import zoneIntroImg from 'assets/images/zone-intro.png';
import { ZM_DETAILS, ZM_DETAILS_CLASSES } from '../ZoneModuleSetup/constants';
import { TNavigationButton } from '../types';
import PrioritizationContext, {
  initialZoneErrorMessages,
} from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconDeleteModal } from 'assets/icons/icon-delete-modal.svg';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import useConfirmModal from 'hooks/useConfirmModal';
import useLabels from 'hooks/useLabels';

const MAIN_CLASS = 'zone-attributes';

const CLASS_BODY = `${MAIN_CLASS}__body`;
const CLASS_FIELD = `${CLASS_BODY}__field`;
const CLASS_FIELD_LABEL = `${CLASS_FIELD}__label`;
const CLASS_FIELD_LABEL_BOTTOM = `${CLASS_FIELD_LABEL}--bottom`;
const CLASS_FIELD_LABEL_REQUIRED = `${CLASS_FIELD_LABEL}__required`;
const CLASS_FIELD_TEXT_INPUT = `${CLASS_FIELD}__text-input`;

const CLASS_FOOTER = `${MAIN_CLASS}__footer`;
const CLASS_FOOTER_BUTTONS = `${CLASS_FOOTER}__footer-buttons`;
const CLASS_REMOVE_ZONE = `${CLASS_FOOTER}__remove-zone`;
const CLASS_REMOVE_ZONE_TEXT = `${CLASS_REMOVE_ZONE}__text`;
const CLASS_REMOVE_ZONE_BUTTON = `${CLASS_REMOVE_ZONE}__button`;

const confirmationDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.DELETE,
  icon: <IconDeleteModal />,
  title: 'Are you sure you want to delete this zone?',
  description: 'Deleting is permanent and cannot be undone.',
  primaryAction: { value: 'Delete zone' },
  secondaryAction: { value: 'Cancel' },
  warningMessage: 'This zone may contain content in an active experience, which users will no longer see.',
};

export type ZoneErrorMessages = {
  name: string;
  refId: string;
  labels: string;
};

export type ZoneAttributeProps = {
  navigateToPrev?: TNavigationButton;
  navigateToNext?: TNavigationButton;
  editMode?: boolean;
  onRemove?: (zoneId: string) => void;
};

function ZoneAttributes({ navigateToPrev, navigateToNext, editMode, onRemove }: ZoneAttributeProps) {
  const history = useHistory();
  const [labelSearch, setLabelSearch] = useState('');
  const { labels } = useLabels(labelSearch);
  const hasMounted = useRef(false);
  const {
    zones,
    setSelectedZone: setZoneValues,
    selectedZone: zone,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    zoneErrorMessages: errorMessages,
    setZoneErrorMessages: setErrorMessages,
  } = useContext(PrioritizationContext);

  const handleChangeZoneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value || '';
    setZoneValues((prev) => ({ ...prev, name }));
    setErrorMessages((prev) => ({ ...prev, name: '' }));
  };

  const handleChangeZoneRefId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const refId = e.target.value || '';
    setZoneValues((prev) => ({ ...prev, referenceID: refId }));
    setErrorMessages((prev) => ({ ...prev, refId: '' }));
  };

  const handleAddLabel = (labels: string[]) => {
    setZoneValues((prev) => ({ ...prev, labels }));
    setErrorMessages((prev) => ({ ...prev, labels: '' }));
  };

  const handleRemoveLabel = (removedLabel: string) => {
    const remainingLabels = zone?.labels?.filter((label) => label !== removedLabel);
    setZoneValues((prev) => ({ ...prev, labels: remainingLabels ?? [] }));
  };

  const [RemoveZoneConfirmModal, showRemoveZoneConfirmModal] = useConfirmModal(confirmationDialogProps);

  const handleRemoveZone = async () => {
    if ((await showRemoveZoneConfirmModal()) && zone?.id && onRemove) {
      onRemove(zone.id);
      history.goBack();
    }
  };

  useEffect(() => {
    if (hasMounted.current) {
      setErrorMessages(initialZoneErrorMessages);
      const { name, refId, labels } = validateZoneAttributes(
        zone?.name,
        zone?.referenceID ?? '',
        zone?.labels,
        zone?.id,
        zones,
      );
      if (name || refId || labels) return setErrorMessages({ name, refId, labels });
    } else {
      hasMounted.current = true;
    }
  }, [setErrorMessages, zone, zones]);

  return (
    <div className={ZM_DETAILS}>
      <div className={ZM_DETAILS_CLASSES.FORM}>
        <div className={MAIN_CLASS}>
          <div className={CLASS_BODY}>
            <div className={CLASS_FIELD}>
              <label htmlFor="zone-name" className={CLASS_FIELD_LABEL}>
                Zone Name<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightTextInput
                className={CLASS_FIELD_TEXT_INPUT}
                labelId="zone-name"
                width="100%"
                isAriaRequired
                hasError={!!errorMessages.name}
                errorMessage={errorMessages.name}
                value={zone?.name}
                onChange={handleChangeZoneName}
              />
              <label htmlFor="zone-name-desc" className={`${CLASS_FIELD_LABEL} ${CLASS_FIELD_LABEL_BOTTOM}`}>
                This would be the name of the zone
              </label>
            </div>
            <div className={CLASS_FIELD}>
              <label htmlFor="reference-identifer" className={CLASS_FIELD_LABEL}>
                Reference Identifier<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightTextInput
                className={CLASS_FIELD_TEXT_INPUT}
                labelId="reference-identifer"
                width="100%"
                isAriaRequired
                hasError={!!errorMessages.refId}
                errorMessage={errorMessages.refId}
                value={zone?.referenceID}
                onChange={handleChangeZoneRefId}
              />
              <label htmlFor="reference-identifer-desc" className={`${CLASS_FIELD_LABEL} ${CLASS_FIELD_LABEL_BOTTOM}`}>
                The reference identifier is used for mobile to fetch the configuration, make sure you reach out to your
                development team to ensure this is accurate
              </label>
            </div>
            <div className={CLASS_FIELD}>
              <label htmlFor="labels" className={CLASS_FIELD_LABEL}>
                Labels<span className={CLASS_FIELD_LABEL_REQUIRED}>*</span>
              </label>
              <FlightLabelSearch
                hasError={!!errorMessages.labels}
                errorMessage={errorMessages.labels}
                labels={zone?.labels}
                options={labels}
                onLabelChange={(labelName: string) => setLabelSearch(labelName)}
                onLabelAdd={handleAddLabel}
                onLabelRemove={handleRemoveLabel}
                onLabelClick={handleRemoveLabel}
              />
            </div>
          </div>
          {zone && (
            <div className={CLASS_FOOTER}>
              <div className={CLASS_FOOTER_BUTTONS}>
                {!!navigateToPrev && (
                  <FlightButton
                    ariaLabel={navigateToPrev.label}
                    label={navigateToPrev.label}
                    onClick={navigateToPrev.cta}
                    disabled={navigateToPrev.isDisabled}
                  />
                )}
                {!!navigateToNext && (
                  <FlightButton
                    ariaLabel={navigateToNext.label}
                    label={navigateToNext.label}
                    onClick={navigateToNext.cta}
                    disabled={navigateToNext.isDisabled}
                  />
                )}
              </div>
              {onRemove && editMode && (
                <div className={CLASS_REMOVE_ZONE}>
                  <div className={CLASS_REMOVE_ZONE_TEXT}>
                    <label htmlFor="remove-zone" className={CLASS_FIELD_LABEL}>
                      Remove zone
                    </label>
                    <label htmlFor="remove-zone-desc" className={`${CLASS_FIELD_LABEL} ${CLASS_FIELD_LABEL_BOTTOM}`}>
                      Removing this zone will also remove any modules within it. Once you remove the zone you
                      can&rsquo;t undo it.
                    </label>
                  </div>
                  <div className={CLASS_REMOVE_ZONE_BUTTON}>
                    <FlightButton
                      theme="secondary"
                      label="Remove Zone"
                      ariaLabel="Remove Zone"
                      onClick={handleRemoveZone}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={ZM_DETAILS_CLASSES.SIDEBAR}>
        <div className={ZM_DETAILS_CLASSES.DIAGRAM_CONTAINER}>
          <img src={zoneIntroImg} alt="zone diagram" />
        </div>
      </div>
      <RemoveZoneConfirmModal />
    </div>
  );
}

export default ZoneAttributes;
