import { INTERVAL_OPTIONS } from 'pages/AnalyticsV2/types';

export enum CHART_CONFIGURATION_TYPES {
  RECOMMENDED = 'recommended',
  DO_IT_YOURSELF = 'diy',
}

export enum VISUALIZER_OPTIONS {
  BAR = 'bar',
  TABLE = 'table',
  LINE = 'line',
  UNSUPORTED = 'unsupported',
}

export const RECOMMENDED_METRICS = [
  {
    icon: 'phoneSetup',
    metric: { key: 'push-sent', name: 'push notifications sent' },
    time: { period: 7, interval: 'days' },
  },
  {
    icon: 'pushNotification',
    metric: { key: 'push-engagement', name: 'push engagement rate' },
    time: { period: 7, interval: 'days' },
  },
];

// subject to change based on format of metrics
export const METRIC_OPTIONS = [
  {
    key: 0,
    name: 'Experience total reach',
    value: {
      entity: 'experience',
      metric: 'reach',
    },
  },
  {
    key: 1,
    name: 'Experience total impressions',
    value: {
      entity: 'experience',
      metric: 'impression',
    },
  },
  {
    key: 2,
    name: 'Experience total engagement',
    value: {
      entity: 'experience',
      metric: 'engagement',
    },
  },
  {
    key: 3,
    name: 'Push delivered',
    value: {
      entity: 'push',
      metric: 'delivered',
    },
  },
  {
    key: 4,
    name: 'Push opened',
    value: {
      entity: 'push',
      metric: 'opened',
    },
  },
  {
    key: 5,
    name: 'Content total viewed',
    value: {
      entity: 'content',
      metric: 'viewed',
    },
  },
  {
    key: 6,
    name: 'Content total clicked',
    value: {
      entity: 'content',
      metric: 'clicked',
    },
  },
  {
    key: 7,
    name: 'Content unique viewed',
    value: {
      entity: 'content',
      metric: 'uniq-viewed',
    },
  },
  {
    key: 8,
    name: 'Content unique clicked',
    value: {
      entity: 'content',
      metric: 'uniq-clicked',
    },
  },
  {
    key: 9,
    name: 'Total engagement rate',
    value: {
      entity: 'experience',
      metric: 'engagement-rate',
    },
  },
  {
    key: 10,
    name: 'Unique engagement rate',
    value: {
      entity: 'experience',
      metric: 'uniq-engagement-rate',
    },
  },
  {
    key: 11,
    name: 'Average frequency',
    value: {
      entity: 'experience',
      metric: 'freq',
    },
  },
  {
    key: 12,
    name: 'Push open rate',
    value: {
      entity: 'push',
      metric: 'opened-rate',
    },
  },
  {
    key: 13,
    name: 'Content click-through rate (total)',
    value: {
      entity: 'content',
      metric: 'click-rate',
    },
  },
  {
    key: 14,
    name: 'Content click-through rate (unique)',
    value: {
      entity: 'content',
      metric: 'uniq-click-rate',
    },
  },
];

export const TIMEFRAME_INTERVAL_OPTIONS = [
  { label: 'Hourly', value: INTERVAL_OPTIONS.HOURLY, duration: 3600 },
  { label: 'Daily', value: INTERVAL_OPTIONS.DAILY, duration: 3600 * 24 },
  { label: 'Weekly', value: INTERVAL_OPTIONS.WEEKLY, duration: 3600 * 24 * 7 },
  { label: 'Monthly', value: INTERVAL_OPTIONS.MONTHLY, duration: 3600 * 24 * 30.5 },
  { label: 'Annually', value: INTERVAL_OPTIONS.ANNUALLY, duration: 3600 * 24 * 365 },
];
