import {
  Content,
  ContentTemplate,
  ContentPrototype,
  ContentPrototypeSchemaField,
  ContentCreationPayload,
  isContentInstance,
  streamlinedContentTypes,
  TemplateType,
  isContentTemplate,
} from 'interface/content/content.interface';
import { ContentPreviewProps } from 'components/ExperienceCanvas/types';
import { ContentListItem } from 'components/ExperienceCanvas/types';
import { ContentStateItem } from 'store/content/content.type';
import { fetchContentInstance } from 'store/content/content.thunk';
import { cloneDeep } from 'lodash';

type ContentButton = {
  actionType: string;
  localizations: {
    [lang: string]: {
      actionValue: string;
      text: string;
    };
  };
};

/**
 * Checks if there is a read only content template id constraint and returns it.
 * Returns undefined if there is no template constraint.
 * */
export const getTemplateConstraint = (content: ContentStateItem) => {
  const payloadConstraints =
    content.payload && !isContentInstance(content.payload) ? content.payload.payload?.constraints : undefined;
  const stateConstraints = content.constraints;

  const constraints = stateConstraints || payloadConstraints;
  if (!constraints) return undefined;
  const contentTemplateId = constraints.contentTemplate?.defaultValue;
  const isCTReadOnly = constraints.contentTemplate?.isReadOnly;

  return isCTReadOnly ? contentTemplateId : undefined;
};

/**
 * Checks if there are read only schema constraints.
 * Returns content template id if there are, undefined if not.
 * */
export const getSchemaConstraint = (content: ContentStateItem) => {
  const payloadConstraints =
    content.payload && !isContentInstance(content.payload) ? content.payload.payload?.constraints : undefined;
  const stateConstraints = content.constraints;

  const constraints = stateConstraints || payloadConstraints;
  if (!constraints) return undefined;
  const contentTemplateId = constraints.contentTemplate?.defaultValue;

  const schema = constraints.schemaConstraints?.[contentTemplateId ?? ''];
  if (!schema || !schema.fields) return undefined;

  for (let i = 0; i < schema.fields.length; i++) {
    const field = schema.fields[i];
    if (field.isReadOnly) return contentTemplateId;
  }

  return undefined;
};

/**
 * Maps a content state item to a ContentListItem.
 * @return ContentListItem
 * */
export const mapStateItemToListItem = (
  contentStateItem: ContentStateItem,
  defaultLangCode: string,
  type: 'primary' | 'secondary',
): ContentListItem | undefined => {
  if (contentStateItem.actionType === 'content-grouping') return;
  const templateConstraint = getTemplateConstraint(contentStateItem);
  const schemaConstraint = getSchemaConstraint(contentStateItem);
  const extractedContent = extractContentPreviewFields(contentStateItem.payload, defaultLangCode);
  const isReadOnly = !!schemaConstraint;

  return {
    refId: contentStateItem.refId ?? '',
    prototypeId: contentStateItem.prototypeId ?? '',
    stepIdx: contentStateItem.stepIdx,
    // right now this displays the name of the content, not the title
    // TODO: This MAY break (the lack of conditional chaining) if the primary language is changed in a tenant
    name: contentStateItem.localizations?.[defaultLangCode].name ?? '',
    metadataDescription: contentStateItem.localizations?.[defaultLangCode].description ?? '',
    type,
    isRequired: !contentStateItem.isOptional,
    templateIdConstraint: templateConstraint || schemaConstraint || '',
    isReadOnly,
    ...extractedContent,
  };
};

/**
 * Extract values from a ContentInstance necessary to generate a preview
 * */
export const extractContentPreviewFields = (
  content: Content | ContentPrototype | ContentTemplate | undefined,
  defaultLangCode: string,
): ContentPreviewProps => {
  // we can reevaluate how we want to do incompatible inputs later
  if (!content || (!isContentInstance(content) && !isContentTemplate(content)))
    return {
      id: '',
      imageUrl: '',
      header: '',
      title: '',
      buttons: [],
      description: '',
      details: '',
      templateType: '',
      isSupported: false,
      link: '',
      renderType: '',
    };

  const contentData = !isContentTemplate(content) ? content.content?.data?.[0] : content;

  const id = content.id || '';
  const header = contentData?.localizations?.[defaultLangCode]?.header ?? '';
  const title =
    contentData?.localizations?.[defaultLangCode]?.title ?? contentData?.localizations?.[defaultLangCode]?.name ?? '';
  const description = contentData?.localizations?.[defaultLangCode]?.description ?? '';
  const imageUrl =
    contentData?.localizations?.[defaultLangCode]?.image ??
    contentData?.media?.localizations?.[defaultLangCode]?.previewImgURL ??
    '';
  const buttons =
    contentData?.buttons?.map((button: ContentButton) => button?.localizations?.[defaultLangCode]?.text ?? '') ?? [];
  const details = contentData?.details?.localizations?.[defaultLangCode]?.body ?? '';
  const link = contentData?.link?.localizations?.[defaultLangCode]?.text ?? '';
  let templateType = '';
  let renderType = '';
  if (isContentInstance(content)) {
    templateType = content.templateType || '';
    renderType = content.renderType || '';
  }
  if (isContentTemplate(content)) {
    templateType = content.type || '';
    renderType = content.defaultValues?.renderType || '';
  }
  const isSupported = streamlinedContentTypes.includes(templateType as TemplateType);

  return {
    id,
    imageUrl,
    header,
    title,
    buttons,
    description,
    details,
    templateType,
    isSupported,
    link,
    renderType,
  };
};

/**
 * Port of the extractDefaultValuesFromPrototype function.
 * Prepares the creation payload for generating a content instance.
 * TODO: Fix Localization after content typing changes go through.
 * */
export const generateContentCreationPayloadFromSchemaConstraints = (
  content: ContentStateItem,
): ContentCreationPayload => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultValues: any = {};
  const prototype = content.payload as ContentPrototype;
  const contentTemplate = prototype?.payload?.contentTemplate;
  const contentName = prototype?.payload?.name;
  const labels = contentTemplate?.labels || [];
  labels.push('fb-auto-gen');
  if (content.prototypeId) labels.push(`proto-id-${content.prototypeId}`);
  contentTemplate?.schema?.fields?.forEach((schemaField: ContentPrototypeSchemaField) => {
    if (schemaField.type === 'object' && schemaField.fields?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectDefault: any = {};
      const hasDefaults = schemaField.fields?.reduce((prev, curr) => {
        return prev || (curr.defaultValue !== null && curr.defaultValue !== undefined);
      }, false);
      schemaField.fields?.forEach((innerField) => {
        if (innerField.type === 'localized') {
          for (const locale in innerField.defaultValue) {
            objectDefault['localizations'] = {
              ...objectDefault['localizations'],
              [locale]: {
                ...objectDefault['localizations']?.[locale],
                [innerField.name]: innerField.defaultValue[locale] || '',
              },
            };
          }
        } else {
          objectDefault[innerField.name] = innerField.defaultValue || '';
        }
      });
      defaultValues[schemaField.name] = hasDefaults ? objectDefault : undefined;
    } else if (schemaField.type === 'array' && Array.isArray(schemaField.defaultValue)) {
      defaultValues[schemaField.name] = [];
      schemaField.defaultValue.forEach((innerField) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const defaultObject: any = {};
        for (const param in innerField) {
          if (typeof innerField[param] === 'object') {
            for (const locale in innerField[param]) {
              defaultObject['localizations'] = {
                ...defaultObject['localizations'],
                [locale]: {
                  ...defaultObject['localizations']?.[locale],
                  [param]: innerField[param]?.[locale] || '',
                },
              };
            }
          } else {
            defaultObject[param] = innerField[param] || '';
          }
        }
        defaultValues[schemaField.name].push(defaultObject);
      });
    } else if (typeof schemaField.defaultValue === 'object' && schemaField.type !== 'location') {
      for (const locale in schemaField.defaultValue) {
        defaultValues['localizations'] = {
          ...defaultValues['localizations'],
          [locale]: {
            ...defaultValues['localizations']?.[locale],
            [schemaField.name]: schemaField.defaultValue?.[locale] || '',
          },
        };
      }
    } else {
      defaultValues[schemaField.name] = schemaField.defaultValue || '';
    }
  });

  return {
    accessType: 'default',
    data: defaultValues,
    // oof on the localizations
    iconUrl: contentTemplate?.iconUrl || '',
    labels,
    localizations: {
      en: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        name: contentName || contentTemplate?.localizations?.en?.name! + new Date().getSeconds() || '',
        description: contentTemplate?.localizations?.en?.description || '',
      },
    },
    templateId: contentTemplate?.id || '',
    templatePrototypeId: content?.prototypeId || '',
    templateType: contentTemplate?.type || '',
  };
};

/**
 * Create content creation payload from another content instance
 */
export const generateContentCreationPayloadFromContentInstance = async (
  content: ContentStateItem,
): Promise<ContentCreationPayload> => {
  const prototype = content.payload as ContentPrototype;
  const contentTemplate = prototype?.payload?.contentTemplate;
  const contentName = prototype?.payload?.name;
  const labels = contentTemplate?.labels || [];
  const contentInstanceId = content.constraints?.contentInstance?.defaultValue;

  if (!contentInstanceId) {
    // ToDo: Handle gracefully
    return content as unknown as ContentCreationPayload;
  }

  const defaultContentInstance = await fetchContentInstance(contentInstanceId);
  const payload = cloneDeep(defaultContentInstance);

  const contentData = cloneDeep(payload.content?.data?.[0]);
  const contentDataKeys = Object.keys(contentData);
  for (const key of contentDataKeys) {
    if (key.toLowerCase().includes('id') || key.toLowerCase().includes('pagination')) {
      delete contentData[key];
    }
  }

  delete payload.id;
  delete payload.content;

  return {
    ...payload,
    accessType: 'default',
    data: contentData,
    iconUrl: content.iconUrl || payload.iconUrl || '',
    labels,
    localizations: {
      en: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        name: contentName || contentTemplate?.localizations?.en?.name! + new Date().getSeconds() || '',
        description: contentTemplate?.localizations?.en?.description || '',
      },
    },
    templateId: contentTemplate?.id || '',
    templatePrototypeId: content?.prototypeId || '',
    templateType: contentTemplate?.type || '',
  };
};
