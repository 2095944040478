import React, { useEffect, useState } from 'react';

import useExitPrompt from 'hooks/useExitPrompt';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconDiscardChanges } from 'assets/icons/navigate-away.svg';
import { ConfirmationDialogProps } from '../shared.types';
import useConfirmNavigationModal from 'hooks/useConfirmNavigationModal';
import { flushSync } from 'react-dom';

type UnsavedPromptProps = {
  when: boolean;
  unblockPaths?: Array<string>;
  dialogProps?: Partial<ConfirmationDialogProps>;
  // will run when navigation away is either allowed by the when condition being false, or when the user has discarded changes
  callback?: () => void;
};

export default function UnsavedPrompt(props: UnsavedPromptProps) {
  const {
    when,
    unblockPaths,
    callback,
    dialogProps: { title, icon, description, primaryAction, secondaryAction, className } = {},
  } = props;
  const history = useHistory();

  const [isFullfilled, setIsFullfiled] = useState(false);
  const [, setShowExitPrompt] = useExitPrompt(when);
  const confirmationDialogProps: ConfirmationDialogProps = {
    icon: icon || <IconDiscardChanges />,
    title: title || 'You are leaving this page',
    description: description || 'This will undo all changes that have been made since the last time you published',
    primaryAction: primaryAction || {
      value: 'Remain on page',
    },
    secondaryAction: secondaryAction || {
      value: 'Discard changes',
    },
    className,
  };
  const [ConfirmNavigationModal, showConfirmNavigationModal] = useConfirmNavigationModal(confirmationDialogProps);

  useEffect(() => {
    if (isFullfilled) {
      return;
    }

    setShowExitPrompt(when);

    const unblock = history.block(({ pathname }) => {
      const allowNavigation = unblockPaths?.some((path) => pathname.includes(path.toLowerCase()));
      if (when && !allowNavigation) {
        showConfirmNavigationModal().then((res) => {
          if (res) {
            flushSync(() => {
              setIsFullfiled(true);
            });
            // Unblock the navigation.
            unblock();
            // Retry navigation
            callback?.();
            history.replace(pathname);
          } else {
            return false;
          }
        });
        return false;
      } else if (!when && !allowNavigation) {
        callback?.();
      }
    });
    return () => {
      setShowExitPrompt(false);
      unblock();
    };
  }, [
    showConfirmNavigationModal,
    history,
    setShowExitPrompt,
    when,
    unblockPaths,
    isFullfilled,
    setIsFullfiled,
    callback,
  ]);

  return <>{ConfirmNavigationModal()}</>;
}
