/* eslint-disable no-console */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import './CreateOrEditChart.scss';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useParams } from 'react-router-dom';
import Preview from './Preview/Preview';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import ExperienceSelector from './ExperienceSelector/ExperienceSelector';
import ChartConfigurationType from './ChartConfigurationType/ChartConfigurationType';
import RecommendationsSelector from './RecommendationsSelector/RecommendationsSelector';
import TimeFrameSelector from './TimeFrameSelector/TimeFrameSelector';
import ChartTitleInput from './ChartTitleInput/ChartTitleInput';
import MetricsSelector from './MetricsSelector/MetricsSelector';
import VisualizationSelector from './VisualizationSelector/VisualizationSelector';
import JourneyAnalyticsCollapsible from 'pages/AnalyticsV2/CustomAnalytics/CreateOrEditChart/JourneyAnalyticsCollapsible/JourneyAnalyticsCollapsible';
import { ReactComponent as IconRecommendation } from 'assets/icons/icon-recommendation.svg';
import { CHART_CONFIGURATION_TYPES } from 'pages/AnalyticsV2/CustomAnalytics/CreateOrEditChart/constants';
import {
  ChartConfigState,
  ExperienceSelectorItem,
  JourneyAnalyticsCollapsibleModes,
  TAnalyticsRouteParams,
} from 'pages/AnalyticsV2/types';
import { deserializeModuleConfig, generateGraphData, getDaysBetweenDates } from 'pages/AnalyticsV2/helpers';
import experienceGraphService from 'services/api/experience-graph.api';
// import useExperienceGraph from 'pages/AnalyticsV2/CustomAnalytics/hooks/useExperienceGraph';
import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { capitalizeFirstCharacter } from 'helpers/common.helper';
import { diffObject } from 'components/Zones/v2/zone.validators';

const MAIN_CLASS = 'chart-details';
const CLASSES = {
  HEADER: `${MAIN_CLASS}__header`,
  BACK: `${MAIN_CLASS}__header__back`,
  CONTENT: `${MAIN_CLASS}__content`,
  COLUMN: `${MAIN_CLASS}__content__column`,
  FOOTER: `${MAIN_CLASS}__footer`,
};

const teAPI = new TemplatedExperienceAPI();

export default function CreateOrEditChart() {
  const { id: editGraphId } = useParams<TAnalyticsRouteParams>();
  const history = useHistory();

  const [apiChartConfig, setApiChartConfig] = useState<ChartConfigState>({});
  const [savedChartConfig, setSavedChartConfig] = useState<ChartConfigState>({});
  const [chartConfig, setChartConfig] = useState<ChartConfigState>({});
  const objdiff = diffObject(apiChartConfig, chartConfig, true);
  const numUnsavedChanges =
    Object.keys(objdiff.added).length + Object.keys(objdiff.updated).length + Object.keys(objdiff.removed).length;

  // ToDo: Consume graph from react-query
  // Currently, using this hook triggers a infinite rendering cycle for CreateOrEditChart
  // debugging still on ... :(
  // const { graph } = useExperienceGraph(id ?? '');

  const [chartConfigurationType, setChartConfigurationType] = useState(CHART_CONFIGURATION_TYPES.RECOMMENDED);
  const handleSelectChartConfigurationType = (type: CHART_CONFIGURATION_TYPES) => setChartConfigurationType(type);

  // Completion Status States
  const [isExperienceSelectorCompleted, setExperienceSelectorCompleted] = useState(false);
  const [isRecommendationSelectorCompleted, setRecommendationSelectorCompleted] = useState(false);
  const [isMetricSelectorCompleted, setMetricSelectorCompleted] = useState(false);
  const [isTimeframeSelectorCompleted, setTimeframeSelectorCompleted] = useState(false);
  const [isVisualizationSelectorCompleted, setVisualizationSelectorCompleted] = useState(false);
  const [isChartTitleInputCompleted, setChartTitleInputCompleted] = useState(false);

  // Validation Status States
  const [isExperienceSelectorValid, setExperienceSelectorValid] = useState(false);
  const [isMetricSelectorValid, setMetricSelectorValid] = useState(false);
  const [isTimeframeSelectorValid, setTimeframeSelectorValid] = useState(false);
  const [isVisualizationSelectorValid, setVisualizationSelectorValid] = useState(false);
  const [isChartTitleInputValid, setChartTitleInputValid] = useState(false);

  const [experiencesList, setExperiencesList] = useState<ExperienceSelectorItem[]>([]);
  const fetchExperiences = useCallback(async () => {
    try {
      const experiences = await teAPI.getTemplatedInstances({
        limit: 9999,
        offset: 0,
      });
      setExperiencesList(
        experiences?.data
          ?.filter(({ status }) => status === 'active')
          .map(({ id, name }) => ({
            key: id,
            name: name || id,
          })),
      );
    } catch (e) {}
  }, []);
  useEffect(() => {
    fetchExperiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Apply Handlers - Completion Status
  // TODO: Map recommendation selector to chartConfig in this handler
  const handleApplyRecommendationSelector = () => setRecommendationSelectorCompleted(true);
  const handleApplyExperienceSelector = () => {
    setExperienceSelectorCompleted(true);
    setSavedChartConfig((config) => ({
      ...config,
      selectedExperiences: chartConfig.selectedExperiences,
      description: chartConfig.description,
    }));
  };
  const handleApplyMetricSelector = () => {
    setMetricSelectorCompleted(true);
    setSavedChartConfig((config) => ({
      ...config,
      selectedExperiences: chartConfig.selectedExperiences,
      metrics: chartConfig.metrics,
    }));
  };
  const handleApplyTimeframeSelector = () => {
    setTimeframeSelectorCompleted(true);
    setSavedChartConfig((config) => ({
      ...config,
      timeFrame: chartConfig.timeFrame,
    }));
  };
  const handleApplyVisualizationSelector = () => {
    setVisualizationSelectorCompleted(true);
    setSavedChartConfig((config) => ({
      ...config,
      visualizationType: chartConfig.visualizationType,
    }));
  };
  const handleApplyChartTitleInput = () => {
    setChartTitleInputCompleted(true);
    setSavedChartConfig((config) => ({
      ...config,
      title: chartConfig.title,
    }));
  };
  // Change Handlers
  const handleChangeExperienceSelector = (experiences: ExperienceSelectorItem[]) =>
    setChartConfig((config) => ({
      ...config,
      selectedExperiences: experiences,
      description: `from ${experiences[0].name}`,
    }));
  const handleChangeMetricSelector = (metrics: ChartConfigState['metrics']) =>
    setChartConfig((config) => ({ ...config, metrics }));
  const handleChangeTimeFrameSelector = (timeFrame: ChartConfigState['timeFrame']) =>
    setChartConfig((config) => ({ ...config, timeFrame }));
  const handleChangeVisualizationSelector = (visualizationType: ChartConfigState['visualizationType']) =>
    setChartConfig((config) => ({ ...config, visualizationType }));
  const handleChangeChartTitleInput = (chartTitle: ChartConfigState['title']) =>
    setChartConfig((config) => ({ ...config, title: chartTitle }));

  // Validation Handlers - Validation Status
  const handleExperienceSelectorValidation = (isValid: boolean) => setExperienceSelectorValid(isValid);
  const handleMetricSelectorValidation = (isValid: boolean) => setMetricSelectorValid(isValid);
  const handleTimeframeSelectorValidation = (isValid: boolean) => setTimeframeSelectorValid(isValid);
  const handleVisualizationSelectorValidation = (isValid: boolean) => setVisualizationSelectorValid(isValid);
  const handleChartTitleInputValidation = (isValid: boolean) => setChartTitleInputValid(isValid);

  const previewData = useMemo(() => generateGraphData(chartConfig.visualizationType), [chartConfig.visualizationType]);

  const renderStepsForRecommendation = () => (
    <>
      {/* Select a recommendation */}
      <JourneyAnalyticsCollapsible
        key={2}
        step={2}
        mode={
          !isRecommendationSelectorCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Select a recommendation'}
        titleIcon={<IconRecommendation />}
        description={'Make chart building easy by selecting a pre-defined option'}
        label={
          <span>
            For the selected experience, show me&nbsp;
            <b>push notifications sent</b>
            &nbsp;in the&nbsp;
            <b>past 7 days</b>
          </span>
        }
        actionLabel={'Apply'}
        actionHandler={handleApplyRecommendationSelector}
        // RecommendationSelector always has the first RECOMMENDED_METRICS option selected by default
        isValid={true}
      >
        <RecommendationsSelector onChange={() => null} />
      </JourneyAnalyticsCollapsible>
      {/* Title your chart */}
      <JourneyAnalyticsCollapsible
        key={3}
        step={3}
        mode={
          !isChartTitleInputCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Title your chart'}
        label={<span>{savedChartConfig.title}</span>}
        actionLabel={'Apply'}
        actionHandler={handleApplyChartTitleInput}
        secondaryActionHandler={() => setChartConfig((config) => ({ ...config, title: savedChartConfig.title }))}
        isValid={isChartTitleInputValid}
      >
        <ChartTitleInput
          chartConfig={chartConfig}
          onChange={handleChangeChartTitleInput}
          onValidation={handleChartTitleInputValidation}
        />
      </JourneyAnalyticsCollapsible>
    </>
  );

  const renderStepsForDIY = () => (
    <>
      {/* Select metrics to display (vertical) */}
      <JourneyAnalyticsCollapsible
        key={2}
        step={2}
        mode={
          !isMetricSelectorCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Select metrics to display (vertical)'}
        label={
          <span>
            I want to measure&nbsp;
            <b>{`${capitalizeFirstCharacter(savedChartConfig?.metrics?.entity) ?? ''} ${
              capitalizeFirstCharacter(savedChartConfig?.metrics?.metric) ?? ''
            }`}</b>
          </span>
        }
        actionLabel={'Apply'}
        actionHandler={handleApplyMetricSelector}
        secondaryActionHandler={() => setChartConfig((config) => ({ ...config, metrics: savedChartConfig.metrics }))}
        isValid={isMetricSelectorValid}
      >
        <MetricsSelector
          chartConfig={chartConfig}
          onChange={handleChangeMetricSelector}
          onValidation={handleMetricSelectorValidation}
        />
      </JourneyAnalyticsCollapsible>
      {/* Select a time frame (horizontal) */}
      <JourneyAnalyticsCollapsible
        key={3}
        step={3}
        mode={
          !isTimeframeSelectorCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Select a time frame (horizontal)'}
        label={
          <span>
            Over the past&nbsp;
            <b>
              {savedChartConfig.timeFrame?.start && savedChartConfig.timeFrame?.end
                ? getDaysBetweenDates(savedChartConfig.timeFrame?.start, savedChartConfig.timeFrame?.end)
                : '-'}{' '}
              days
            </b>
            &nbsp;at a&nbsp;
            <b>{savedChartConfig.timeFrame?.period}</b>
            &nbsp;interval
          </span>
        }
        actionLabel={'Apply'}
        secondaryActionHandler={() =>
          setChartConfig((config) => ({ ...config, timeFrame: savedChartConfig.timeFrame }))
        }
        actionHandler={handleApplyTimeframeSelector}
        isValid={isTimeframeSelectorValid}
      >
        <TimeFrameSelector
          chartConfig={chartConfig}
          onChange={handleChangeTimeFrameSelector}
          onValidation={handleTimeframeSelectorValidation}
        />
      </JourneyAnalyticsCollapsible>
      {/* Select visualization */}
      <JourneyAnalyticsCollapsible
        key={4}
        step={4}
        mode={
          !isVisualizationSelectorCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Select visualization'}
        label={
          <span>
            In a&nbsp;
            <b>
              {savedChartConfig.visualizationType} {`${savedChartConfig.visualizationType !== 'table' ? 'graph' : ''}`}
            </b>
          </span>
        }
        actionLabel={'Preview chart'}
        actionHandler={handleApplyVisualizationSelector}
        secondaryActionHandler={() =>
          setChartConfig((config) => ({ ...config, visualizationType: savedChartConfig.visualizationType }))
        }
        isValid={isVisualizationSelectorValid}
      >
        <VisualizationSelector
          chartConfig={chartConfig}
          onChange={handleChangeVisualizationSelector}
          onValidation={handleVisualizationSelectorValidation}
        />
      </JourneyAnalyticsCollapsible>
      {/* Title your chart */}
      <JourneyAnalyticsCollapsible
        key={5}
        step={5}
        mode={
          !isChartTitleInputCompleted
            ? JourneyAnalyticsCollapsibleModes.DRAFT
            : JourneyAnalyticsCollapsibleModes.COMPLETED
        }
        title={'Title your chart'}
        label={<span>{savedChartConfig.title}</span>}
        actionLabel={'Apply'}
        actionHandler={handleApplyChartTitleInput}
        secondaryActionHandler={() => setChartConfig((config) => ({ ...config, title: savedChartConfig.title }))}
        isValid={isChartTitleInputValid}
      >
        <ChartTitleInput
          chartConfig={chartConfig}
          onChange={handleChangeChartTitleInput}
          onValidation={handleChartTitleInputValidation}
        />
      </JourneyAnalyticsCollapsible>
    </>
  );

  const isSaveButtonEnabled =
    chartConfigurationType === CHART_CONFIGURATION_TYPES.RECOMMENDED
      ? isExperienceSelectorValid && isChartTitleInputValid
      : isExperienceSelectorValid &&
        isMetricSelectorValid &&
        isTimeframeSelectorValid &&
        isVisualizationSelectorValid &&
        isChartTitleInputValid;

  useEffect(() => {
    if (!editGraphId) {
      return;
    }

    (async () => {
      const moduleconfig = await experienceGraphService.getExperienceGraph(editGraphId);
      const chartConfigState = await deserializeModuleConfig(moduleconfig);

      setChartConfig((config) => ({ ...config, ...chartConfigState }));
      setApiChartConfig((config) => ({ ...config, ...chartConfigState }));
      setSavedChartConfig((config) => ({ ...config, ...chartConfigState }));
    })();
  }, [editGraphId]);

  return (
    <div className={MAIN_CLASS}>
      <header className={CLASSES.HEADER}>
        <FlightButton
          ariaLabel={'Go Back'}
          className={CLASSES.BACK}
          iconLeft={'baselineKeyboardArrowLeft'}
          theme="link"
          onClick={() => history.goBack()}
        />
      </header>
      <div className={CLASSES.CONTENT}>
        <div className={CLASSES.COLUMN}>
          {/* Select an experience */}
          <JourneyAnalyticsCollapsible
            key={1}
            step={1}
            mode={
              !isExperienceSelectorCompleted
                ? JourneyAnalyticsCollapsibleModes.DRAFT
                : JourneyAnalyticsCollapsibleModes.COMPLETED
            }
            title={'Select an experience'}
            label={
              <span>
                Based on&nbsp;<b>{savedChartConfig.selectedExperiences?.[0]?.name || ''}</b>
              </span>
            }
            actionLabel={'Apply'}
            actionHandler={handleApplyExperienceSelector}
            secondaryActionHandler={() =>
              setChartConfig((config) => ({
                ...config,
                selectedExperiences: savedChartConfig.selectedExperiences,
                description: savedChartConfig.description,
              }))
            }
            isValid={isExperienceSelectorValid}
          >
            <ExperienceSelector
              experiences={experiencesList}
              onChange={handleChangeExperienceSelector}
              onValidation={handleExperienceSelectorValidation}
            />
          </JourneyAnalyticsCollapsible>
          {/* Recommendations or Do it yourself */}
          {!editGraphId && (
            <ChartConfigurationType
              currrentSelection={chartConfigurationType}
              onSelect={handleSelectChartConfigurationType}
            />
          )}
          {editGraphId || chartConfigurationType === CHART_CONFIGURATION_TYPES.DO_IT_YOURSELF
            ? renderStepsForDIY()
            : chartConfigurationType === CHART_CONFIGURATION_TYPES.RECOMMENDED
            ? renderStepsForRecommendation()
            : null}
        </div>
        <div className={CLASSES.COLUMN}>
          <Preview chartConfig={chartConfig} data={previewData} />
        </div>
      </div>
      <footer className={CLASSES.FOOTER}>
        <FlightButton theme="secondary" onClick={() => history.goBack()} label={'Cancel'} />
        <FlightButton
          theme="primary"
          onClick={() => console.log('TODO: Save to dashboard')}
          label={'Save to dashboard'}
          disabled={!isSaveButtonEnabled}
        />
      </footer>
      <UnsavedPrompt
        when={numUnsavedChanges > 0}
        dialogProps={{
          description: 'This will undo all the information you have entered since you started.',
        }}
      />
    </div>
  );
}
