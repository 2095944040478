import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { extractContentPreviewFields } from 'helpers/content.helper';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import useSettings from 'hooks/useSetting';
import { RootState } from 'store/store';
import { ActionTypes } from 'store/actionTypes';
import { setContentInstances } from 'store/content/content.action';
import { Content } from 'interface/content/content.interface';
import ActionsIcon from 'pages/ExperienceCanvas/assets/icons/ActionsIcon';
import ContentInstancesSelector from '../ContentInstancesSelector/ContentInstancesSelector';
import SlidingSidePanel from '../SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview';
import ContentTemplateSelector from './ContentTemplateSelector/ContentTemplateSelector';
import DynamicContentIframe from 'components/ExperienceCanvas/DynamicContentIframe/DynamicContentEditorIframe';
import './ContentManager.scss';
import { getContentListItems } from 'store/content/content.selector';
import { fetchContentInstance, insertContents } from 'store/content/content.thunk';
import ContentOptionSelector from './ContentOptionSelector/ContentOptionSelector';
import { CONTENT_OPTIONS, CONTENT_MANAGER_STEPS as STEPS } from 'components/ExperienceCanvas/types';
import { genUUID } from 'helpers/common.helper';

type TContentManagerProps = {
  isOpen: boolean;
  onClickClose: () => void;
  stepIdx: number;
  contentRefId?: string;
  onChangeContent?: () => void;
  startingStep?: STEPS;
  headerInfo?: Partial<TSlidingSidePanelProps['headerInfo']>;
  className?: string;
};

const STEPS_TITLES: { [key in STEPS]: string } = {
  CONTENT_OPTION_SELECTOR: 'Select an option',
  CONTENT_SELECTOR: 'Content Selector',
  TEMPLATE_SELECTOR: 'Create content from template',
  CONTENT_EDITOR: 'Content Editor',
};

const filteredContentInstancesSelector = (state: RootState) =>
  state.content.contentInstances.filter((c: Content) => !c?.labels?.includes('fb-auto-gen'));

const MAIN_CLASS = 'content-manager';
const CLASSES = {
  CONTENT_WRAPPER: `${MAIN_CLASS}__content-wrapper`,
  PREVIEW_WRAPPER: `${MAIN_CLASS}__preview-wrapper`,
  FIX_PREVIEW_BORDER: `${MAIN_CLASS}__fix-preview-border`,
  LOADING_WRAPPER: `${MAIN_CLASS}__loading-wrapper`,
};

const ContentManager: React.FC<TContentManagerProps> = ({
  isOpen,
  onClickClose,
  stepIdx,
  onChangeContent,
  contentRefId = '',
  headerInfo,
  className,
  startingStep = STEPS.CONTENT_OPTION_SELECTOR,
}) => {
  const [currentStep, setCurrentStep] = useState<STEPS>(startingStep);
  const [selectedOption, setSelectedOption] = useState<CONTENT_OPTIONS>(CONTENT_OPTIONS.NEW);
  const [selectedTemplate, setSelectedTemplate] = useState<Content>();
  const contentInstances = useSelector(filteredContentInstancesSelector);
  const { languages } = useSettings();
  const defaultLang = getDefaultLanguage(languages);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const stepPushRefId = useSelector((state) => state.te.journey.steps[stepIdx]?.push);
  const [contentsFromState, singleSelectStateContent] = useSelector((state) => {
    const stateContents = getContentListItems(state, stepIdx);
    const singleSelectedContent = stateContents.find((content) => content.refId === contentRefId);
    return [stateContents, singleSelectedContent];
  });
  const [selectedContentInstances, setSelectedContentInstances] = useState<Content[]>([]);
  const [singlySelectedAndDefaultContentIds, setSinglySelectedAndDefaultContentIds] = useState<Array<string>>([]);
  // since we're only supporting one primary right now, we only need one external being tracked
  const [selectedExternalContent, setSelectedExternalContent] = useState<{ refId: string; id: string }>();

  useEffect(() => {
    if (!isOpen) return;

    (async () => {
      const _defaultContentIds: string[] = [];
      const _defaultContentInstances = (await Promise.all(
        contentsFromState
          .filter((content) => content.id)
          .map((content) => {
            _defaultContentIds.push(content.id);
            return fetchContentInstance(content.id);
          }),
      )) as Content[];

      setSinglySelectedAndDefaultContentIds(() => _defaultContentIds);
      setSelectedContentInstances(_defaultContentInstances);
      const externalContent = contentsFromState.find((content) => content.stepIdx !== stepIdx);
      if (externalContent && externalContent.id)
        setSelectedExternalContent({
          refId: externalContent.refId,
          id: externalContent.id,
        });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [singleSelectContentId, setSingleSelectContentId] = useState('');

  const reset = useCallback(() => {
    setSelectedContentInstances([]);
    setSelectedTemplate(undefined);
    setCurrentStep(STEPS.CONTENT_OPTION_SELECTOR);
    setSingleSelectContentId('');
    setSelectedExternalContent(undefined);
  }, []);

  const confirmContentInstancesSelection = useCallback(
    (contents: Content[]) => {
      if (!contents?.length) {
        return;
      }

      if (onChangeContent) onChangeContent();
      if (contentRefId) {
        if (selectedExternalContent && stepPushRefId) {
          dispatch({
            type: 'CHANGE_PRIMARY_CONTENT',
            payload: {
              oldPrimaryRefId: contentsFromState.find((c) => c.type === 'primary')?.refId,
              newPrimaryRefId: selectedExternalContent.refId,
              affectedEntities: [{ refId: stepPushRefId, var: '{{dep.content-id.0}}' }],
              stepIdx,
            },
          });
        }
        dispatch({
          type: 'UPDATE_CONTENT',
          payload: { [contentRefId]: contents.find((content) => content.id === singleSelectContentId) },
        });
        dispatch({
          type: ActionTypes.SET_UI_NODE_ATTR,
          payload: {
            refId: contentRefId,
            fields: {
              enableOptionalNode: true,
            },
          },
        });

        setSingleSelectContentId('');

        return;
      }

      const addMoreContents = contents.filter((content) => {
        const isNewContent =
          singlySelectedAndDefaultContentIds.findIndex((contentId) => contentId === content.id) === -1;
        const isExternalContent = selectedExternalContent && content.id === selectedExternalContent.id;

        if (isNewContent && isExternalContent && stepPushRefId) {
          dispatch({
            type: 'CHANGE_PRIMARY_CONTENT',
            payload: {
              oldPrimaryRefId: contentsFromState.find((c) => c.type === 'primary')?.refId,
              newPrimaryRefId: selectedExternalContent.refId,
              affectedEntities: [{ refId: stepPushRefId, var: '{{dep.content-id.0}}' }],
              stepIdx,
            },
          });
        }

        return isNewContent && !isExternalContent;
      });

      const refIds = addMoreContents.map(() => genUUID());
      dispatch(insertContents({ refIds, stepIdx: stepIdx ?? 0, contents: addMoreContents }));
    },
    [
      onChangeContent,
      contentRefId,
      dispatch,
      stepIdx,
      selectedExternalContent,
      stepPushRefId,
      contentsFromState,
      singleSelectContentId,
      singlySelectedAndDefaultContentIds,
    ],
  );

  const goToPrevStep = useCallback(() => {
    switch (currentStep) {
      case STEPS.CONTENT_OPTION_SELECTOR:
        onClickClose();
        break;
      case STEPS.CONTENT_SELECTOR:
        if (startingStep === STEPS.CONTENT_SELECTOR) onClickClose();
        else setCurrentStep(STEPS.CONTENT_OPTION_SELECTOR);
        break;
      case STEPS.TEMPLATE_SELECTOR:
        if (startingStep === STEPS.TEMPLATE_SELECTOR) onClickClose();
        setCurrentStep(STEPS.CONTENT_OPTION_SELECTOR);
        break;
    }
  }, [startingStep, currentStep, onClickClose]);

  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case STEPS.CONTENT_OPTION_SELECTOR:
        switch (selectedOption) {
          case CONTENT_OPTIONS.NEW:
            setCurrentStep(STEPS.TEMPLATE_SELECTOR);
            break;
          case CONTENT_OPTIONS.EXISTING:
            setCurrentStep(STEPS.CONTENT_SELECTOR);
            break;
        }
        break;
      case STEPS.CONTENT_SELECTOR:
        confirmContentInstancesSelection(selectedContentInstances);
        onClickClose();
        break;
      case STEPS.TEMPLATE_SELECTOR:
        if (!!selectedTemplate) setCurrentStep(STEPS.CONTENT_EDITOR);
        break;
    }
  }, [
    currentStep,
    selectedTemplate,
    selectedContentInstances,
    confirmContentInstancesSelection,
    onClickClose,
    selectedOption,
  ]);

  const handleContentEditSuccess = async (content: Content) => {
    await queryClient.invalidateQueries({ queryKey: ['content-instances'], refetchType: 'all' });

    if (onChangeContent) onChangeContent();

    const contentIndex = contentInstances.findIndex((c) => c.id === content.id);
    const tempContentInstances = [...contentInstances];

    if (contentIndex >= 0) {
      tempContentInstances.splice(contentIndex, 1, content);
    } else {
      tempContentInstances.push(content);
    }

    dispatch(
      setContentInstances({
        contentInstances: tempContentInstances,
      }),
    );

    if (startingStep === STEPS.CONTENT_EDITOR || startingStep === STEPS.TEMPLATE_SELECTOR) {
      dispatch({
        type: 'UPDATE_CONTENT',
        payload: { [singleSelectStateContent?.refId as string]: content },
      });
      onClickClose();
    } else {
      if (contentRefId) {
        if (selectedContentInstances.length < 1) {
          setSelectedContentInstances([content]);
          setSingleSelectContentId(content.id || '');
        }
      } else {
        setSelectedContentInstances((prevState) => [...prevState, content]);
      }
      setCurrentStep(STEPS.CONTENT_SELECTOR);
    }
  };

  const handleContentEditCancel = () => {
    startingStep === STEPS.CONTENT_EDITOR ? onClickClose() : setCurrentStep(STEPS.TEMPLATE_SELECTOR);
  };

  useEffect(() => {
    setCurrentStep(startingStep);
  }, [startingStep]);

  const renderCurrentStep = () => {
    switch (currentStep) {
      case STEPS.CONTENT_OPTION_SELECTOR:
        return <ContentOptionSelector selectedOption={selectedOption} setSelectedOption={setSelectedOption} />;
      case STEPS.CONTENT_SELECTOR:
        return (
          <ContentInstancesSelector
            selectedContentInstances={selectedContentInstances}
            singleSelectContentId={singleSelectContentId}
            setSelectedContentInstances={setSelectedContentInstances}
            stepIdx={stepIdx}
            isSingleSelectMode={!!contentRefId}
            singleSelectTemplateIdConstraint={singleSelectStateContent?.templateIdConstraint}
            setSingleSelectContentId={setSingleSelectContentId}
            selectedExternalContent={selectedExternalContent}
            setSelectedExternalContent={setSelectedExternalContent}
            stepHasPush={!!stepPushRefId}
          />
        );
      case STEPS.TEMPLATE_SELECTOR:
        return (
          <ContentTemplateSelector
            setSelectedTemplate={setSelectedTemplate}
            selectedTemplate={selectedTemplate}
            isSingleSelectMode={!!contentRefId}
            singleSelectTemplateIdConstraint={singleSelectStateContent?.templateIdConstraint}
          />
        );
      case STEPS.CONTENT_EDITOR:
        return (
          <DynamicContentIframe
            contentInstanceId={selectedOption === CONTENT_OPTIONS.NEW ? undefined : singleSelectStateContent?.id}
            contentTemplateId={selectedTemplate?.id}
            contentPrototypeId={singleSelectStateContent?.prototypeId}
            onCancel={handleContentEditCancel}
            onSuccess={handleContentEditSuccess}
          />
        );
      default:
        return null;
    }
  };

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: STEPS_TITLES[currentStep],
        goBackIcon: <ActionsIcon fill="#ffffff" />,
        goBackTitle: 'Actions',
        goBackActionHandler: onClickClose,
        ...headerInfo,
      },
      footerInfo: {
        primaryActionText: 'Confirm',
        primaryActionHandler: goToNextStep,
        secondaryActionText: 'Back',
        secondaryActionHandler: goToPrevStep,
        footerText: currentStep === STEPS.TEMPLATE_SELECTOR ? 'Select a template to proceed' : undefined,
      },
      size: 'large',
      showFooter: currentStep !== STEPS.CONTENT_EDITOR,
      onSlideOutComplete: reset,
      className: className,
    }),
    [isOpen, currentStep, onClickClose, headerInfo, goToNextStep, goToPrevStep, reset, className],
  );

  useEffect(() => {
    switch (currentStep) {
      case STEPS.CONTENT_SELECTOR:
        setSelectedTemplate(undefined);
        break;
    }
  }, [currentStep]);

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div className={MAIN_CLASS}>
        <div className={CLASSES.CONTENT_WRAPPER}>{renderCurrentStep()}</div>
        {(currentStep === STEPS.CONTENT_SELECTOR || currentStep === STEPS.TEMPLATE_SELECTOR) && (
          <div className={CLASSES.PREVIEW_WRAPPER}>
            <div className={CLASSES.FIX_PREVIEW_BORDER}>
              <SmartphonePreview
                isTemplate={!!selectedTemplate}
                contents={
                  currentStep === STEPS.CONTENT_SELECTOR
                    ? selectedContentInstances.map((c) => extractContentPreviewFields(c, defaultLang))
                    : selectedTemplate && [extractContentPreviewFields(selectedTemplate, defaultLang)]
                }
              />
            </div>
          </div>
        )}
      </div>
    </SlidingSidePanel>
  );
};

export default ContentManager;
