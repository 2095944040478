import './OffersSlideout.scss';

import React, { useEffect, useMemo, useState } from 'react';
import {
  Merchant,
  MerchantsRouteParams,
  Offer,
  OFFER_STATUSES,
  OFFER_VERIFICATION_STATUSES,
  STATUS_ENTITIES,
} from 'pages/MerchantPortal/merchant-portal.types';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import MemoizedSlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { FlightButton, FlightTooltip, getIcon } from '@flybits/design-system';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';
import { useHistory, useParams } from 'react-router-dom';
import { currencyFormatter, epochToDate, getFullDate } from 'pages/MerchantPortal/merchant-portal.helpers';
import MerchantAndOfferStatus from 'components/MerchantPortal/MerchantAndOfferStatus/MerchantAndOfferStatus';

const OFFERS_SLIDEOUT = 'offers-slideout';
const OFFERS_SLIDEOUT_CLASSES = {
  CONTAINER: `${OFFERS_SLIDEOUT}__container`,
  SECTIONS: `${OFFERS_SLIDEOUT}__container__sections`,
};

const OFFERS_HEADER = `${OFFERS_SLIDEOUT_CLASSES.CONTAINER}__header`;
const OFFERS_HEADER_CLASSES = {
  INFO: `${OFFERS_HEADER}__info`,
  INFO_LOGO: `${OFFERS_HEADER}__info__logo`,
  INFO_TITLE: `${OFFERS_HEADER}__info__title`,
  OFFERS: `${OFFERS_HEADER}__offers`,
  OFFER: `${OFFERS_HEADER}__offers__offer`,
  SELECTED: `${OFFERS_HEADER}__offers__offer--selected`,
};

const OFFER_SECTION = `${OFFERS_SLIDEOUT_CLASSES.SECTIONS}__section`;
const OFFER_SECTION_CLASSES = {
  HEADER: `${OFFER_SECTION}__header`,
  FIELDS: `${OFFER_SECTION}__fields`,
};

const OFFER_FIELD = `${OFFER_SECTION_CLASSES.FIELDS}__field`;
const OFFER_FIELD_CLASSES = {
  NAME: `${OFFER_FIELD}__name`,
  VAL: `${OFFER_FIELD}__val`,
};

const TARGET_BUDGET = `target-budget`;
const TARGET_BUDGET_CLASSES = {
  HEADER: `${TARGET_BUDGET}__header`,
  PROGRESS: `${TARGET_BUDGET}__progress`,
  DATE: `${TARGET_BUDGET}__date`,
};

const OFFER_DETAILS_FIELDS = {
  title: 'Offer title',
  description: 'Description',
};

const OFFER_INFO_FIELDS = {
  verificationStatus: 'Offer verification',
  status: 'Activation status',
  // temporarily hidden, no be support
  // type: 'Offer type',
  // redemptionLimit: 'Redemption limit',
  spendThreshold: 'Spend threshold',
  earnCap: 'Earn cap',
  notes: 'Offer note',
};

const OFFER_SCHEDULE_AND_BUDGET_FIELDS = {
  desiredStartDate: 'Desired start date',
  desiredEndDate: 'Expected end date',
  budgetStatus: 'Budget status',
  budgetNotes: 'Budget note',
};
interface TargetBudgetIndicatorProps extends React.ComponentProps<'div'> {
  currentValue: number;
  maxValue: number;
}

const TargetBudgetIndicator: React.FC<TargetBudgetIndicatorProps> = ({ currentValue, maxValue }) => {
  const percent = ~~((currentValue / maxValue) * 100); //~~ is the same as Math.round() ;)
  let color = 'transparent';
  if (percent > 0 && percent <= 50) color = '#16D892';
  else if (percent > 50 && percent < 90) color = '#FFC107';
  else color = '#FF541E';
  return (
    <div className={TARGET_BUDGET}>
      <div className={TARGET_BUDGET_CLASSES.HEADER}>
        {`${currencyFormatter.format(currentValue)}`}/ <span>{`${currencyFormatter.format(maxValue)}`}</span>
      </div>
      <div className={TARGET_BUDGET_CLASSES.PROGRESS}>
        <div role="progressbar" style={{ width: `${percent}%` }}>
          <div style={{ backgroundColor: color }}></div>
        </div>
      </div>
      <div className={TARGET_BUDGET_CLASSES.DATE}>As of Yesterday</div>
    </div>
  );
};

interface OffersHeaderProps extends React.ComponentProps<'div'> {
  merchant: Merchant;
  selectedOffer: Offer;
  setSelectedOffer: React.Dispatch<React.SetStateAction<Offer>>;
}

const OffersHeader: React.FC<OffersHeaderProps> = ({ merchant, selectedOffer, setSelectedOffer }) => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  const handleAddAnOffer = (merchantId: string) => {
    history.push(`/project/${projectId}/merchants/${merchantId}/create-offer`);
  };

  return (
    <div className={OFFERS_HEADER}>
      <div className={OFFERS_HEADER_CLASSES.INFO}>
        <div className={OFFERS_HEADER_CLASSES.INFO_LOGO}>
          {merchant.logoUrl ? <img src={merchant.logoUrl} alt="merchant logo" /> : getIcon('merchant', {})}
        </div>
        <div className={OFFERS_HEADER_CLASSES.INFO_TITLE}>{merchant.name}</div>
      </div>
      <div className={OFFERS_HEADER_CLASSES.OFFERS}>
        {merchant.offers?.map((offer) => (
          <FlightButton
            key={offer.id}
            label={offer.title}
            className={`${OFFERS_HEADER_CLASSES.OFFER} ${
              offer.id === selectedOffer.id ? OFFERS_HEADER_CLASSES.SELECTED : ''
            }`}
            onClick={() => setSelectedOffer(offer)}
          />
        ))}
        <FlightButton theme="link" iconLeft="add" label="Add new offer" onClick={() => handleAddAnOffer(merchant.id)} />
      </div>
    </div>
  );
};

const getFieldValue = (fieldKey: string, offer: Offer) => {
  switch (fieldKey) {
    case 'verificationStatus':
      return (
        <>
          <MerchantAndOfferStatus
            type={STATUS_ENTITIES.OFFER_VERIFICATION_STATUS}
            status={offer.verificationStatus}
            showTooltip
          />
        </>
      );
    case 'status':
      return (
        <MerchantAndOfferStatus
          type={STATUS_ENTITIES.OFFER_STATUS}
          status={offer.journeyInstanceStatus as OFFER_STATUSES}
        />
      );
    case 'budgetStatus':
      return (
        <div className={OFFER_FIELD_CLASSES.VAL}>
          <TargetBudgetIndicator currentValue={offer['budgetConsumed'] || 0} maxValue={offer['spendThreshold'] || 0} />
        </div>
      );
    case 'spendThreshold':
    case 'earnCap':
      return (
        <div className={OFFER_FIELD_CLASSES.VAL}>
          {currencyFormatter.format((offer[fieldKey as keyof Offer] as number) || 0)}
        </div>
      );
    case 'desiredStartDate':
    case 'desiredEndDate':
      const timestamp = offer[fieldKey as keyof Offer] as EpochTimeStamp;
      const date = epochToDate(timestamp);
      const fullDate = getFullDate(date);
      return <div className={OFFER_FIELD_CLASSES.VAL}>{`${fullDate}`}</div>;
    default:
      return <div className={OFFER_FIELD_CLASSES.VAL}>{offer[fieldKey as keyof Offer] as string}</div>;
  }
};

interface OfferFieldProps extends React.ComponentProps<'div'> {
  fieldKey: string;
  fieldName: string;
  offer: Offer;
}

const OfferField: React.FC<OfferFieldProps> = ({ fieldKey, fieldName, offer }) => (
  <div className={OFFER_FIELD}>
    <div className={OFFER_FIELD_CLASSES.NAME}>{fieldName}:</div>
    {getFieldValue(fieldKey, offer)}
  </div>
);

interface OfferSectionProps extends React.ComponentProps<'div'> {
  header: string;
  actions?: JSX.Element;
  fields: { [key: string]: string };
  offer: Offer;
}

const OfferSection: React.FC<OfferSectionProps> = ({ header, fields, offer, actions }) => (
  <div className={OFFER_SECTION}>
    <div className={OFFER_SECTION_CLASSES.HEADER}>
      {header}
      {actions && actions}
    </div>
    <div className={OFFER_SECTION_CLASSES.FIELDS}>
      {Object.keys(fields).map((field) => (
        <OfferField key={field} fieldKey={field} fieldName={fields[field]} offer={offer} />
      ))}
    </div>
  </div>
);

type OfferEditButtonProps = {
  merchant: Merchant;
  offer: Offer;
  isFBAdmin?: boolean;
};

const OfferEditButton = ({ merchant, offer, isFBAdmin = false }: OfferEditButtonProps) => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  if (!isFBAdmin) {
    return (
      <FlightTooltip description="If you need to make changes to this offer, please contact the Flybits Solution Team">
        <FlightButton disabled theme="secondary" iconRight="editFullOutline" label="Edit" />
      </FlightTooltip>
    );
  }

  const isOfferNotEditable =
    offer.journeyInstanceStatus === OFFER_STATUSES.ACTIVE ||
    offer.journeyInstanceStatus === OFFER_STATUSES.SCHEDULED ||
    offer.journeyInstanceStatus === OFFER_STATUSES.EXPIRED ||
    offer.journeyInstanceStatus === OFFER_STATUSES.CANCELLED;

  if (isOfferNotEditable) {
    return (
      <FlightTooltip description={`The offer is ${offer.journeyInstanceStatus} and can't be edited`}>
        <FlightButton disabled theme="secondary" iconRight="editFullOutline" label="Edit" />
      </FlightTooltip>
    );
  } else {
    return (
      <FlightButton
        theme="secondary"
        iconRight="editOutline"
        label="Edit"
        onClick={() => history.push(`/project/${projectId}/merchants/${merchant.id}/offers/${offer.id}/edit-offer`)}
      />
    );
  }
};

type TCreateExperienceButtonProps = {
  offer: Offer;
  isFBAdmin: boolean;
};

const OfferExperienceButton = ({ offer, isFBAdmin }: TCreateExperienceButtonProps) => {
  const history = useHistory();
  const { pid: projectId } = useParams<MerchantsRouteParams>();

  const hasInstance = offer.journeyInstanceId;
  const isTenantConfigured = !!offer.journeyTemplateId;
  const isVerified = offer.verificationStatus === OFFER_VERIFICATION_STATUSES.VERIFIED;

  if (!isFBAdmin || !isTenantConfigured)
    return (
      <FlightTooltip
        description={
          !isFBAdmin
            ? 'If you need to make changes, please contact the Flybits Solution Team'
            : 'Missing Journey Template; Go to Developer Portal’s Merchant Settings page to associate a Journey Template'
        }
      >
        View Only {getIcon('infoStroke', {})}
      </FlightTooltip>
    );
  return (
    <FlightTooltip description="The offer must be verified prior to creating an experience" isEnabled={!isVerified}>
      <FlightButton
        label={hasInstance ? 'Edit experience' : 'Create experience'}
        theme="secondary"
        size="small"
        disabled={!isVerified}
        onClick={() =>
          history.push(
            `/project/${projectId}/experiences/${
              hasInstance ? offer.journeyInstanceId : offer.journeyTemplateId
            }/flow-builder?viewFlow=true&parentEntityType=offer&parentEntityId=${offer.id}&mode=${
              hasInstance ? 'edit' : 'create'
            }`,
          )
        }
      />
    </FlightTooltip>
  );
};

interface OffersSlideoutProps extends React.ComponentProps<'div'> {
  merchant: Merchant;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const OffersSlideout: React.FC<OffersSlideoutProps> = ({ merchant, show, setShow }) => {
  const [selectedOffer, setSelectedOffer] = useState({} as Offer);
  const isFBAdmin = useSelector((state) => state.auth.user?.level && state.auth.user.level >= 21);

  useEffect(() => {
    if (!show) {
      return;
    }

    setSelectedOffer(merchant.offers?.[0]);
  }, [show, merchant.offers]);

  const offersSlideoutProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show,
      headerInfo: {
        mainTitle: 'View Merchant Offers',
        showCloseButton: true,
      },
      showFooter: false,
      footerInfo: {
        secondaryActionHandler: () => setShow(false),
      },
      className: OFFERS_SLIDEOUT,
    }),
    [show, setShow],
  );

  return (
    <MemoizedSlidingSidePanel {...offersSlideoutProps}>
      <div className={OFFERS_SLIDEOUT_CLASSES.CONTAINER}>
        <OffersHeader merchant={merchant} selectedOffer={selectedOffer} setSelectedOffer={setSelectedOffer} />
        <div className={OFFERS_SLIDEOUT_CLASSES.SECTIONS}>
          <OfferSection
            header="Offer Details"
            actions={<OfferEditButton merchant={merchant} offer={selectedOffer} isFBAdmin={!!isFBAdmin} />}
            fields={OFFER_DETAILS_FIELDS}
            offer={selectedOffer}
          />
          <OfferSection
            header="Offer Information"
            actions={<OfferExperienceButton offer={selectedOffer} isFBAdmin={!!isFBAdmin} />}
            fields={OFFER_INFO_FIELDS}
            offer={selectedOffer}
          />
          <OfferSection
            header="Offer Schedule and Budget"
            fields={OFFER_SCHEDULE_AND_BUDGET_FIELDS}
            offer={selectedOffer}
          />
        </div>
      </div>
    </MemoizedSlidingSidePanel>
  );
};

export default OffersSlideout;
