import './EngagementTables.scss';
import React, { useMemo, useState } from 'react';
import { FlightTable, FlightTextInput, getIcon, FlightDateRangePicker } from '@flybits/design-system';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import useDebounce from 'hooks/useDebounce';
import AnalyticsExperiencesAPI from 'services/api/analytics-experiences.api';
import { CLASSES, DATE_FORMAT_OPTIONS } from '../constants';
import LoadingIcon from 'components/Shared/LoadingIcon/LoadingIcon';
import { epochToDateTimeString } from 'helpers/common.helper';
import { ContextRulesEngagementData, ENGAGEMENT_DATA_TYPES } from 'components/Analytics/types';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import DownloadEngagementData from 'pages/AnalyticsV2/Overview/Engagement/DownloadEngagementData';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps } from 'components/Shared/shared.types';
import { ReactComponent as IconDownloadData } from 'assets/icons/icon-download-data.svg';
import { AdvancedDateRange, DateRange } from 'pages/AnalyticsV2/types';

const tableHeaders = [
  {
    name: 'key',
    key: 'key',
    isVisible: false,
    isSortable: false,
  },
  {
    name: 'Name',
    key: 'name',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Status',
    key: 'status',
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Total reach',
    key: 'total_reach',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The total number of times this particular rule was fulfilled, within the time range and labels you have selected.',
  },
  {
    name: 'Unique reach',
    key: 'unique_reach',
    isVisible: true,
    isSortable: true,
    tooltip:
      'The number of unique users who have fulfilled this particular rule, within the time range and labels you have selected.',
  },
];

const fetchContextRules = async (
  analyticsExperiencesApi: AnalyticsExperiencesAPI,
  page: number,
  perPage: number,
  dateRange: DateRange<Date>,
  search: string,
  sortBy: string,
  sortOrder: string,
): Promise<ContextRulesEngagementData> => {
  const res = await analyticsExperiencesApi.getContextRulesEngagement({
    lastFrom: Math.trunc((dateRange[0]?.getTime() ?? new Date().setHours(0, 0, 0, 0)) / 1000),
    lastTo: Math.trunc((dateRange[1]?.getTime() ?? new Date().setHours(23, 59, 59, 999)) / 1000),
    offset: (page - 1) * perPage,
    limit: perPage,
    search,
    sortBy,
    sortOrder,
  });

  return {
    contextRules: res.data || [],
    pagination: res.pagination,
  };
};

const confirmationDialogProps: ConfirmationDialogProps = {
  icon: <IconDownloadData />,
  title: 'Download rules table',
  description: 'Note that the table will be downloaded based on your current filters',
  primaryAction: {
    value: 'Download',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};

const RulesEngagementTable = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const analyticsExperiencesApi = useMemo(() => new AnalyticsExperiencesAPI(), []);

  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  const endOfDay = new Date();
  endOfDay.setHours(23, 59, 59, 999);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [dateRange, setDateRange] = useState<AdvancedDateRange>([startOfToday, new Date(), 0, 'day']);
  const [queryDateRange, setQueryDateRange] = useState<AdvancedDateRange>([startOfToday, endOfDay]);
  const [search, setSearch] = useState('');
  const [sortByColumn, setSortByColumn] = useState('unique_reach');
  const [sortOrder, setSortOrder] = useState('desc');
  const debouncedSearch = useDebounce(search, 300);
  const [rulesDataForCSV, setRulesDataForCSV] = useState<Record<string, string>[]>([]);
  const [isAllRulesDataLoading, setAllRulesDataLoading] = useState(false);
  const [DownloadConfirmModal, showDownloadConfirmModal] = useConfirmModal(confirmationDialogProps);

  const { data, status, isFetching, isLoading } = useQuery({
    queryKey: [
      'context-rules-engagement',
      currentPage,
      perPage,
      queryDateRange,
      debouncedSearch,
      sortByColumn,
      sortOrder,
    ],
    queryFn: () =>
      fetchContextRules(
        analyticsExperiencesApi,
        currentPage,
        perPage,
        [queryDateRange[0], queryDateRange[1]],
        debouncedSearch,
        sortByColumn,
        sortOrder,
      ),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const handleSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    setCurrentPage(1);
    setSearch(value);
  };

  const handleDateRangeChange = (newDate: AdvancedDateRange) => {
    setDateRange(newDate);
    const hasFullRange = newDate[0] !== null && newDate[1] !== null;

    if (hasFullRange) {
      const endOfDay = new Date(newDate[1] ?? new Date());
      if (newDate.length < 3 || (newDate.length >= 3 && newDate[2] === 0)) {
        // Known interval - no custom or single day
        endOfDay.setHours(23, 59, 59);
      }

      setQueryDateRange([newDate[0], endOfDay]);
    }
  };

  const handleHeaderSort = (e: { key: string }) => {
    if (e.key !== sortByColumn) {
      setSortByColumn(e.key);
      setSortOrder('desc');
    } else {
      setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    }
  };

  const generateCSVRecords = (data: ContextRulesEngagementData): Record<string, string>[] => {
    return data.contextRules.map((contextRule) => ({
      'Context Rule ID': contextRule.ruleId,
      'Context Rule Name': contextRule.ruleName,
      Status: contextRule.status,
      'Total Reach': contextRule.totalReach.toLocaleString(),
      'Unique Reach': contextRule.uniqueReach.toLocaleString(),
    }));
  };

  const handleClickDownload = async () => {
    try {
      if (await showDownloadConfirmModal()) {
        setAllRulesDataLoading(true);
        const allData = await queryClient.fetchQuery({
          queryKey: ['all-context-rules-engagement', queryDateRange, debouncedSearch, sortByColumn, sortOrder],
          queryFn: () =>
            fetchContextRules(
              analyticsExperiencesApi,
              1,
              100000, // Sending higher limit to BE to fetch all records
              [queryDateRange[0], queryDateRange[1]],
              debouncedSearch,
              sortByColumn,
              sortOrder,
            ),
        });

        if (!allData.contextRules?.length) {
          dispatch({
            type: 'SHOW_SNACKBAR',
            payload: {
              title: 'Info',
              content: 'No context rules engagement data found',
              type: 'info',
            },
          });
        } else {
          setRulesDataForCSV(generateCSVRecords(allData));
        }
      }
    } catch {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Something went wrong and could not download context rules engagement data.',
          type: 'error',
        },
      });
    } finally {
      setAllRulesDataLoading(false);
    }
  };

  return (
    <div className={CLASSES.CARD}>
      <div className={CLASSES.CARD_HEADER}>
        <h2>Context rules</h2>
        <div className={CLASSES.DATE_RANGE}>
          {dateRange[2] === 0
            ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
            : `${epochToDateTimeString(dateRange[0] || 0, 'en-US', DATE_FORMAT_OPTIONS)} - ${epochToDateTimeString(
                dateRange[1] || 0,
                'en-US',
                DATE_FORMAT_OPTIONS,
              )}`}
        </div>
        <div>
          <FlightDateRangePicker
            className={CLASSES.DATE_RANGE_DD}
            value={dateRange}
            onChange={handleDateRangeChange}
            maxDate={new Date()}
            includeAllTime={false}
            includeToday
            isIncludeCustom
            replaceCustomRangeLabel={false}
          />
        </div>
        <div className={CLASSES.DOWNLOAD}>
          <DownloadEngagementData
            data={rulesDataForCSV}
            type={ENGAGEMENT_DATA_TYPES.RULES}
            isDownloading={isAllRulesDataLoading}
            onClickDownload={handleClickDownload}
          />
        </div>
      </div>
      {isLoading && (
        <div className={CLASSES.SPINNER}>
          <LoadingIcon />
        </div>
      )}
      <div className={CLASSES.SEARCH_WRAPPER}>
        <FlightTextInput
          onChange={handleSearchChange}
          value={search}
          placeholderText="Search"
          iconInput="search"
          width="280px"
          hasClearIcon
        />
      </div>
      {status === 'success' && (
        <>
          <div className={CLASSES.TABLE_WRAPPER}>
            <FlightTable
              tableHeaders={tableHeaders}
              tableData={data.contextRules.map((contextRule) => ({
                key: contextRule.ruleId,
                name: contextRule.ruleName,
                status: contextRule.status,
                total_reach: contextRule.totalReach.toLocaleString(),
                unique_reach: contextRule.uniqueReach.toLocaleString(),
              }))}
              sortByKey={sortByColumn}
              sortOrder={sortOrder}
              handleHeaderSort={handleHeaderSort}
              hasPaginationBeforeTable={false}
              hasPaginationAfterTable={!!data.contextRules.length}
              paginationProps={{
                totalPageNumber: Math.ceil(data.pagination.totalRecords / perPage) || 1,
                currentPageNumber: currentPage,
                rowsPerPageOptions: [5, 10, 15, 20],
                currentRowsPerPage: perPage,
                handlePageChange: (page: number) => setCurrentPage(page),
                handleRowsPerPageChange: (num: number) => setPerPage(num),
              }}
              isLoading={isFetching}
            />
            {!!!data?.contextRules?.length && !isFetching && (
              <div className={CLASSES.EMPTY_DATA}>
                {getIcon('search', {})}
                No results found
              </div>
            )}
          </div>
        </>
      )}
      <DownloadConfirmModal />
    </div>
  );
};

export default RulesEngagementTable;
