import { useCallback, useState } from 'react';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps } from 'components/Shared/shared.types';
import useDownloadCSV from 'hooks/useDownloadCSV';
import { VISUALIZER_OPTIONS } from 'pages/AnalyticsV2/CustomAnalytics/CreateOrEditChart/constants';

type UseExportGraph = {
  confirmModalProps: ConfirmationDialogProps;
  exportGraphData: Record<string, string>[];
  graphType: VISUALIZER_OPTIONS;
};

const useExportGraph = ({ confirmModalProps, exportGraphData, graphType }: UseExportGraph) => {
  const dispatch = useDispatch();

  const [csvData, setCSVData] = useState<Record<string, string>[]>([]);
  const [ExportGraphConfirmModal, showExportGraphConfirmModal] = useConfirmModal(confirmModalProps);

  const handleExportGraph = useCallback(async () => {
    try {
      if (await showExportGraphConfirmModal()) {
        setCSVData(exportGraphData);

        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: {
            content: 'Your chart has been exported in CSV format',
            type: 'info',
          },
        });
      }
    } catch {
      dispatch({
        type: 'SHOW_SNACKBAR',
        payload: {
          title: 'Error',
          content: 'Something went wrong and could not download chart data.',
          type: 'error',
        },
      });
    }
  }, [dispatch, showExportGraphConfirmModal, exportGraphData]);

  useDownloadCSV({ data: csvData, filenamePrefix: `${graphType}_graph` });

  return { ExportGraphConfirmModal, handleExportGraph };
};

export default useExportGraph;
