import { VISUALIZER_OPTIONS } from './CustomAnalytics/CreateOrEditChart/constants';

export type TAnalyticsRouteParams = {
  pid: string;
  id: string;
};

export type ModuleConfig = {
  id: string;
  tenantId: string;
  name: string;
  description: string;
  labels: string[] | null;
  timeFrame: TimeFrame;
  scope: Scope;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metrics: any | null;
  sortMetric: number;
  sortDesc: boolean;
  chartConfig: ChartConfig;
  resultsConfig: ResultsConfig;
  createdAt: EpochTimeStamp;
  updatedAt: EpochTimeStamp;
};

export type ModuleConfigUpdatePayload = Omit<ModuleConfig, 'createdAt' | 'updatedAt'>;

export enum INTERVAL_OPTIONS {
  HOURLY = 'hour',
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
  ANNUALLY = 'year',
}

export type ChartConfigState = {
  title?: string;
  description?: string;
  selectedExperiences?: ExperienceSelectorItem[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metrics?: any;
  timeFrame?: { start: Date; end: Date; period: string; relative: [number, string] };
  visualizationType?: VISUALIZER_OPTIONS | null;
};

type TimeFrame = {
  type: string;
  absoluteStart: EpochTimeStamp;
  absoluteEnd: EpochTimeStamp;
  relativeTimeOffset: number;
  relativeTimeUnit: string;
  relativeTimeIsCurrentIncluded: boolean;
  timeInterval: INTERVAL_OPTIONS;
  timeZone: string;
};

type Scope = {
  components: string;
  interactions: string[];
  aggContext: {
    dataTypeId: string;
    valueKey: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columnConditions: any | null;
    andOrColConds: string;
    isAggCol: boolean;
  };
  journeyIds: string[];
};

type ChartConfig = {
  type: VISUALIZER_OPTIONS;
  title: string;
  xAxis: string;
  yAxis: string;
};

type ResultsConfig = {
  aggregateFunction: string;
  maxResults: number;
  sortOrder: string;
  countDistinct: boolean;
  cumulativeOverTime: boolean;
};

type BarGraphDataPoint = {
  journey_id: string;
  name: string;
  value: number;
};

type LineGraphDataPoint = {
  x: number;
  y: number;
  name: string;
};

export type LineGraphData = LineGraphDataPoint[];
export type BarGraphData = BarGraphDataPoint[];

export type TBarGraph = { data: BarGraphData; moduleConfig: ModuleConfig };
export type TLineGraph = { data: LineGraphData; moduleConfig: ModuleConfig };

export type ChartData = TBarGraph | TLineGraph;

export type ModuleConfigPayload = Omit<ModuleConfig, 'id'>;

export enum JourneyAnalyticsCollapsibleModes {
  DRAFT = 'draft',
  COMPLETED = 'completed',
}

export type DateRange<T> = [T | null, T | null];
export type RelativeDateRange = [number, string];
export type AdvancedDateRange = DateRange<Date> | [...DateRange<Date>, ...RelativeDateRange];

export type ExperienceSelectorItem = {
  key: string;
  name: string;
};
