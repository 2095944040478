import TemplatedExperienceAPI from 'services/api/templated-experience.api';
import { VISUALIZER_OPTIONS } from './CustomAnalytics/CreateOrEditChart/constants';
import {
  BarGraphData,
  ChartConfigState,
  ChartData,
  INTERVAL_OPTIONS,
  LineGraphData,
  ModuleConfig,
  TBarGraph,
  TLineGraph,
} from './types';

export function isBarGraph(chartData: ChartData): chartData is TBarGraph {
  return chartData.moduleConfig.chartConfig.type === VISUALIZER_OPTIONS.BAR;
}

export function isLineGraph(chartData: ChartData): chartData is TLineGraph {
  return chartData.moduleConfig.chartConfig.type === VISUALIZER_OPTIONS.LINE;
}

// TODO: map chartConfig properly, and change the output to a serialization payload
export function serializeChartConfig(chartConfig: ChartConfigState): ModuleConfig {
  const yAxis = `${chartConfig.metrics?.entity}: ${chartConfig.metrics?.metric}`;
  return {
    id: '',
    tenantId: '',
    name: chartConfig.title ?? '',
    description: chartConfig.description ?? '',
    labels: null,
    timeFrame: {
      type: 'relative',
      absoluteStart: 0,
      absoluteEnd: 0,
      relativeTimeOffset: 1,
      relativeTimeUnit: 'year',
      relativeTimeIsCurrentIncluded: false,
      timeInterval: INTERVAL_OPTIONS.DAILY,
      timeZone: 'UTC',
    },
    scope: {
      components: '',
      interactions: [],
      aggContext: {
        dataTypeId: '',
        valueKey: '',
        columnConditions: null,
        andOrColConds: '',
        isAggCol: false,
      },
      journeyIds: [],
    },
    metrics: chartConfig.metrics,
    sortMetric: 0,
    sortDesc: false,
    chartConfig: {
      type: chartConfig.visualizationType ?? VISUALIZER_OPTIONS.BAR,
      title: chartConfig.title ?? '',
      xAxis: chartConfig.visualizationType === VISUALIZER_OPTIONS.BAR ? yAxis : 'Time',
      yAxis: chartConfig.visualizationType === VISUALIZER_OPTIONS.BAR ? 'Time' : yAxis,
    },
    resultsConfig: {
      aggregateFunction: '',
      maxResults: 0,
      sortOrder: '',
      countDistinct: false,
      cumulativeOverTime: false,
    },
    createdAt: 0,
    updatedAt: 0,
  };
}

export async function deserializeModuleConfig(moduleConfig: ModuleConfig): Promise<ChartConfigState> {
  const templatedExperienceAPI = new TemplatedExperienceAPI();
  const { name, description, metrics, chartConfig, scope, timeFrame } = moduleConfig;

  const selectedExperiences = await Promise.all(
    scope.journeyIds?.map(async (id) => {
      try {
        const journey = await templatedExperienceAPI.getTemplatedExperienceInstance(id);
        return journey.name ?? '';
      } catch (e) {
        return 'Unknown Instance';
      }
    }) ?? [],
  );

  const customDateRange: Date[] = [];
  let relativeDateRange: [number, string] = [1, 'day'];
  if (timeFrame.type === 'absolute') {
    customDateRange.push(new Date(timeFrame.absoluteStart * 1000), new Date(timeFrame.absoluteEnd * 1000));
  } else {
    relativeDateRange = [timeFrame.relativeTimeOffset, timeFrame.relativeTimeUnit];
  }

  return {
    title: name,
    description,
    selectedExperiences,
    metrics,
    timeFrame: {
      start: customDateRange[0],
      end: customDateRange[1],
      period: timeFrame.timeInterval,
      relative: relativeDateRange,
    },
    visualizationType: chartConfig.type ?? VISUALIZER_OPTIONS.BAR,
  };
}

export function generateGraphData(type?: VISUALIZER_OPTIONS | null, timeframe?: ChartConfigState['timeFrame']) {
  switch (type) {
    case VISUALIZER_OPTIONS.LINE:
      return generateLineGraphData(timeframe);
    case VISUALIZER_OPTIONS.TABLE:
      return generateTableData(timeframe);
    case VISUALIZER_OPTIONS.BAR:
      return generateBarGraphData(timeframe);
    default:
      return [];
  }
}
export function getDaysBetweenDates(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  const diffInTime = endDate.getTime() - startDate.getTime();
  return Math.round(diffInTime / oneDay);
}
// TODO: check if the timeframe should affect the preview
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateLineGraphData(timeframe?: ChartConfigState['timeFrame']) {
  const data: LineGraphData = [];
  const yValues = Array.from({ length: 10 }, () => Math.random() * 100).sort((a, b) => a - b);

  const startTime = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;

  for (let i = 0; i < 10; i++) {
    const point = {
      name: 'data',
      x: startTime + i * oneDay,
      y: yValues[i],
    };
    data.push(point);
  }

  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateBarGraphData(timeframe?: ChartConfigState['timeFrame']) {
  const data: BarGraphData = [];
  const values = Array.from({ length: 10 }, () => Math.random() * 100).sort((a, b) => a - b);

  const startTime = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;

  for (let i = 0; i < 10; i++) {
    const point = {
      journey_id: 'data',
      name: new Date(startTime + i * oneDay * 1000).getDate().toString(),
      value: values[i],
    };
    data.push(point);
  }

  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function generateTableData(timeframe?: ChartConfigState['timeFrame']) {
  const data: BarGraphData = [];
  const values = Array.from({ length: 10 }, () => Math.random() * 100).sort((a, b) => a - b);

  const startTime = Date.now();
  const oneDay = 24 * 60 * 60 * 1000;

  for (let i = 0; i < 10; i++) {
    const point = {
      journey_id: 'data',
      name: new Date(startTime + i * oneDay).toDateString(),
      value: values[i],
    };
    data.push(point);
  }

  return data;
}
