import './EngagementTables.scss';
import React from 'react';
import PushEngagementTable from './PushEngagementTable';
import RulesEngagementTable from './RulesEngagementTable';
import ContentEngagementTable from './ContentEngagementTable';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { MAIN_CLASS } from '../constants';
import JourneyEngagementTable from 'pages/AnalyticsV2/Overview/Engagement/JourneyEngagementTable';

const EngagementTables: React.FC = () => {
  const { flags } = useFeatureFlag();

  const showEngagementTables = flags['tx_show_analytics_engagement'];
  const showJourneyTable = flags['tx_journey_analytics_table'];

  return (
    showEngagementTables && (
      <div className={MAIN_CLASS}>
        {showJourneyTable && <JourneyEngagementTable />}
        <PushEngagementTable />
        <RulesEngagementTable />
        <ContentEngagementTable />
      </div>
    )
  );
};

export default EngagementTables;
