import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { ENGAGEMENT_DATA_TYPES } from 'components/Analytics/types';
import useDownloadCSV from 'hooks/useDownloadCSV';

export interface DownloadEngagementDataProps extends React.ComponentProps<'div'> {
  data: Record<string, string>[];
  type: ENGAGEMENT_DATA_TYPES;
  isDownloading: boolean;
  onClickDownload: () => void;
}

const DownloadEngagementData: React.FC<DownloadEngagementDataProps> = ({
  data,
  type,
  isDownloading,
  onClickDownload,
}) => {
  useDownloadCSV({ data, filenamePrefix: `${type}_engagement` });

  return (
    <FlightButton
      label="Download"
      iconLeft="download"
      onClick={onClickDownload}
      theme="secondary"
      loading={isDownloading}
      disabled={isDownloading}
    />
  );
};

export default DownloadEngagementData;
