import './ModulePositionManager.scss';
import { getIcon } from '@flybits/design-system';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { Zone } from 'pages/Zones/types';
import ModuleListItem from './ModuleListItem/ModuleListItem';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';

type TModulePositionManager = {
  isOpen: boolean;
  onClose: () => void;
};

const MAIN_CLASS = 'module-position-manager';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CLASSES = {
  CATEGORIES: `${MAIN_CLASS}__categories`,
  CATEGORY_CONTENT: `${MAIN_CLASS}__category-content`,
  LABELS_CONTENT: `${MAIN_CLASS}__labels-content`,
  PINNED_CONTENT: `${MAIN_CLASS}__pinned-content`,
  PLACEHOLDER: `${MAIN_CLASS}__placeholder`,
};

const ModulePositionManager: React.FC<TModulePositionManager> = ({ isOpen, onClose }) => {
  const { selectedZone, updateZone } = useContext(PrioritizationContext);
  const [currentDraggingIndex, setCurrentDraggingIndex] = useState<number>();
  const [zone, setZone] = useState(selectedZone);

  useEffect(() => {
    if (isOpen && selectedZone) {
      setZone(selectedZone);
    }
  }, [isOpen, selectedZone]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleApply = useCallback(() => {
    updateZone(zone as Zone);
    onClose();
  }, [zone, updateZone, onClose]);

  const handleChangePosition = (oldIndex: number, newIndex: number) => {
    const _modules = [...(selectedZone.modules || [])];
    [_modules[oldIndex], _modules[newIndex]] = [_modules[newIndex], _modules[oldIndex]];

    const _zone = { ...selectedZone } as Zone;
    _zone.modules = _modules;

    setZone(_zone);
  };

  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      show: isOpen,
      headerInfo: {
        mainTitle: '',
        goBackIcon: getIcon('itemsList', {}) as JSX.Element,
        goBackTitle: 'Module Position',
        goBackSubTitle: 'Arrange the modules in this zone according to your preferred order',
        goBackActionHandler: onClose,
      },
      footerInfo: {
        primaryActionText: 'Apply',
        primaryActionHandler: handleApply,
        secondaryActionText: 'Cancel',
        secondaryActionHandler: handleCancel,
      },
      size: 'medium',
      className: MAIN_CLASS,
    }),
    [isOpen, onClose, handleApply, handleCancel],
  );

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <div>
        {zone.modules?.map((module, index) => (
          <ModuleListItem
            key={module.id}
            index={index + 1}
            listLength={zone.modules?.length || 0}
            name={module.name}
            onDragStart={setCurrentDraggingIndex}
            onDragEnd={() => setCurrentDraggingIndex(undefined)}
            onChangePosition={(oldIndex, newIndex) => handleChangePosition(oldIndex - 1, newIndex - 1)}
            showDropzone={
              currentDraggingIndex !== undefined &&
              currentDraggingIndex !== index + 1 &&
              currentDraggingIndex !== index + 2
            }
          />
        )) || null}
      </div>
    </SlidingSidePanel>
  );
};

export default ModulePositionManager;
