import './BaseNode.scss';

import { Handle, Position } from 'reactflow';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FlightTooltip, getIcon } from '@flybits/design-system';
import { IconProps } from 'pages/FlowBuilder/assets/icons/icon.types';
import { defaultTargetHandleStyle, defaultSourceHandleStyle } from 'pages/FlowBuilder/constants/flow-builder.constants';
import { TOUCHPOINT_STATUSES } from 'components/ExperienceCanvas/types';
import IncompleteIcon from 'pages/ExperienceCanvas/assets/icons/IncompleteIcon';

const MAIN_CLASS = 'base-node';
const CLASSES = {
  SELECTED: `${MAIN_CLASS}--selected`,
  ERROR: `${MAIN_CLASS}--error`,
  INCOMPLETE: `${MAIN_CLASS}--incomplete`,
  PENDING_ACTIONS_MENU: `${MAIN_CLASS}__pending-actions-menu`,
  OVERFLOW_CONTAINER: `${MAIN_CLASS}__overflow-container`,
  OVERFLOW_TRIGGER: `${MAIN_CLASS}__overflow-container__trigger`,
  OVERFLOW_MENU: `${MAIN_CLASS}__overflow-container__menu`,
  OVERFLOW_MENU_ICON_LEFT: `${MAIN_CLASS}__overflow-container__menu__icon--left`,
  OVERFLOW_MENU_ICON_RIGHT: `${MAIN_CLASS}__overflow-container__menu__icon--right`,
  ICON_CONTAINER: `${MAIN_CLASS}__icon-container`,
  ICON: `${MAIN_CLASS}__icon-container__icon`,
  ICON_DISABLED: `${MAIN_CLASS}__icon-container__icon--disabled`,
  ICON_TAG: `${MAIN_CLASS}__icon-container__icon__icon-tag`,
  CONTAINER: `${MAIN_CLASS}__container`,
  BADGE_INDICATOR: `${MAIN_CLASS}__badge-indicator`,
};

export type FlowNodeMenuItem = {
  icon: string;
  label: string;
  onClick: () => void;
  iconRight?: JSX.Element | null;
  iconRightTooltip?: string;
};

export type BaseNodeProps = {
  Icon: (props: IconProps) => React.JSX.Element;
  children: React.ReactNode;
  menuItems: FlowNodeMenuItem[];
  isPendingActionsMenuOpen?: boolean;
  pendingActionsMenuItems?: FlowNodeMenuItem[];
  className?: string;
  IconTag?: (props: IconProps) => React.JSX.Element;
  isOptional?: boolean;
  isDisabled?: boolean;
  iconUrl?: string;
  status?: string | TOUCHPOINT_STATUSES.COMPLETE;
};

function BaseNode({
  children,
  menuItems,
  isPendingActionsMenuOpen = false,
  pendingActionsMenuItems,
  className,
  Icon,
  IconTag,
  isOptional,
  isDisabled,
  iconUrl,
  status,
}: BaseNodeProps) {
  const [isOverflowOpen, setIsOverflowOpen] = useState(false);
  const [isSelected, setSelected] = useState(false);

  // imperfect dropdown with ref - can use double ref or re-style
  // flight-overflow later
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideOverflowMenu = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsOverflowOpen(false);
        setSelected(false);
      }
    };

    document.addEventListener('click', handleClickOutsideOverflowMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideOverflowMenu);
    };
  }, []);

  const handleFocus = useCallback(() => {
    if (isDisabled) {
      return;
    }
    setSelected(true);
  }, [isDisabled]);
  const handleBlur = useCallback(() => {
    if (isDisabled || isOverflowOpen) {
      return;
    }
    setSelected(false);
  }, [isDisabled, isOverflowOpen]);

  const renderStatusIcon = () => {
    switch (status) {
      case TOUCHPOINT_STATUSES.ERROR:
        return getIcon('error', { fill: '#871313', viewBox: '0 0 27 27' });
      case TOUCHPOINT_STATUSES.INCOMPLETE:
        return <IncompleteIcon fill="#FFB005" viewBox="0 0 27 27" />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`${MAIN_CLASS} ${className ?? ''} ${isSelected ? CLASSES.SELECTED : ''} ${
        status === TOUCHPOINT_STATUSES.ERROR ? CLASSES.ERROR : (status === TOUCHPOINT_STATUSES.INCOMPLETE ? CLASSES.INCOMPLETE : '')
      }`}
      onMouseOver={handleFocus}
      onMouseOut={handleBlur}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
    >
      {isPendingActionsMenuOpen && (
        <div className={CLASSES.PENDING_ACTIONS_MENU}>
          {pendingActionsMenuItems?.map((item, i) => (
            <button
              key={`node-${i}`}
              onClick={() => {
                item.onClick();
              }}
            >
              {getIcon(item.icon, {})}
              {item.label}
            </button>
          ))}
        </div>
      )}
      <div className={`${CLASSES.OVERFLOW_CONTAINER}`}>
        {!isDisabled && !!menuItems.length && (
          <button
            className={CLASSES.OVERFLOW_TRIGGER}
            onClick={() => {
              setIsOverflowOpen((prev) => !prev);
            }}
            aria-label="open node overflow menu"
          >
            {getIcon('moreVert', {})}
          </button>
        )}
        {isOverflowOpen && (
          <div className={CLASSES.OVERFLOW_MENU}>
            {menuItems.map((item, i) => (
              <button
                key={`node-${i}`}
                onClick={() => {
                  item.onClick();
                  setIsOverflowOpen(false);
                }}
              >
                {getIcon(item.icon, { className: CLASSES.OVERFLOW_MENU_ICON_LEFT })}
                {item.label}
                {item.iconRight && (
                  <div className={CLASSES.OVERFLOW_MENU_ICON_RIGHT}>
                    {item.iconRightTooltip ? (
                      <FlightTooltip isEnabled description={item.iconRightTooltip} direction="right">
                        {item.iconRight}
                      </FlightTooltip>
                    ) : (
                      item.iconRight
                    )}
                  </div>
                )}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className={CLASSES.ICON_CONTAINER}>
        <div className={`${CLASSES.ICON} ${isDisabled ? CLASSES.ICON_DISABLED : ''}`}>
          {IconTag && (
            <div className={CLASSES.ICON_TAG}>
              <IconTag />
            </div>
          )}
          {!isDisabled && <div className={CLASSES.BADGE_INDICATOR}>{renderStatusIcon()}</div>}
          {iconUrl ? <img src={iconUrl} alt="Node icon" /> : <Icon />}
        </div>
      </div>
      <div className={CLASSES.CONTAINER}>{children}</div>
      {!isOptional && <Handle type="target" position={Position.Top} style={defaultTargetHandleStyle} />}
      {!isOptional && <Handle type="source" position={Position.Bottom} style={defaultSourceHandleStyle} />}
    </div>
  );
}

export default BaseNode;
