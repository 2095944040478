import React, { useLayoutEffect, useRef } from 'react';

import './LabelPreviewContent.scss';
import { getIcon } from '@flybits/design-system';
import { ReactComponent as ContentWithLabelIcon } from 'assets/icons/content-with-label.svg';
import CalendarIcon from 'pages/ExperienceCanvas/assets/icons/CalendarIcon';

const MAIN_CLASS = 'label-preview-content';
const LABEL_PREVIEW_CONTENT_DETAILS = `${MAIN_CLASS}__label-preview-content-details`;
const LABEL_PREVIEW_CONTENT_DATA = `${LABEL_PREVIEW_CONTENT_DETAILS}__label-preivew-content-data`;
const LABEL_PREVIEW_CONTENT_SECTIONS = `${LABEL_PREVIEW_CONTENT_DATA}__label-preview-content-sections`;
const LABEL_PREVIEW_CONTENT_SECTION = `${LABEL_PREVIEW_CONTENT_SECTIONS}__label-preview-content-section`;

type LabelPreviewContentProps = {
  title: string | null | undefined;
  createdDate?: number;
  labels?: string[];
  contentId?: string;
  grandParentRect?: DOMRect;
}; // Change this name to be something better

type LabelPreviewContentLabelsProps = {
  labels?: string[];
};

const LabelPreviewContentLabels = ({ labels }: LabelPreviewContentLabelsProps) => {
  const LABEL_PREVIEW_CONTENT_LABELS = 'section-labels';
  return (
    <div className={`${LABEL_PREVIEW_CONTENT_LABELS}`}>
      {labels
        ? labels.map((label, index) => {
            return (
              <div className={`${LABEL_PREVIEW_CONTENT_LABELS}__label`} key={index}>
                <div className={`${LABEL_PREVIEW_CONTENT_LABELS}__label__text`}>{label}</div>
              </div>
            );
          })
        : null}
    </div>
  );
};

const LabelPreviewCotentTitle = ({ title }: { title: string | null | undefined }) => {
  const LABEL_PREVIEW_CONTENT_TITLE = `${LABEL_PREVIEW_CONTENT_DATA}__content-title`;
  return (
    <div className={`${LABEL_PREVIEW_CONTENT_TITLE}`}>
      <div className={`${LABEL_PREVIEW_CONTENT_TITLE}__text`}>{title}</div>
      <hr />
    </div>
  );
};

const LabelPreviewContentSections = ({ children }: { children?: React.ReactNode }) => {
  return <div className={`${LABEL_PREVIEW_CONTENT_SECTIONS}`}>{children}</div>;
};

const LabelPreviewContentSection = ({ children }: { children?: React.ReactNode }) => {
  return <div className={`${LABEL_PREVIEW_CONTENT_SECTION}`}>{children}</div>;
};

const LabelPreviewContentSectionHeader = ({ title, icon }: { title: string; icon: JSX.Element }) => {
  const LABEL_PREVIEW_CONTENT_SECTION_HEADER = `${LABEL_PREVIEW_CONTENT_SECTION}__header`;
  return (
    <div className={`${LABEL_PREVIEW_CONTENT_SECTION_HEADER}`}>
      {icon}
      <div className={`${LABEL_PREVIEW_CONTENT_SECTION_HEADER}__title`}>{title}</div>
    </div>
  );
};

const LabelPreviewContentCreatedDate = ({ createdDate }: { createdDate?: number }) => {
  const ddmmyy = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric', //  Or 'long'
  });

  const time = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  const ddmmyyformat = ddmmyy.format((createdDate ?? 0) * 1000);
  const timeformat = time.format((createdDate ?? 0) * 1000);

  const LABEL_PREVIEW_CONTENT_CREATED = 'section-created';
  const LABEL_PREVIEW_CONTENT_CREATED_DATE = `${LABEL_PREVIEW_CONTENT_CREATED}__date`;
  const LABEL_PREVIEW_CONTENT_CREATED_TIME = `${LABEL_PREVIEW_CONTENT_CREATED}__time`;
  return (
    <div className={`${LABEL_PREVIEW_CONTENT_CREATED}`}>
      <div className={LABEL_PREVIEW_CONTENT_CREATED_DATE}>{ddmmyyformat}, </div>
      <div className={LABEL_PREVIEW_CONTENT_CREATED_TIME}>{timeformat}</div>
    </div>
  );
};

const LabelPreviewContent = ({ title, createdDate, labels, contentId, grandParentRect }: LabelPreviewContentProps) => {
  const contentData = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const contentDataRect = contentData.current?.getBoundingClientRect();

    if (contentDataRect && grandParentRect) {
      if (contentDataRect.right > grandParentRect.right) {
        const value = `translateX(-${contentDataRect.right - grandParentRect.right + 10}px)`;
        contentData.current?.style.setProperty('transform', value);
      } else {
        contentData.current?.style.setProperty('transform', `translateX(-${100}px)`);
      }
    }
  }, [grandParentRect, contentData]);

  return (
    <div className={`${MAIN_CLASS}`}>
      {getIcon('infoFilled', {})}
      <div className={`${LABEL_PREVIEW_CONTENT_DETAILS}`} ref={contentData}>
        <div className={`${LABEL_PREVIEW_CONTENT_DATA}`}>
          <LabelPreviewCotentTitle title={title} />
          <LabelPreviewContentSections>
            <LabelPreviewContentSection>
              <LabelPreviewContentSectionHeader title="Created Date" icon={<CalendarIcon />} />
              <LabelPreviewContentCreatedDate createdDate={createdDate} />
            </LabelPreviewContentSection>
            {labels && (
              <LabelPreviewContentSection>
                <LabelPreviewContentSectionHeader title="Labels " icon={getIcon('addTag', {}) ?? <></>} />
                <LabelPreviewContentLabels labels={labels} />
              </LabelPreviewContentSection>
            )}
            <LabelPreviewContentSection>
              <LabelPreviewContentSectionHeader title="Content ID " icon={<ContentWithLabelIcon />} />
              <div className={`${LABEL_PREVIEW_CONTENT_SECTION}__content-identifier`}>{contentId}</div>
            </LabelPreviewContentSection>
          </LabelPreviewContentSections>
        </div>
      </div>
    </div>
  );
};

export default LabelPreviewContent;
