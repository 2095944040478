import React, { useState, useCallback, useLayoutEffect, useRef, useMemo } from 'react';
// TODO: react-iframe only supports typescript ^3.9.10 right now.
import Iframe from 'react-iframe';
import Session from 'services/session';
import { fetchContentInstance } from 'store/content/content.thunk';
import { getTenantId } from 'helpers/templated-experience.helper';
import { LoadingIcon } from 'components/Shared/LoadingIcon/LoadingIcon';
import { Content } from 'interface/content/content.interface';
import useFeatureFlag from 'hooks/useFeatureFlag';

import './DynamicContentEditorIframe.scss';
import { useQueryClient } from '@tanstack/react-query';

type TDynamicContentEditorIframeProps = {
  contentInstanceId?: string;
  contentTemplateId?: string;
  contentPrototypeId?: string;
  onCancel: () => void;
  onSuccess: (content: Content) => void;
};

const MAIN_CLASS = 'dynamic-content-iframe-editor';
const CLASSES = {
  IFRAME_WRAPPER: `${MAIN_CLASS}__iframe-wrapper`,
  IFRAME: `${MAIN_CLASS}__iframe`,
  LOADING_WRAPPER: `${MAIN_CLASS}__loading-wrapper`,
};

function DynamicContentEditorIframe({
  contentInstanceId,
  contentTemplateId,
  contentPrototypeId,
  onCancel: handleCancel,
  onSuccess: handleSuccess,
}: TDynamicContentEditorIframeProps) {
  const session = useMemo(() => new Session(getTenantId()), []);

  const { flags } = useFeatureFlag();
  const enableDesignAndCode = !!flags['tx_design_and_code_content'];

  // While testing locally with vue-content-manager
  // use http://localhost:3300 (by default)
  const contentManagerUrl = process.env.VUE_CONTENT_MANAGER_URL;
  const iframeUrl = contentInstanceId
    ? `${contentManagerUrl}/content/edit/${contentInstanceId}`
    : `${contentManagerUrl}/content/create/`;
  const iframeTargetOrigin = useMemo(() => new URL(iframeUrl).origin, [iframeUrl]);
  const [isBusy, setIsBusy] = useState(false);
  const iframeWrapperRef = useRef<HTMLDivElement>(null);
  const [isIframeLoading, setIframeLoading] = useState(true);

  const queryClient = useQueryClient();

  const iframeMessageListener = useCallback(
    async (event: MessageEvent) => {
      const { source, origin, data: { type, changes } = { type: '', changes: [] } } = event;

      const action = event?.data?.action;

      if (action === 'close') {
        handleCancel();
        return;
      }

      if (action === 'success') {
        setIsBusy(true);

        if (changes && changes.find((change: any) => change.entity === 'zone')) {
          queryClient.invalidateQueries(['zoneconfigurations']);
        }

        if (changes && changes.find((change: any) => change.entity === 'content')) {
          const content = await fetchContentInstance(event.data.id, { data: true });
          if (content) handleSuccess(content);
        }

        setIsBusy(false);
        return;
      }

      const iframe = iframeWrapperRef.current?.querySelector('#dynamicContentEditor') as HTMLIFrameElement;
      const iframeWindow = iframe?.contentWindow;
      const env = process.env.REACT_API_ENV;
      const host = process.env.REACT_APP_API_URL;
      const token = await session.getToken();

      if (
        !iframe ||
        !iframeWindow ||
        source !== iframeWindow ||
        origin !== iframeTargetOrigin ||
        type !== 'flb.contenteditor.ready'
      ) {
        return;
      }

      if (!env || !host || !token) {
        // eslint-disable-next-line no-console
        console.error('Missing required iframe inputs');
        return;
      }

      setIframeLoading(false);

      const messagePayload = {
        token,
        host,
        env,
        contentTemplateId,
        contentInstanceId,
        contentPrototypeId,
        enableDesignAndCode,
      };

      iframeWindow.postMessage(messagePayload, iframeTargetOrigin);
    },
    [
      handleCancel,
      handleSuccess,
      contentInstanceId,
      contentTemplateId,
      contentPrototypeId,
      enableDesignAndCode,
      iframeTargetOrigin,
      session,
      queryClient,
    ],
  );

  useLayoutEffect(() => {
    window.addEventListener('message', iframeMessageListener);

    return () => {
      window.removeEventListener('message', iframeMessageListener);
    };
  }, [iframeMessageListener]);

  return (
    <div className={MAIN_CLASS}>
      <div className={CLASSES.IFRAME_WRAPPER} ref={iframeWrapperRef}>
        {(isIframeLoading || isBusy) && (
          <div className={CLASSES.LOADING_WRAPPER}>
            <LoadingIcon />
          </div>
        )}
        <Iframe
          className={CLASSES.IFRAME}
          title={`content-editor-${contentInstanceId || contentTemplateId}`}
          url={iframeUrl}
          width="100%"
          height="100%"
          id="dynamicContentEditor"
          position="relative"
          sandbox={['allow-scripts', 'allow-same-origin', 'allow-forms']}
        />
      </div>
    </div>
  );
}

export default DynamicContentEditorIframe;
