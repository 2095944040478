/* eslint-disable @typescript-eslint/no-explicit-any */

import { FlightButton, FlightDropdown, FlightNav } from '@flybits/design-system';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Router, Switch, matchPath } from 'react-router-dom';
import { capitalize, isEmpty } from 'lodash';

import FlybitsLogo from 'assets/images/flybits-logo.png';
import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker/GoogleAnalyticsTracker';
import Notifications from 'components/Notifications/Notifications';
import Pages from 'pages';
import PrioritizationProvider from 'pages/ZonesV2/PrioritizationContext/PrioritizationProvider';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { ReactComponent as RectangleSeperator } from 'assets/icons/rectangle.svg';
import Session from 'services/session';
import SettingAPI from 'services/api/setting.api';
import SnackBar from 'components/SnackBar/SnackBar';
// TODO: Replace end of support package for theme switching
import ThemeSwitcher from 'react-css-vars';
import UseAsyncState from 'hooks/useAsyncState';
import { history } from 'store/store';
import { isAxiosError } from 'axios';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSelector } from 'react-redux';

// TODO: fix imported components' CSS; but in the meantime we need this at the bottom to prevent some very boosted overrides
import './App.scss';

function App() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { flags } = useFeatureFlag();
  const user = useSelector((state: any) => state.auth.user);
  const session = useMemo(() => new Session(), []);
  const settingsApi = useMemo(() => new SettingAPI(), []);
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 30 * 1000, // 30 seconds,
            cacheTime: 5 * 60 * 1000, // 5 minutes
            retry: (retryCount, error) => {
              // Do not retry if it's HTTP 500 series
              if (
                error instanceof Error &&
                isAxiosError(error) &&
                error.response?.status &&
                error.response.status >= 500 &&
                error.response.status < 600
              ) {
                return false;
              }

              // Maximum number of retries for other errors
              if (retryCount >= 3) {
                return false;
              }

              return true;
            },
          },
        },
      }),
    [],
  );

  const match = matchPath<{ projectId: string }>(history.location.pathname, {
    path: '/project/:projectId',
  });
  const projectId = match?.params?.projectId;

  const [themeData, isThemeDataLoading] = UseAsyncState(
    () => settingsApi.getThemeSettings().then((r) => r.settings),
    [],
  );
  let logoUrl;
  let appName;
  let appNameTextColor: string;
  let navbarBgColor = '#ffffff';

  const handleSignOut = async () => {
    await session.unauthorizeUser();
    window.location.replace(process.env.REACT_APP_SSO_URL || '');
  };

  const devPortalRedirect = () => {
    const projectId = match?.params?.projectId;
    window.open(`${process.env.REACT_APP_DEV_PORTAL_URL}/project/${projectId}`);
  };

  const opendomeRedirect = () => {
    const projectId = match?.params?.projectId;
    // we don't have sister deployments as P/E.
    // there's only 1 live instance, however, we control the deployment using params
    const appApi = process.env.REACT_APP_API_URL || '';
    const url = appApi && new URL(appApi);
    window.open(`https://www.opendome.flybits.dev/#/sso?tenantId=${projectId}&deployment=${url && url.hostname}`);
  };

  const sandboxRedirect = () => {
    const projectId = match?.params?.projectId;
    // we don't have sister deployments as P/E.
    // there's only 1 live instance, however, we control the deployment using params
    const appApi = process.env.REACT_APP_API_URL || '';
    const url = appApi && new URL(appApi);
    window.open(`https://www.sandbox.pns.flybits.dev/#/sso?tenantId=${projectId}&deployment=${url && url.hostname}`);
  };

  if (themeData && !isThemeDataLoading) {
    const _themeData = JSON.parse(themeData?.themeData) || '';
    if (!isEmpty(_themeData.logoUrl)) {
      logoUrl = _themeData.logoUrl;
    }
    if (!isEmpty(_themeData.appName)) {
      appName = _themeData.appName;
    }
    if (!isEmpty(_themeData.appNameTextColor)) {
      appNameTextColor = _themeData.appNameTextColor;
    }
    if (!isEmpty(_themeData.navbarBgColor)) {
      navbarBgColor = _themeData.navbarBgColor;
    }
  }

  const [theme, setTheme] = useState({
    navbarBgColor: '',
    appNameTextColor: '',
  });
  const [project, setProject] = useState<Record<string, never>>({});

  function getSidebarRoutes() {
    const projectId = history.location.pathname.split('/')?.[2];
    if (!projectId) {
      return [];
    }

    const routes = [
      {
        label: 'Experiences',
        icon: 'workflow',
        location: `/project/${projectId}`,
        key: 'sidebar-nav-experiences',
      },
      {
        label: 'Locations',
        icon: 'locationMap',
        location: `/project/${projectId}/location-management`,
        key: 'sidebar-nav-location-management',
      },
      {
        label: 'Analytics',
        icon: 'report',
        location: `/project/${projectId}/analytics`,
        key: 'sidebar-nav-analytics',
      },
      {
        label: 'Content Manager',
        icon: 'gallery',
        location: `/project/${projectId}/content-manager`,
        key: 'sidebar-nav-content-manager',
      },
    ];
    if (flags && flags['tx_analytics_v2']) {
      routes.push({
        label: 'Analytics (V2)',
        icon: 'report',
        location: `/project/${projectId}/analytics/v2`,
        key: 'sidebar-nav-analytics-v2',
      });
    }
    // Only shows for owner/grantor
    if (user && user.level >= 20) {
      routes.splice(1, 0, {
        label: 'Audit History',
        icon: 'customerInsight',
        location: `/project/${projectId}/settings/audit-history`,
        key: 'sidebar-nav-audit-history',
      });
    }

    if (flags && flags['tx_zones_modules_v2']) {
      routes.push({
        label: 'Zones and Modules',
        icon: 'collection',
        location: `/project/${projectId}/zones/v2`,
        key: 'sidebar-nav-zones-v2',
      });
    }

    if (flags && flags['tx_merchant_portal']) {
      routes.push({
        label: 'Merchant Portal',
        icon: 'merchant',
        location: `/project/${projectId}/merchants`,
        key: 'sidebar-nav-merchant-portal',
      });
    }

    return routes;
  }

  useEffect(() => {
    if (!projectId) return;
    settingsApi.getProjectSettings(projectId).then((project) => {
      setProject(project);
    });
    setTheme({
      navbarBgColor: navbarBgColor,
      appNameTextColor: appNameTextColor,
    });
    //eslint-disable-next-line
  }, [isThemeDataLoading, projectId]);

  return (
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <ThemeSwitcher theme={theme}>
          <GoogleAnalyticsTracker />
          <div className="app">
            <FlightNav
              user={user}
              project={{
                id: 'id',
                name: `${appName || process.env.REACT_APP_NAME}`,
                environment: `${project.Name ? project?.Name + ' | ' : ''}${capitalize(process.env.REACT_APP_ENV)}`,
                image: <img alt="logo" src={logoUrl || FlybitsLogo} />,
              }}
              hasSidebar={true}
              collapsed={true}
              routes={getSidebarRoutes()}
              isActiveRouteExactMatch={true}
              actionables={
                <>
                  <FlightDropdown
                    className="flight-nav-apps"
                    trigger={
                      <FlightButton
                        iconLeft="circleApps"
                        onClick={() => undefined}
                        isPropagateUpperActions
                        theme="minor"
                        label=""
                        ariaLabel=" "
                      />
                    }
                  >
                    <>
                      <FlightButton
                        className="redirect-project-settings"
                        onClick={devPortalRedirect}
                        iconRight="openInNew"
                        label="Project Settings"
                        size="small"
                        theme="minor"
                      />
                      {flags['tx_opendome'] && (
                        <FlightButton
                          className="redirect-opendome"
                          onClick={opendomeRedirect}
                          iconRight="openInNew"
                          label="Opendome"
                          size="small"
                          theme="minor"
                        />
                      )}
                      {flags['tx_sandbox'] && (
                        <FlightButton
                          className="redirect-sandbox"
                          onClick={sandboxRedirect}
                          iconRight="openInNew"
                          label="Solutions Sandbox"
                          size="small"
                          theme="minor"
                        />
                      )}
                    </>
                  </FlightDropdown>
                  {flags['tx_ao_notifications'] && (
                    <div>
                      <Notifications />
                      <RectangleSeperator />
                    </div>
                  )}
                </>
              }
              handleSignOut={handleSignOut}
            >
              <div className="app__main">
                {/* TODO: Use ErrorBoundary Component to render fallback component on error
                  link: https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/
              */}
                <Switch>
                  {/* /redirect route is the initial entry point to this application from other external websites */}
                  <Route exact path="/redirect" component={Pages.RedirectIndex} />
                  <Route exact path="/developer-hub" component={Pages.DeveloperHubIndex} />
                  <Route exact path="/no-access" component={Pages.NoAccess} />

                  <PrivateRoute exact path="/" component={Pages.HomeIndex} />
                  <PrivateRoute exact path="/project/:pid" component={Pages.TemplatedExperienceIndex} />
                  <PrivateRoute exact path="/project/:pid/analytics" component={Pages.AnalyticsOverview} />
                  <PrivateRoute
                    key="analyticsv2"
                    exact
                    path="/project/:pid/analytics/v2"
                    component={Pages.AnalyticsV2}
                    requiredFlag="tx_analytics_v2"
                  />
                  <PrivateRoute
                    key="experience-add-chart"
                    exact
                    path="/project/:pid/analytics/v2/experiences/create"
                    component={Pages.CreateOrEditChart}
                    requiredFlag="tx_analytics_v2"
                  />
                  <PrivateRoute
                    key="experience-edit-chart"
                    exact
                    path="/project/:pid/analytics/v2/experiences/:id"
                    component={Pages.CreateOrEditChart}
                    requiredFlag="tx_analytics_v2"
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/analytics/module/create"
                    component={Pages.CustomModuleAnalytics}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/analytics/module/:moduleID/edit"
                    component={Pages.CustomModuleAnalytics}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/experiences/templates/library"
                    component={Pages.TemplatesLibrary}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/experiences/templates/library/:cid"
                    component={Pages.TemplatesLibrary}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/experiences/templates/library/:cid/:sid"
                    component={Pages.TemplatesLibrary}
                  />
                  <PrivateRoute
                    exact
                    path="/project/:pid/theme"
                    component={Pages.ThemeHub}
                    requiredFlag="tx_theme_settings"
                  />
                  {/* Experience Canvas Overview */}
                  <PrivateRoute
                    key="experience-overview"
                    exact
                    path="/project/:pid/experiences/:id/overview"
                    component={Pages.ExperienceOverview}
                  />
                  {/* Maker Checker */}
                  <PrivateRoute
                    key="experience-approval-request"
                    exact
                    path="/project/:pid/experiences/:id/approval"
                    component={Pages.ApprovalRequest}
                    requiredFlag="tx_maker_checker"
                  />
                  {/* Touchpoint Editor */}
                  <PrivateRoute
                    key="experience-create-edit"
                    exact
                    path="/project/:pid/experiences/:id/touchpoint/:stepidx"
                    component={Pages.TouchpointEditor}
                  />
                  {/* Audit History */}
                  <PrivateRoute
                    key="audit-history"
                    exact
                    path="/project/:pid/settings/audit-history"
                    component={Pages.AuditHistory}
                  />
                  {/* Location Management */}
                  <PrivateRoute
                    key="location-management"
                    exact
                    path="/project/:pid/location-management"
                    component={Pages.LocationManagement}
                  />
                  {/* Dynamic Content Manager */}
                  <PrivateRoute
                    key="content-manager"
                    exact
                    path="/project/:pid/content-manager"
                    component={Pages.DynamicContentManager}
                  />
                  {/* Zones */}
                  <PrivateRoute key="zones" exact path="/project/:pid/zones" component={Pages.Zones} />
                  <Route path="/project/:pid/zones/v2" component={Pages.RedirectIndex}>
                    <PrioritizationProvider>
                      <Switch>
                        <PrivateRoute
                          key="zones-onboarding"
                          exact
                          path="/project/:pid/zones/v2/onboarding"
                          component={Pages.ZonesOnboarding}
                        />
                        <PrivateRoute key="zone" exact path="/project/:pid/zones/v2/:zoneId" component={Pages.Zone} />
                        <PrivateRoute key="zonesv2" exact path="/project/:pid/zones/v2" component={Pages.ZonesV2} />
                      </Switch>
                    </PrioritizationProvider>
                  </Route>
                  {/* Flow Builder */}
                  <PrivateRoute
                    key="flow-builder"
                    exact
                    path="/project/:pid/experiences/:id/flow-builder"
                    component={Pages.FlowBuilder}
                  />
                  {/* Merchant Portal*/}
                  <Route path="/project/:pid/merchants" component={Pages.RedirectIndex}>
                    <PrivateRoute
                      key="merchant-portal"
                      exact
                      path="/project/:pid/merchants"
                      component={Pages.MerchantPortal}
                      requiredFlag="tx_merchant_portal"
                    />
                    <PrivateRoute
                      key="merchant-portal-edit-merchant"
                      exact
                      path="/project/:pid/merchants/:merchantid/edit-merchant"
                      component={Pages.EditMerchant}
                      requiredFlag="tx_merchant_portal"
                    />
                    <PrivateRoute
                      key="merchant-portal-create-offer"
                      exact
                      path="/project/:pid/merchants/:merchantid/create-offer"
                      component={Pages.CreateMerchantOffer}
                      requiredFlag="tx_merchant_portal"
                    />
                    <PrivateRoute
                      key="merchant-portal-edit-offer"
                      exact
                      path="/project/:pid/merchants/:merchantid/offers/:offerid/edit-offer"
                      component={Pages.EditMerchantOffer}
                      requiredFlag="tx_merchant_portal"
                    />
                  </Route>
                  <PrivateRoute
                    key="merchant-portal-register-merchant"
                    exact
                    path="/project/:pid/register-merchant"
                    component={Pages.RegisterMerchant}
                  />
                </Switch>
                <SnackBar />
              </div>
            </FlightNav>
          </div>
        </ThemeSwitcher>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
