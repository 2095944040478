import React, { useCallback, useState } from 'react';
import ConfirmationModal from 'components/Shared/ConfirmationModal/ConfirmationModal';
import { ConfirmationDialogProps } from 'components/Shared/shared.types';

// Use this hook to render a confirmation modal for functions like save, discard, delete, and download
// e.g., currently this is used in `Delete Zone`, `Delete Module`, `Publish and Discard ZM`, `Delete Merchant`, `Download Engagement Tables`
const useConfirmModal = (props: ConfirmationDialogProps) => {
  const {
    title,
    description,
    icon,
    theme,
    primaryAction,
    secondaryAction,
    confirmationInput,
    className,
    isLoading,
    actionStyle,
    warningMessage,
    infoBanner,
  } = props;
  const [promise, setPromise] = useState<any>(null);
  const showConfirmModal = useCallback(() => new Promise((resolve) => setPromise({ resolve })), []);

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = useCallback(() => {
    promise?.resolve(true);
    primaryAction.onClickHandler?.();
    handleClose();
  }, [promise, primaryAction]);

  const handleCancel = useCallback(() => {
    promise?.resolve(false);
    secondaryAction.onClickHandler?.();
    handleClose();
  }, [promise, secondaryAction]);

  const ConfirmModal = useCallback(() => {
    return (
      <ConfirmationModal
        isOpen={promise !== null}
        closeModal={handleCancel}
        theme={theme}
        icon={icon}
        title={title}
        description={description}
        primaryAction={{
          value: primaryAction.value ?? 'Continue',
          onClickHandler: handleConfirm,
        }}
        secondaryAction={{ value: secondaryAction.value ?? 'Cancel', onClickHandler: handleCancel }}
        confirmationInput={confirmationInput}
        className={className}
        isLoading={isLoading}
        actionStyle={actionStyle}
        warningMessage={warningMessage}
        infoBanner={infoBanner}
      />
    );
  }, [
    icon,
    theme,
    title,
    description,
    primaryAction.value,
    secondaryAction.value,
    confirmationInput,
    promise,
    className,
    isLoading,
    actionStyle,
    warningMessage,
    infoBanner,
    handleCancel,
    handleConfirm,
  ]);

  return [ConfirmModal, showConfirmModal] as const;
};

export default useConfirmModal;
