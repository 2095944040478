import './ChartTitleInput.scss';

import React, { useEffect, useState } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import { ChartConfigState } from 'pages/AnalyticsV2/types';

const MAIN_CLASS = 'chart-title-selector';
interface ChartTitleInputProps {
  chartConfig: ChartConfigState;
  errorMessage?: string;
  onChange: (title: string) => void;
  onValidation: (isValid: boolean) => void;
}

export default function ChartTitleInput({ chartConfig, errorMessage, onChange, onValidation }: ChartTitleInputProps) {
  const { title } = chartConfig;
  const [validationError, setValidationError] = useState<string | undefined>(undefined);

  useEffect(() => {
    let error: string | undefined = undefined;
    let isValid = true;

    if (title?.trim() === '') {
      isValid = false;
      error = 'Title cannot be empty';
    } else if (title?.trim() && title.trim().length >= 35) {
      isValid = false;
      error = 'Title must be less than 35 characters';
    } else if (!/^[a-zA-Z0-9\s-]*$/.test(title?.trim() || '')) {
      isValid = false;
      error = 'Title can only contain alphanumeric characters, space and hyphen (-)';
    }

    setValidationError(error);
    onValidation(isValid);
  }, [title, onValidation]);

  return (
    <section className={MAIN_CLASS}>
      <FlightTextInput
        value={title || ''}
        width={'100%'}
        placeholderText={'Untitled'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newTitle = e.target.value || '';
          onChange(newTitle.trim());
        }}
        hasError={!!validationError || !!errorMessage}
        errorMessage={validationError || errorMessage}
        trailingIconTooltipDescription={validationError || errorMessage}
        label={''}
        ariaLabel={'Title your chart'}
      />
    </section>
  );
}
