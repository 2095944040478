import './OfferReview.scss';
import React from 'react';
import { FlightButton } from '@flybits/design-system';
import { OfferAllFormValues } from 'pages/MerchantPortal/merchant-portal.types';

const OFFER_REVIEW = 'offer-review';
const OFFER_REVIEW_CLASSES = {
  HEADER: `${OFFER_REVIEW}__header`,
  HEADER_NUMBER: `${OFFER_REVIEW}__header__number`,
  HEADER_TITLE: `${OFFER_REVIEW}__header__title`,
  SECTIONS: `${OFFER_REVIEW}__sections`,
  FOOTER: `${OFFER_REVIEW}__footer`,
};

const SECTION = 'offer-review-section';
const SECTION_CLASSES = {
  TITLE: `${SECTION}__title`,
  FIELDS: `${SECTION}__fields`,
  FIELD: `${SECTION}__fields__field`,
  FIELD_KEY: `${SECTION}__fields__field__key`,
  FIELD_VALUE: `${SECTION}__fields__field__value`,
  FIELD_VALUE_IMAGE: `${SECTION}__fields__field__value--image`,
};

const OFFER_FIELDS_INFORMATION = {
  about: {
    merchantName: 'Merchant Name',
    type: 'Offer type',
    redemptionLimit: 'Redemption limit',
    percentage: 'Cash back percentage',
    spendThreshold: 'Spend threshold',
    earnCap: 'Earn cap',
    additionalNote: 'Offer note',
  },
  details: { title: 'Offer title', description: 'Description' },
};

const OFFER_SCHEDULE_FIELDS = {
  schedule: { startDate: 'Desired start date', endDate: 'Expected end date' },
  budget: { targetBudget: 'Target Budget', budgetNote: 'Budget note' },
};

interface SectionFieldProps extends React.ComponentProps<'section'> {
  name: string;
  fields: { [key: string]: string };
  values: { [key: string]: string | JSX.Element | null };
}
const formatDateFromEpoch = (date: number) => {
  return new Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
  }).format(date * 1000);
};
const Section: React.FC<SectionFieldProps> = ({ name, fields, values }) => (
  <section className={SECTION}>
    <h4 className={SECTION_CLASSES.TITLE}>{name}</h4>
    <div className={SECTION_CLASSES.FIELDS}>
      {Object.keys(fields).map((key) => (
        <div className={SECTION_CLASSES.FIELD} key={key}>
          <div className={SECTION_CLASSES.FIELD_KEY}>{fields[key as keyof typeof fields]}</div>
          <div className={`${SECTION_CLASSES.FIELD_VALUE} ${key === 'logo' ? SECTION_CLASSES.FIELD_VALUE_IMAGE : ''}`}>
            {values[key as keyof typeof values]}
          </div>
        </div>
      ))}
    </div>
  </section>
);

interface OfferReviewProps extends React.ComponentProps<'div'> {
  data: OfferAllFormValues;
  onPrevious: () => void;
  onSubmit: () => void;
}
const OfferReview: React.FC<OfferReviewProps> = ({ onPrevious, onSubmit, data }) => {
  const { offer, schedule } = data;
  return (
    <div className={OFFER_REVIEW}>
      <header className={OFFER_REVIEW_CLASSES.HEADER}>
        <h2 className={OFFER_REVIEW_CLASSES.HEADER_TITLE}>Offer information</h2>
      </header>
      <div className={OFFER_REVIEW_CLASSES.SECTIONS}>
        <Section
          name="About the offer"
          fields={OFFER_FIELDS_INFORMATION.about}
          values={{
            merchantName: offer.merchantName || '',
            type: offer.type,
            redemptionLimit: offer.redemptionLimit === 'just-once' ? 'Just once' : 'Multiple use',
            percentage: `${offer.percentage}%`,
            spendThreshold: `${offer.spendThreshold}`,
            earnCap: `${offer.earnCap}`,
            additionalNote: offer.additionalNote || 'N/A',
          }}
        />
        <Section
          name="Offer details"
          fields={OFFER_FIELDS_INFORMATION.details}
          values={{
            title: offer.title,
            description: offer.description,
          }}
        />
      </div>
      <hr />
      <header className={OFFER_REVIEW_CLASSES.HEADER}>
        <h2 className={OFFER_REVIEW_CLASSES.HEADER_TITLE}>Offer Schedule and Budget</h2>
      </header>
      <div className={OFFER_REVIEW_CLASSES.SECTIONS}>
        <Section
          name="Expected launch schedule"
          fields={OFFER_SCHEDULE_FIELDS.schedule}
          values={{
            startDate: formatDateFromEpoch(schedule.startDate),
            endDate: formatDateFromEpoch(schedule.endDate),
          }}
        />
        <Section
          name="Offer Budget"
          fields={OFFER_SCHEDULE_FIELDS.budget}
          values={{
            targetBudget: schedule.targetBudget + '',
            budgetNote: schedule.budgetNote || 'N/A',
          }}
        />
      </div>
      <footer className={OFFER_REVIEW_CLASSES.FOOTER}>
        <FlightButton label="Back to editing" theme="secondary" onClick={onPrevious} />
        <FlightButton label="Submit" onClick={onSubmit} />
      </footer>
    </div>
  );
};

export default OfferReview;
