import './JourneyAnalyticsCollapsible.scss';
import React, { useEffect, useRef, useState } from 'react';
import { FlightButton, FlightTooltip, getIcon } from '@flybits/design-system';
import { JourneyAnalyticsCollapsibleModes } from 'pages/AnalyticsV2/types';

const JOURNEY_ANALYTICS_COLLAPSIBLE = 'journey-analytics-collapsible';
const COLLAPSIBLE_CLASSES = {
  HEADER: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__header`,
  HEADER_COMPLETED: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__header-completed`,
  HEADER_ERROR: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__header-error`,
  CONTENT: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__content`,
  BODY: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__content__body`,
  FOOTER: `${JOURNEY_ANALYTICS_COLLAPSIBLE}__content__footer`,
};
const HEADER = COLLAPSIBLE_CLASSES.HEADER;
const HEADER_CLASSES = {
  FADE_OUT: `${HEADER}--fade-out`,
  FADE_IN: `${HEADER}--fade-in`,
  INFO: `${HEADER}__info`,
  NUMBER: `${HEADER}__info__number`,
  TEXT: `${HEADER}__info__text`,
  TITLE: `${HEADER}__info__text__title`,
  TITLE_ICON: `${HEADER}__info__text__title__icon`,
  TITLE_REQ: `${HEADER}__info__text__title__required`,
  DESC: `${HEADER}__info__text__desc`,
  COLLAPSIBLE_TRIGGER: `${HEADER}__collapsible-trigger`,
  COLLAPSIBLE_TRIGGER_ROTATE_DOWN: `${HEADER}__collapsible-trigger--rotate-down`,
  COLLAPSIBLE_TRIGGER_ROTATE_UP: `${HEADER}__collapsible-trigger--rotate-up`,
};
const HEADER_COMPLETED = COLLAPSIBLE_CLASSES.HEADER_COMPLETED;
const HEADER_COMPLETED_CLASSES = {
  FADE_OUT: `${HEADER_COMPLETED}--fade-out`,
  FADE_IN: `${HEADER_COMPLETED}--fade-in`,
  INFO: `${HEADER_COMPLETED}__info`,
  STATUS_CHECK: `${HEADER_COMPLETED}__info__status-check`,
  TEXT: `${HEADER_COMPLETED}__info__text`,
  TITLE: `${HEADER_COMPLETED}__info__text__title`,
  LABEL: `${HEADER_COMPLETED}__info__text__label`,
  EDIT: `${HEADER_COMPLETED}__edit`,
};

const HEADER_ERROR_CLASS = COLLAPSIBLE_CLASSES.HEADER_ERROR;
const HEADER_ERROR_CLASSES = {
  FADE_OUT: `${HEADER_ERROR_CLASS}--fade-out`,
  FADE_IN: `${HEADER_ERROR_CLASS}--fade-in`,
  INFO: `${HEADER_ERROR_CLASS}__info`,
  STATUS_ERROR: `${HEADER_ERROR_CLASS}__info__status-error`,
  TEXT: `${HEADER_ERROR_CLASS}__info__text`,
  TITLE: `${HEADER_ERROR_CLASS}__info__text__title`,
  EDIT: `${HEADER_ERROR_CLASS}__edit`,
};

interface HeaderProps extends React.ComponentProps<'div'> {
  mode: JourneyAnalyticsCollapsibleModes;
  renderMode: JourneyAnalyticsCollapsibleModes;
  step: number;
  title: string;
  titleIcon?: JSX.Element;
  description?: string;
  isCollapsed: boolean;
  onClickCollapsibleTrigger: () => void;
}

const Header: React.FC<HeaderProps> = ({
  mode,
  renderMode,
  step,
  title,
  titleIcon,
  description,
  isCollapsed,
  onClickCollapsibleTrigger,
}) => (
  <div
    className={`${COLLAPSIBLE_CLASSES.HEADER} ${
      mode !== renderMode ? HEADER_CLASSES.FADE_OUT : HEADER_CLASSES.FADE_IN
    }`}
  >
    <div className={HEADER_CLASSES.INFO}>
      <div className={HEADER_CLASSES.NUMBER}>{step}</div>
      <div className={HEADER_CLASSES.TEXT}>
        <div className={HEADER_CLASSES.TITLE}>
          {titleIcon && <div className={HEADER_CLASSES.TITLE_ICON}>{titleIcon}</div>}
          {title}
          <span className={HEADER_CLASSES.TITLE_REQ}>*</span>
        </div>
        {description && <div className={HEADER_CLASSES.DESC}>{description}</div>}
      </div>
    </div>
    <button
      className={`${HEADER_CLASSES.COLLAPSIBLE_TRIGGER} ${
        !isCollapsed ? HEADER_CLASSES.COLLAPSIBLE_TRIGGER_ROTATE_DOWN : HEADER_CLASSES.COLLAPSIBLE_TRIGGER_ROTATE_UP
      }`}
      onClick={onClickCollapsibleTrigger}
    >
      {getIcon('baselineKeyboardArrowDown', {})}
    </button>
  </div>
);

interface HeaderCompletedProps extends React.ComponentProps<'div'> {
  mode: JourneyAnalyticsCollapsibleModes;
  renderMode: JourneyAnalyticsCollapsibleModes;
  title: string;
  label: React.ReactNode;
  onClickEdit: () => void;
}

const HeaderCompleted: React.FC<HeaderCompletedProps> = ({ mode, renderMode, title, label, onClickEdit }) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [isLabelOverflowing, setLabelOverflowing] = useState(false);

  useEffect(() => {
    if (labelRef.current) {
      const isOverflow = labelRef.current.scrollWidth > labelRef.current.clientWidth;
      setLabelOverflowing(isOverflow);
    }
  }, [label]);

  return (
    <div
      className={`${COLLAPSIBLE_CLASSES.HEADER_COMPLETED} ${
        mode !== renderMode ? HEADER_COMPLETED_CLASSES.FADE_OUT : HEADER_CLASSES.FADE_IN
      }`}
    >
      <div className={HEADER_COMPLETED_CLASSES.INFO}>
        <div className={HEADER_COMPLETED_CLASSES.STATUS_CHECK}>{getIcon('checkCircle', {})}</div>
        <div className={HEADER_COMPLETED_CLASSES.TEXT}>
          <div className={HEADER_COMPLETED_CLASSES.TITLE}>{title}</div>
          <FlightTooltip isEnabled={isLabelOverflowing} description={label} direction="top">
            <div className={HEADER_COMPLETED_CLASSES.LABEL} ref={labelRef}>
              {label}
            </div>
          </FlightTooltip>
        </div>
      </div>
      <button className={HEADER_COMPLETED_CLASSES.EDIT} onClick={onClickEdit}>
        {getIcon('editFullOutline', {})}
      </button>
    </div>
  );
};

interface HeaderErrorProps extends React.ComponentProps<'div'> {
  mode: JourneyAnalyticsCollapsibleModes;
  renderMode: JourneyAnalyticsCollapsibleModes;
  title: string;
  onClickEdit: () => void;
}

const HeaderError: React.FC<HeaderErrorProps> = ({ mode, renderMode, title, onClickEdit }) => {
  return (
    <div
      className={`${HEADER_ERROR_CLASS} ${
        mode !== renderMode ? HEADER_ERROR_CLASSES.FADE_OUT : HEADER_ERROR_CLASSES.FADE_IN
      }`}
    >
      <div className={HEADER_ERROR_CLASSES.INFO}>
        <div className={HEADER_ERROR_CLASSES.STATUS_ERROR}>{getIcon('error', {})}</div>
        <div className={HEADER_ERROR_CLASSES.TEXT}>
          <div className={HEADER_ERROR_CLASSES.TITLE}>{title}</div>
        </div>
      </div>
      <button className={HEADER_ERROR_CLASSES.EDIT} onClick={onClickEdit}>
        {getIcon('editFullOutline', {})}
      </button>
    </div>
  );
};

interface JourneyAnalyticsCollapsibleProps extends React.ComponentProps<'div'> {
  mode: JourneyAnalyticsCollapsibleModes;
  step: number;
  title: string;
  titleIcon?: JSX.Element;
  description?: string;
  label: JSX.Element;
  actionLabel: string;
  actionHandler: () => void;
  secondaryActionLabel?: string;
  secondaryActionHandler?: () => void;
  isValid: boolean;
}

const JourneyAnalyticsCollapsible: React.FC<JourneyAnalyticsCollapsibleProps> = ({
  mode,
  step,
  title,
  titleIcon,
  description,
  label,
  actionLabel,
  actionHandler,
  secondaryActionLabel = 'Cancel',
  secondaryActionHandler,
  children,
  isValid,
}) => {
  const [isCollapsed, setCollapsed] = useState(step > 1);
  const [renderMode, setRenderMode] = useState(mode);
  const contentRef = useRef<HTMLDivElement>(null);

  // Animations between modes
  useEffect(() => {
    const timeout = setTimeout(() => setRenderMode(mode), 300 /* css transition duration */);

    return () => clearTimeout(timeout);
  }, [mode]);

  // Find the child content height for smooth sliding animations
  const contentHeight = contentRef.current ? contentRef.current.scrollHeight : 0;

  return (
    <div className={JOURNEY_ANALYTICS_COLLAPSIBLE}>
      {renderMode !== JourneyAnalyticsCollapsibleModes.COMPLETED ? (
        <Header
          mode={mode}
          renderMode={renderMode}
          step={step}
          title={title}
          titleIcon={titleIcon}
          description={description}
          isCollapsed={isCollapsed}
          onClickCollapsibleTrigger={() => setCollapsed((isCollapsed) => !isCollapsed)}
        />
      ) : isValid ? (
        <HeaderCompleted
          mode={mode}
          renderMode={renderMode}
          title={title}
          label={label}
          onClickEdit={() => setCollapsed((isCollapsed) => !isCollapsed)}
        />
      ) : (
        <HeaderError
          mode={mode}
          renderMode={renderMode}
          title={title}
          onClickEdit={() => setCollapsed((isCollapsed) => !isCollapsed)}
        />
      )}
      <div
        className={COLLAPSIBLE_CLASSES.CONTENT}
        style={{
          height: isCollapsed ? '0px' : `${contentHeight}px`,
        }}
        ref={contentRef}
      >
        <div className={COLLAPSIBLE_CLASSES.BODY}>{children}</div>
        <div className={COLLAPSIBLE_CLASSES.FOOTER}>
          <FlightButton
            theme="minor"
            label={secondaryActionLabel}
            onClick={() => {
              setCollapsed(true);
              secondaryActionHandler?.();
            }}
          />
          <FlightButton
            theme="secondary"
            label={actionLabel}
            disabled={!isValid}
            onClick={() => {
              setCollapsed((isCollapsed) => !isCollapsed);
              actionHandler();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default JourneyAnalyticsCollapsible;
