import { LabelFilter } from 'pages/Zones/types';
import React, { createContext } from 'react';

export type TLabelFilterContext = {
  moduleName: string | null;
  pageSize: number | null;
  labelFilters: LabelFilter[] | null;
  selectedLabelFilter: LabelFilter | null;
  setSelectedLabelFilter: React.Dispatch<React.SetStateAction<LabelFilter | null>>;
  limit: number;
  addLabelFilter: (newLabels: LabelFilter[]) => void;
  reOrder: (startIndex: number, endIndex: number) => void;
  remove: (label: string) => void;
};

const LabelFilterContextDefaults: TLabelFilterContext = {
  moduleName: null,
  pageSize: null,
  limit: 5,
  labelFilters: [],
  setSelectedLabelFilter: () => null,
  selectedLabelFilter: null,
  addLabelFilter: () => null,
  reOrder: () => null,
  remove: () => null,
};

export default createContext<TLabelFilterContext>(LabelFilterContextDefaults);
