import { PaginationResponse } from 'interface/shared/api.interface';
import BaseAPI from './base.api';
import { ChartData, ModuleConfig, ModuleConfigPayload, ModuleConfigUpdatePayload } from 'pages/AnalyticsV2/types';

class ExperienceGraphAPI extends BaseAPI {
  private ROUTES = {
    graphs: '/analytics/analytics/moduleconfig',
    graph: '/analytics/analytics/moduleconfig/:graphId',
    graphData: '/analytics/analytics/moduleconfig/:graphId/data',
  };

  getExperienceGraphs(params = {}): Promise<PaginationResponse<ModuleConfig>> {
    return this.GET(this.ROUTES.graphs, params);
  }

  getExperienceGraph(id: string): Promise<ModuleConfig> {
    return this.GET(this.buildURL(this.ROUTES.graph, { graphId: id }));
  }

  getExperienceGraphData(id: string): Promise<ChartData> {
    return this.GET(this.buildURL(this.ROUTES.graphData, { graphId: id }));
  }

  createExperienceGraph(moduleConfig: ModuleConfigPayload): Promise<ModuleConfig> {
    return this.POST(this.ROUTES.graphs, moduleConfig);
  }

  updateExperienceGraph(moduleConfig: ModuleConfigUpdatePayload): Promise<ModuleConfig> {
    return this.PUT(this.buildURL(this.ROUTES.graph, { graphId: moduleConfig.id }), moduleConfig);
  }

  deleteExperienceGraph(id: string) {
    return this.DELETE(this.buildURL(this.ROUTES.graph, { graphId: id }));
  }
}

const experienceGraphService = new ExperienceGraphAPI();

export default experienceGraphService;
