import './PublishChangesBanner.scss';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FlightButton, getIcon } from '@flybits/design-system';
import UnsavedPrompt from '../UnsavedPrompt/UnsavedPrompt';
import PrioritizationContext, {
  initialPublishChangesBannerInfo,
} from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import IconPublishChanges from 'components/Shared/Icons/IconPublishChanges';
import IconDiscardChanges from 'components/Shared/Icons/IconDiscardChanges';
import { useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import { showSnackbar } from 'store/snackbar/snackbar.action';
import { useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { ConfirmationDialogProps } from '../shared.types';
import useConfirmModal from 'hooks/useConfirmModal';

const infoIcon = getIcon('infoFilled', {
  width: 18,
  height: 18,
  fill: '#fff',
});
const errorIcon = getIcon('warning', { width: 18, height: 18, fill: '#fff' });

const discardConfirmDialogProps: ConfirmationDialogProps = {
  icon: <IconDiscardChanges />,
  title: 'Discard Changes?',
  description: 'This will undo all changes that have been made since the last time you published.',
  primaryAction: { value: 'Yes, continue' },
  secondaryAction: { value: 'Cancel' },
};

export default function PublishChangesBanner() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const {
    publishChangesBannerInfo: { title, errorMessage, show, didAnimationEnd },
    setPublishChangesBannerInfo,
    publishZoneConfigs,
    resetZoneConfigs,
    numUnsavedChanges,
    queryKey,
  } = useContext(PrioritizationContext);

  const bannerTitle = title || `You have made changes to Zones and Modules`;

  const [isZoneConfigSaving, setZoneConfigSaving] = useState(false);
  const [bannerAnimationEnd, setBannerAnimationEnd] = useState(false);
  const [bannerTextAnimationEnd, setBannerTextAnimationEnd] = useState(false);
  const [bannerButtonsAnimationEnd, setBannerButtonsAnimationEnd] = useState(false);
  const handlePublishBannerAnimationEnd = () => setBannerAnimationEnd(true);
  const handlePublishBannerTextAnimationEnd = () => setBannerTextAnimationEnd(true);
  const handlePublishBannerButtonsAnimationEnd = () => setBannerButtonsAnimationEnd(true);

  const publishConfirmDialogProps: ConfirmationDialogProps = useMemo(
    () => ({
      icon: <IconPublishChanges />,
      title: 'Publish Changes',
      description:
        'Changes you have made may not be saved. Before leaving this page, please publish your changes first.',
      primaryAction: { value: 'Publish changes' },
      secondaryAction: { value: 'Continue without publishing' },
      isLoading: isZoneConfigSaving,
    }),
    [isZoneConfigSaving],
  );

  const [PublishChangesConfirmModal, showPublishChangesConfirmModal] = useConfirmModal(publishConfirmDialogProps);
  const [DiscardChangesConfirmModal, showDiscardChangesConfirmModal] = useConfirmModal(discardConfirmDialogProps);

  const handleConfirmPublish = async () => {
    try {
      if (await showPublishChangesConfirmModal()) {
        setZoneConfigSaving(true);

        await publishZoneConfigs();
        await queryClient.invalidateQueries({ queryKey: [queryKey] });
        dispatch(
          showSnackbar({
            content: `Changes to zones and modules have been published`,
            type: 'success',
          }),
        );
        setPublishChangesBannerInfo(initialPublishChangesBannerInfo);
      }
    } catch (e: unknown) {
      const errorMessage = isAxiosError(e) ? e.response?.data?.error?.exceptionMessage : undefined;
      dispatch(
        showSnackbar({
          content: `Your zones and modules could not be saved! \n Error: ${errorMessage ?? 'unknown'}`,
          type: 'error',
        }),
      );
    } finally {
      setZoneConfigSaving(false);
    }
  };

  const handleConfirmDiscard = async () => {
    if (await showDiscardChangesConfirmModal()) {
      resetZoneConfigs && resetZoneConfigs();
      setPublishChangesBannerInfo(initialPublishChangesBannerInfo);
    }
  };

  useEffect(() => {
    if (bannerAnimationEnd && bannerTextAnimationEnd && bannerButtonsAnimationEnd) {
      setPublishChangesBannerInfo((bannerInfo) => ({ ...bannerInfo, didAnimationEnd: true }));
    }
  }, [bannerAnimationEnd, bannerTextAnimationEnd, bannerButtonsAnimationEnd, setPublishChangesBannerInfo]);
  return (
    show && (
      <div
        className={`publish-banner ${!!errorMessage ? 'publish-banner--error' : ''}${
          didAnimationEnd ? 'publish-banner--no-animation' : ''
        }`}
        onAnimationEnd={handlePublishBannerAnimationEnd}
      >
        <UnsavedPrompt
          when={numUnsavedChanges > 0}
          unblockPaths={['/zones/v2']}
          dialogProps={{
            secondaryAction: {
              value: 'Discard changes',
              onClickHandler: () => setPublishChangesBannerInfo(initialPublishChangesBannerInfo),
            },
          }}
        />
        <PublishChangesConfirmModal />
        <DiscardChangesConfirmModal />
        <div
          className={`publish-banner__text ${didAnimationEnd ? 'publish-banner__text--no-animation' : ''}`}
          onAnimationEnd={handlePublishBannerTextAnimationEnd}
        >
          <span className="publish-banner__icon" aria-hidden>
            {!!errorMessage ? errorIcon : infoIcon}
          </span>{' '}
          {!!errorMessage ? errorMessage : bannerTitle}
        </div>
        <div
          className={`publish-banner__buttons ${didAnimationEnd ? 'publish-banner__buttons--no-animation' : ''}`}
          onAnimationEnd={handlePublishBannerButtonsAnimationEnd}
        >
          <FlightButton onClick={handleConfirmDiscard} theme={'primary'} label="Discard changes" size="small" />
          <FlightButton
            onClick={handleConfirmPublish}
            theme={'secondary'}
            label="Publish changes"
            size="small"
            disabled={!!errorMessage}
          />
        </div>
      </div>
    )
  );
}
