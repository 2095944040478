import { FlightTable } from '@flybits/design-system';
import { UseQueryResult } from '@tanstack/react-query';
import SlidingSidePanel from 'components/ExperienceCanvas/SlidingSidePanel/SlidingSidePanel';
import { TSlidingSidePanelProps } from 'components/ExperienceCanvas/types';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';
import { Content } from 'interface/content/content.interface';
import PrioritizationContext from 'pages/ZonesV2/PrioritizationContext/PrioritizationContext';
import React, { useContext, useMemo } from 'react';
import { capitalize } from 'lodash';
import { getDuration } from 'helpers/templated-experience.helper';
import './PinnedContentScheduleSlideout.scss';
import { PinnedContent } from 'pages/Zones/types';

type TPinnedContentScheduleSlideoutProps = {
  contentQueriesResults: UseQueryResult<Content, unknown>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  languages: any[];
};
const FullDateFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
  timeStyle: 'short',
};

const tableHeader = [
  {
    name: 'Content Name',
    key: 'name',
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Status',
    key: 'status',
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  },
  {
    name: 'Schedule Time',
    key: 'schedule',
    hideTooltip: true,
    isVisible: true,
    isSortable: false,
  },
];

const MAIN_CLASS = 'pinned-content-schedule-slide-out';
const CLASSES = {
  TABLE: `${MAIN_CLASS}__table`,
  STATUS: `${MAIN_CLASS}__table__status`,
  STATUS_ICON: `${MAIN_CLASS}__table__status__icon`,
  SCHEDULE: `${MAIN_CLASS}__table__schedule`,
  SCHEDULE_TITLE: `${MAIN_CLASS}__table__schedule__title`,
  SCHEDULE_SUBTITLE: `${MAIN_CLASS}__table__schedule__subtitle`,
  EMPTY: `${MAIN_CLASS}__table__empty`,
};

type TContentScheduleProps = {
  startAt: number;
  endAt: number;
};

type TPinnedContentStatus = 'active' | 'scheduled';

const StatusTableCell = ({ startAt, endAt }: TContentScheduleProps) => {
  const nowInSeconds = Math.floor(Date.now() / 1000);

  let pinnedContentStatus: TPinnedContentStatus = 'scheduled';

  if (startAt <= nowInSeconds && (endAt === -1 || nowInSeconds < endAt)) {
    pinnedContentStatus = 'active';
  } else if (nowInSeconds < startAt && (endAt === -1 || nowInSeconds < endAt)) {
    pinnedContentStatus = 'scheduled';
  }

  return (
    <div className={CLASSES.STATUS}>
      <div className={`${CLASSES.STATUS_ICON} ${CLASSES.STATUS_ICON}--${pinnedContentStatus}`}></div>
      <span>{capitalize(pinnedContentStatus)}</span>
    </div>
  );
};

const ScheduleTableCell = ({ startAt, endAt }: TContentScheduleProps) => {
  return (
    <div className={CLASSES.SCHEDULE}>
      <div className={CLASSES.SCHEDULE_TITLE}>
        {`${new Date(startAt * 1000).toLocaleString(undefined, FullDateFormatOptions)} - ${
          endAt === -1 ? 'No End' : new Date(endAt * 1000).toLocaleString(undefined, FullDateFormatOptions)
        }`}
      </div>
      <div className={CLASSES.SCHEDULE_SUBTITLE}>{getDuration(startAt, endAt)}</div>
    </div>
  );
};

const PinnedContentScheduleSlideout: React.FC<TPinnedContentScheduleSlideoutProps> = ({
  contentQueriesResults,
  languages,
}) => {
  const { isPinnedContentScheduleOpen, setPinnedContentScheduleOpen, setSelectedModule, selectedModule } =
    useContext(PrioritizationContext);
  const defaultLang = getDefaultLanguage(languages);
  const slidingSidePanelProps = useMemo<TSlidingSidePanelProps>(
    () => ({
      headerInfo: {
        mainTitle: 'Pin Schedule',
        goBackTitle: ' ',
        goBackActionHandler: () => {
          setPinnedContentScheduleOpen(false);
        },
      },
      footerInfo: {
        secondaryActionText: 'Close',
        secondaryActionHandler: () => {
          setPinnedContentScheduleOpen(false);
        },
      },
      showFooter: true,
      show: isPinnedContentScheduleOpen,
      onSlideOutComplete: () => setSelectedModule({}),
      size: 'large',
      className: MAIN_CLASS,
    }),
    [isPinnedContentScheduleOpen, setPinnedContentScheduleOpen, setSelectedModule],
  );
  const tableData = useMemo(() => {
    const active: PinnedContent[] = [];
    const scheduled: PinnedContent[] = [];

    selectedModule?.pinnedContentIDsByTimeRange?.forEach((cp) => {
      const nowInSeconds = Math.floor(Date.now() / 1000);

      if (nowInSeconds >= cp.startAt && (cp.endAt === -1 || nowInSeconds <= cp.endAt)) {
        active.push(cp);
      } else if (nowInSeconds < cp.startAt && (cp.endAt === -1 || nowInSeconds <= cp.endAt)) {
        scheduled.push(cp);
      }
    });

    const groupedByTimeActiveType: PinnedContent[] = active.concat(
      scheduled.sort((a: PinnedContent, b: PinnedContent) => a.startAt - b.startAt),
    );

    return (
      groupedByTimeActiveType.map((pc) => {
        const contentId = pc.contentID;
        const content = contentQueriesResults.find((c) => c.data?.id === contentId);

        return {
          ...pc,
          key: pc.contentID,
          name: content?.data?.localizations?.[defaultLang].name,
          status: <StatusTableCell startAt={pc.startAt} endAt={pc.endAt} />,
          schedule: <ScheduleTableCell startAt={pc.startAt} endAt={pc.endAt} />,
        };
      }) ?? []
    );
  }, [selectedModule?.pinnedContentIDsByTimeRange, contentQueriesResults, defaultLang]);

  return (
    <SlidingSidePanel {...slidingSidePanelProps}>
      <FlightTable
        className={CLASSES.TABLE}
        tableHeaders={tableHeader}
        tableData={tableData}
        emptyState={<div className={CLASSES.EMPTY}>There are no pinned contents</div>}
        hasPaginationBeforeTable={false}
        hasPaginationAfterTable={false}
      />
    </SlidingSidePanel>
  );
};

export default PinnedContentScheduleSlideout;
