import React, { useState } from 'react';

// Custom imports
import { EVENT_KEYS } from 'types/events';
import { FlightButton, FlightDropdown, FlightTooltip, getIcon } from '@flybits/design-system';
import { JourneyState } from 'store/journey/journey.type';
import { SmartTargetStatusType } from 'interface/smart-targeting/smart-targeting.interface';
import { useAppSelector as useSelector, useThunkDispatch as useDispatch } from 'hooks/reduxHooks';
import ContextDataSlideOut from 'components/ExperienceCanvas/ContextDataSlideOut/ContextDataSlideOut';
import FileUploadDropdown from 'components/ExperienceCanvas/FileUploadDropdown/FileUploadDropdown';
import LabelsSlidingPanel from 'components/ExperienceCanvas/LabelsSlidePanel/LabelsSlidePanel';
import Schedule from 'components/ExperienceCanvas/Schedule/Schedule';
import ScheduleIcon from 'pages/ExperienceCanvas/assets/icons/ScheduleIcon';
import Settings from 'components/ExperienceCanvas/Settings/Settings';
import SettingsIcon from 'pages/ExperienceCanvas/assets/icons/SettingsIcon';
import SmartTargeting from 'components/ExperienceCanvas/SmartTargeting/SmartTargeting';
import SmartTargetingData from '../SmartTargetingData/SmartTargetingData';
import LocalizationSelector from 'components/ExperienceCanvas/LocalizationSelector/LocalizationSelector';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSettings from 'hooks/useSetting';

// Stylings
import './ActionsHeader.scss';

type FeatureHeaderType = {
  journeyInstance: JourneyState;
  showLocalizationSelector?: boolean;
};

const headerIconFill = '#2371f1';

const ActionsHeader: React.FC<FeatureHeaderType> = ({ journeyInstance, showLocalizationSelector }) => {
  const { flags } = useFeatureFlag();
  const { languages, previewTextLanguage } = useSettings();
  const dispatch = useDispatch();
  const [isLabelsPanelOpen, setIsLabelsPanelOpen] = useState(false);
  const [isLanguageDDOpen, setLanguageDDOpen] = useState(false);
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useState(false);
  const [isSchedulePanelOpen, setIsSchedulePanelOpen] = useState(false);
  const [isSmartTargetingPanelOpen, setIsSmartTargetingPanelOpen] = useState(false);
  const [isContextDataSlideOutOpen, setIsContextDataSlideOutOpen] = useState(false);
  const isListBasedTemplate = useSelector(
    (state) =>
      state.te.journey.templateName?.toLowerCase().includes('list-based') ||
      state.te.journey.steps.reduce((prev, curr) => prev || curr.type === 'list-based', false) ||
      false,
  );
  const isAOEligible =
    !!journeyInstance.audienceOptimizationEligible ||
    !!journeyInstance.audienceOptimization?.audienceOptimizationEligible;
  const [smartTargetingStatus, setSmartTargetingStatus] = useState<SmartTargetStatusType>('');

  const toggleLanguageDD = () => {
    setLanguageDDOpen((prevState) => !prevState);
  };

  const toggleIsLabelsPanelOpen = () => {
    setIsLabelsPanelOpen((prevState) => !prevState);
  };

  const toggleIsSettingsPanelOpen = () => {
    setIsSettingsPanelOpen((prevState) => !prevState);
  };

  const toggleIsSchedulePanelOpen = () => {
    setIsSchedulePanelOpen((prevState) => !prevState);
  };
  const toggleIsSmartTargetingPanelOpen = () => {
    setIsSmartTargetingPanelOpen((prevState) => !prevState);
  };

  const toggleIsContextDataSlideOutOpen = () => {
    setIsContextDataSlideOutOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="overview-container__header">
        <div className="overview-container__header__left-section">
          {/* SCHEDULER OPTION */}
          <div
            className="overview-header-item-container"
            aria-label="open experience schedule editor"
            tabIndex={0}
            role="button"
            onClick={() => {
              toggleIsSchedulePanelOpen();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === EVENT_KEYS.ENTER) {
                toggleIsSchedulePanelOpen();
              }
            }}
          >
            <ScheduleIcon fill={headerIconFill} />
          </div>
          {/* <div className="overview-header-item-container overview-header-item-container--separator"></div> */}
          {/* LABELS OPTION */}
          <div
            className="overview-header-item-container"
            aria-label="open experience labels"
            tabIndex={0}
            role="button"
            onClick={() => {
              toggleIsLabelsPanelOpen();
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === EVENT_KEYS.ENTER) {
                toggleIsLabelsPanelOpen();
              }
            }}
          >
            {getIcon('tag', { fill: headerIconFill })}
          </div>
          {/* SETTINGS OPTION */}
          {flags['tx_enable_experience_settings_in_actions_header'] && (
            <div
              className="overview-header-item-container"
              aria-label="open experience settings"
              tabIndex={0}
              role="button"
              onClick={() => {
                toggleIsSettingsPanelOpen();
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  toggleIsSettingsPanelOpen();
                }
              }}
            >
              <SettingsIcon fill={headerIconFill} />
            </div>
          )}
          {/* PREVIEW LANGUAGE OPTION */}
          {!flags['tx_localization_v2'] && languages.length > 1 && (
            <FlightTooltip isEnabled={!isLanguageDDOpen} description={'Change preview language'}>
              <FlightDropdown
                isActive={isLanguageDDOpen}
                handleTriggerClick={toggleLanguageDD}
                handleClickOutside={toggleLanguageDD}
                trigger={
                  <div className="overview-header-item-container" aria-label="open preview language dropdown">
                    {getIcon('language', { fill: headerIconFill, width: '24px', height: '24px' })}
                  </div>
                }
              >
                {languages.map((lang) => (
                  <FlightButton
                    key={`journey-preview-lang-${lang.id}`}
                    className={`overview-header-item-dd-option ${
                      lang.id === previewTextLanguage ? 'overview-header-item-dd-option--selected' : ''
                    }`}
                    onClick={() => {
                      setLanguageDDOpen(false);
                      dispatch({
                        type: 'SET_PREVIEW_LANGUAGE',
                        payload: { previewTextDirection: lang.direction || 'ltr', previewTextLanguage: lang.id },
                      });
                    }}
                    label={lang.id}
                    size="small"
                    theme="minor"
                  />
                ))}
              </FlightDropdown>
            </FlightTooltip>
          )}
        </div>

        {/* Right Container */}
        <div className="overview-container__header__right-section secondary__header">
          {/* UPLOAD CONTEXT DATA */}
          {isListBasedTemplate && (
            <FileUploadDropdown title="Upload Context Data" onViewAllClick={toggleIsContextDataSlideOutOpen} />
          )}

          {/* SMART TARGETTING */}
          {isAOEligible && (
            <SmartTargetingData
              journeyInstance={journeyInstance}
              toggleIsSmartTargetingPanelOpen={toggleIsSmartTargetingPanelOpen}
              status={smartTargetingStatus}
            />
          )}

          {/* LANGUAGE SELECTOR V2 */}
          {flags['tx_localization_v2'] && languages.length > 1 && showLocalizationSelector && (
            <LocalizationSelector
              label="Preview language"
              selectedLanguage={previewTextLanguage}
              tooltip="Changing languages lets you view push notifications and/or content in the selected language"
            />
          )}
        </div>
      </div>
      <LabelsSlidingPanel isOpen={isLabelsPanelOpen} onClickClose={toggleIsLabelsPanelOpen} />
      <Settings isOpen={isSettingsPanelOpen} onClickClose={toggleIsSettingsPanelOpen} />
      <Schedule isOpen={isSchedulePanelOpen} onClickClose={toggleIsSchedulePanelOpen} />
      {isAOEligible && (
        <SmartTargeting
          isOpen={isSmartTargetingPanelOpen}
          onClickClose={toggleIsSmartTargetingPanelOpen}
          journeyInstance={journeyInstance}
          status={smartTargetingStatus}
          setStatus={setSmartTargetingStatus}
        />
      )}
      <ContextDataSlideOut isOpen={isContextDataSlideOutOpen} onClickClose={toggleIsContextDataSlideOutOpen} />
    </>
  );
};

export default ActionsHeader;
