import { RootState } from 'store/store';
import { getBackendJourneyErrors } from 'helpers/error.helper';
import { getTemplateConstraint, getSchemaConstraint } from 'helpers/content.helper';
import { ERROR_TYPES } from 'pages/ExperienceCanvas/types';
import { JourneyError } from 'validators/ExperienceCanvas/types';
import { getDefaultLanguage } from 'helpers/templated-experience.helper';

export function getContentErrorList(state: RootState, refId: string, checkJourneyErrors = true): JourneyError[] {
  const contentState = state.te.content;

  const content = contentState.byRefId[refId];
  const templateConstraint = getTemplateConstraint(content) || getSchemaConstraint(content);

  const retVal: JourneyError[] = [];

  if (!content) retVal.push({ type: ERROR_TYPES.ERROR, err: 'Fatal Content Error' });
  // give exception to 'content-grouping' as it is a template configured action.
  else if (content.actionType === 'content-grouping') return retVal;
  else if (!content.id) retVal.push({ type: ERROR_TYPES.INCOMPLETE, err: 'Unconfigured Content' });
  // if this feels insufficient, we can also check the list of constant instances
  else if (templateConstraint && content.templateId !== templateConstraint)
    retVal.push({ type: ERROR_TYPES.ERROR, err: 'Content does not abide template constraints.' });

  const incompleteLocalizations = getIncompleteLocalizations(
    content?.payload?.content?.data?.[0],
    getDefaultLanguage(state.settings.languages),
  );

  for (const lang of Object.keys(incompleteLocalizations))
    retVal.push({
      type: ERROR_TYPES.INCOMPLETE,
      err: `Content on step ${content.stepIdx + 1} has incomplete form values for ${lang.toUpperCase()} localization`,
      nonblocking: true,
      category: 'localization',
    });

  if (!checkJourneyErrors) return retVal;
  const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { filteredErrors } = getBackendJourneyErrors(beErrors, state, (err: any) => {
    return err.component && err.component === 'content' && err.refId && err.refId === refId;
  });
  retVal.push(...filteredErrors);

  return retVal;
}

/**
 * A function to determine if a content item is missing localizations in languages other than the default.
 * Takes a content data item and the default language code and returns a map of languages that are missing localizations.
 * Current iteration only checks for falsy values, which may preclude some edge cases, especially with number values.
 * @param contentDataItem
 * @param defaultLang
 * @returns { [langCode: string]: boolean }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getIncompleteLocalizations(contentDataItem: Record<string, any>, defaultLang: string) {
  if (!contentDataItem) return {};
  const dataLocalizations = contentDataItem.localizations;
  if (!dataLocalizations || !dataLocalizations[defaultLang]) return {};
  const incompleteLocalizations: { [langCode: string]: boolean } = {};
  const primaryLanguageFields: { [field: string]: boolean } = {};

  for (const [field, val] of Object.entries(dataLocalizations[defaultLang])) {
    if (val) primaryLanguageFields[field] = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for (const [lang, obj] of Object.entries(dataLocalizations as Record<string, Record<string, any>>)) {
    if (lang === defaultLang) continue;
    // determines validity by ensuring fields are either all filled or all for non-empty primary language fields
    let isValid = true;
    let firstItemStatus = undefined;
    for (const field of Object.keys(primaryLanguageFields)) {
      if (firstItemStatus === undefined) {
        firstItemStatus = !!obj?.[field];
        continue;
      }
      if (firstItemStatus !== !!obj?.[field]) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      incompleteLocalizations[lang] = true;
    }
  }

  for (const [field, obj] of Object.entries(contentDataItem)) {
    if (field === 'localizations' || field.includes('pagination')) continue;
    if (Array.isArray(obj)) {
      for (const item of obj) {
        const incomplete = getIncompleteLocalizations(item, defaultLang);
        for (const lang of Object.keys(incomplete)) incompleteLocalizations[lang] = true;
      }
    } else if (typeof obj === 'object') {
      const incomplete = getIncompleteLocalizations(obj, defaultLang);
      for (const lang of Object.keys(incomplete)) incompleteLocalizations[lang] = true;
    }
  }

  return incompleteLocalizations;
}
