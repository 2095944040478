import './VisualizationSelector.scss';
import React, { useEffect } from 'react';
import { FlightRadioButton } from '@flybits/design-system';
import { VISUALIZER_OPTIONS } from 'pages/AnalyticsV2/CustomAnalytics/CreateOrEditChart/constants';
import { ChartConfigState } from 'pages/AnalyticsV2/types';
import { getDaysBetweenDates } from 'pages/AnalyticsV2/helpers';
import BarIcon from 'assets/images/bar-icon-v2.svg';
import TableIcon from 'assets/images/table-icon.svg';
import LineIcon from 'assets/images/single-line-graph-icon.svg';

const visualizerOptions = [
  { key: VISUALIZER_OPTIONS.BAR, label: 'Bar', value: VISUALIZER_OPTIONS.BAR, iconSrc: BarIcon, alt: 'Bar Icon' },
  {
    key: VISUALIZER_OPTIONS.TABLE,
    label: 'Table',
    value: VISUALIZER_OPTIONS.TABLE,
    iconSrc: TableIcon,
    alt: 'Table Icon',
  },
  { key: VISUALIZER_OPTIONS.LINE, label: 'Line', value: VISUALIZER_OPTIONS.LINE, iconSrc: LineIcon, alt: 'Line Icon' },
];

const MAIN_CLASS = 'visualization-selector';
const CLASSES = {
  OPTION_CONTAINER: `${MAIN_CLASS}__radio__container`,
  OPTION_IMAGE: `${MAIN_CLASS}__radio__icon`,
  OPTION_RADIO: `${MAIN_CLASS}__radio__radio`,
};

type TVisualizationSelectionProps = {
  chartConfig: ChartConfigState;
  onChange: (graphType: VISUALIZER_OPTIONS | null) => void;
  onValidation: (isValid: boolean) => void;
};

export default function VisualizationSelector({ chartConfig, onChange, onValidation }: TVisualizationSelectionProps) {
  const { visualizationType, timeFrame } = chartConfig;

  useEffect(() => {
    onValidation(!!visualizationType);
  }, [visualizationType, onValidation]);

  // Visualizer options are disabled based on the time frame
  useEffect(() => {
    const numberOfDays = timeFrame?.start && timeFrame?.end ? getDaysBetweenDates(timeFrame.start, timeFrame.end) : 0;
    const disabled = numberOfDays > 30 ? ['bar'] : [];
    // Bar can only be shown when 30 days or less
    if (disabled.includes('bar') && visualizationType === 'bar') {
      handleSelect(null); // deselect bar if it is selected
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFrame, visualizationType]);

  const handleSelect = (key: VISUALIZER_OPTIONS | null) => onChange?.(key);

  return (
    <section className={MAIN_CLASS}>
      {visualizerOptions.map((visualizer, idx) => {
        const { key, label, value, iconSrc, alt } = visualizer;
        const numberOfDays =
          timeFrame?.start && timeFrame?.end ? getDaysBetweenDates(timeFrame.start, timeFrame.end) : 0;
        const isDisabled = numberOfDays > 30 && value === VISUALIZER_OPTIONS.BAR;
        const isChecked = visualizationType === value;

        return (
          <div key={`ca-v2-visualizer-${idx}`} className={CLASSES.OPTION_CONTAINER}>
            <button
              className={`${CLASSES.OPTION_IMAGE} ${isDisabled ? 'disabled' : ''}`}
              onClick={() => !isDisabled && handleSelect(key)}
              aria-label={alt}
              disabled={isDisabled}
            >
              <img src={iconSrc} alt={alt} />
            </button>
            <FlightRadioButton
              key={key}
              className={`${CLASSES.OPTION_RADIO} ${isDisabled ? 'disabled' : ''}`}
              label={label}
              onSelect={handleSelect}
              value={value}
              checked={isChecked}
              disabled={isDisabled}
            />
          </div>
        );
      })}
    </section>
  );
}
