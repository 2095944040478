import './TimeFrameSelector.scss';
import React, { useState, useMemo, useEffect } from 'react';
import { coerceEpoch, epochToDateTimeString } from 'helpers/common.helper';
import { DATE_FORMAT_OPTIONS } from 'pages/AnalyticsV2/Overview/constants';
import { FlightDateRangePicker, FlightSelect } from '@flybits/design-system';
import { AdvancedDateRange, ChartConfigState, INTERVAL_OPTIONS } from 'pages/AnalyticsV2/types';
import { TIMEFRAME_INTERVAL_OPTIONS } from 'pages/AnalyticsV2/CustomAnalytics/CreateOrEditChart/constants';

const MAIN_CLASS = 'timeframe-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  BLOCK: `${MAIN_CLASS}__block`,
  PREVIEW: `${MAIN_CLASS}__preview`,
  DATE_RANGE: `${MAIN_CLASS}__date-range`,
  DATE_RANGE_DD: `${MAIN_CLASS}__date-range__dropdown`,
};

const transformPayloadToRange = (absolute: Date[], relative?: [number, string]): AdvancedDateRange =>
  relative ? [null, null, ...relative] : [absolute[0] ?? null, absolute[1] ?? null];
interface TimeFrameSelectorProps {
  chartConfig: ChartConfigState;
  onChange: (timeFrame: ChartConfigState['timeFrame']) => void;
  onValidation: (isValid: boolean) => void;
}

export default function TimeFrameSelector({ chartConfig, onChange, onValidation }: TimeFrameSelectorProps) {
  const { start, end, period, relative } = chartConfig.timeFrame || {};

  const initialDateRange = useMemo(() => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    return transformPayloadToRange([start || startOfToday, end || endOfToday], relative);
  }, [start, end, relative]);

  const [selectedDateRange, setSelectedDateRange] = useState<AdvancedDateRange>(initialDateRange);

  const intervalOptions = useMemo(
    () =>
      TIMEFRAME_INTERVAL_OPTIONS.map((interval) => {
        const { label, value, duration } = interval;
        return { key: value, name: label, duration };
      }).filter((opt) => {
        if (selectedDateRange[2] === 0) {
          return opt.key === INTERVAL_OPTIONS.HOURLY;
        }
        const duration = coerceEpoch(selectedDateRange[1] || 0) - coerceEpoch(selectedDateRange[0] || 0);
        return duration > opt.duration;
      }),
    [selectedDateRange],
  );

  const initialTimeInterval = useMemo(
    () =>
      period
        ? {
            key: period as INTERVAL_OPTIONS,
            name: TIMEFRAME_INTERVAL_OPTIONS.find((intervalOption) => intervalOption.value === period)?.label || '',
            duration: coerceEpoch(selectedDateRange[1] || 0) - coerceEpoch(selectedDateRange[0] || 0),
          }
        : intervalOptions[0],
    [period, selectedDateRange, intervalOptions],
  );

  const [selectedTimeInterval, setSelectedTimeInterval] = useState<(typeof intervalOptions)[number] | undefined>(
    initialTimeInterval,
  );

  const handleDateRangeChange = (selectedDate: AdvancedDateRange) => {
    setSelectedDateRange(selectedDate);

    const duration = coerceEpoch(selectedDate[1] || 0) - coerceEpoch(selectedDate[0] || 0);

    if (duration <= (selectedTimeInterval?.duration || 0)) {
      setSelectedTimeInterval(undefined);
    }

    onChange({
      start: selectedDateRange[0] || new Date(),
      end: selectedDateRange[1] || new Date(),
      period: selectedTimeInterval?.key || INTERVAL_OPTIONS.HOURLY,
      relative: [selectedDateRange[2] as number, selectedDateRange[3] as string],
    });
  };

  const handleIntervalChange = ({ key, name, duration }: { key: INTERVAL_OPTIONS; name: string; duration: number }) => {
    setSelectedTimeInterval({
      key,
      name,
      duration,
    });

    onChange({
      start: selectedDateRange[0] || new Date(),
      end: selectedDateRange[1] || new Date(),
      period: selectedTimeInterval?.key || INTERVAL_OPTIONS.HOURLY,
      relative: [selectedDateRange[2] as number, selectedDateRange[3] as string],
    });
  };

  useEffect(() => {
    const isTimePeriodValid = selectedDateRange?.[0] !== null && selectedDateRange?.[1] !== null;
    const isTimeIntervalValid = selectedTimeInterval && selectedTimeInterval.key !== undefined;
    const isValid = isTimePeriodValid && isTimeIntervalValid;
    onValidation(!!isValid);
  }, [selectedDateRange, selectedTimeInterval, onValidation]);

  return (
    <section className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="select-period">
        Select a period of time
      </label>
      <div className={CLASSES.BLOCK}>
        <FlightDateRangePicker
          className={`${CLASSES.DATE_RANGE_DD} half`}
          value={selectedDateRange}
          onChange={handleDateRangeChange}
          maxDate={new Date()}
          includeAllTime={false}
          includeToday
          isIncludeCustom
          replaceCustomRangeLabel={false}
          ariaControlsId={'select-period'}
        />
        <div className={CLASSES.PREVIEW}>
          {selectedDateRange?.[2] === 0
            ? `${epochToDateTimeString(new Date().getTime(), 'en-US', DATE_FORMAT_OPTIONS)}`
            : `${epochToDateTimeString(
                selectedDateRange?.[0] || 0,
                'en-US',
                DATE_FORMAT_OPTIONS,
              )} - ${epochToDateTimeString(selectedDateRange?.[1] || 0, 'en-US', DATE_FORMAT_OPTIONS)}`}
        </div>
      </div>
      <label className={CLASSES.LABEL} htmlFor="select-interval">
        Select an interval
      </label>
      <FlightSelect
        className={`${CLASSES.DATE_RANGE_DD}`}
        options={intervalOptions}
        selected={selectedTimeInterval}
        ariaControlsId={'select-interval'}
        width={'100%'}
        handleOptionClick={handleIntervalChange}
      />
    </section>
  );
}
