import { uploadImageFile } from 'helpers/common.helper';
import { MERCHANT_FORM_INIT_VALS, OFFER_FORM_INIT_VALS } from './merchant-portal.constants';
import {
  Merchant,
  MERCHANT_VERIFICATION_STATUSES,
  MerchantCategory,
  MerchantFormValues,
  MerchantUpdatePayload,
  Offer,
  OFFER_VERIFICATION_STATUSES,
  OfferAllFormValues,
  OfferScheduleFormValues,
  OfferUpdatePayload,
} from './merchant-portal.types';
import { ContentCreationPayload, ContentPrototype } from 'interface/content/content.interface';
import { ExperienceTemplate } from 'interface/experience/experience.interface';
import { CONTENT_ACTION_TYPES } from 'store/content/content.type';
import { SaveRuleAction } from 'store/actionTypes';

// Currency formatter
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});
export function formatCurrency(
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: number | string) => void,
  field: string,
) {
  const inputField = e.target;
  const number = parseInt(inputField.value.replace(/[^0-9]/g, ''), 10);
  setFieldValue(field, !isNaN(number) ? currencyFormatter.format(number / 100) + '' : '');
}

export const dateToEpoch = (date: Date) => {
  if (date == null) date = new Date(0);
  return (date.getTime() - date.getMilliseconds()) / 1000;
};

export const epochToDate = (ms: EpochTimeStamp) => {
  if (!ms) {
    return new Date();
  } else {
    return new Date(ms * 1000);
  }
};

export const getFullDate = (date: Date) =>
  new Intl.DateTimeFormat(undefined, {
    dateStyle: 'full',
  }).format(date);

export function validateInput(e: React.KeyboardEvent<HTMLInputElement>) {
  // Allow only number keys, control keys, and decimal points
  if (!/[0-9]|Backspace|ArrowLeft|ArrowRight|Delete|Tab|Enter/.test(e.key)) {
    e.preventDefault(); // Prevent non-numeric and non-control keys
  }
}

export const mapMerchantDataToFormValues = (merchantData?: Merchant): MerchantFormValues => {
  if (!merchantData) return MERCHANT_FORM_INIT_VALS;
  const {
    externalId,
    name,
    id,
    category,
    logoUrl: logo,
    phoneNumber: phone,
    addressLine: address,
    addressCity: city,
    addressState: state,
    addressCountry: country,
    addressZipCode: zip,
    verificationStatus,
    notes,
    contactEmail,
    contactName,
    contactPhoneNumber,
  } = merchantData;
  return {
    id,
    name: name?.trim(),
    externalId: externalId?.trim(),
    category,
    logo,
    phone: phone?.trim(),
    address: address?.trim(),
    city: city?.trim(),
    state,
    country,
    zip: zip.trim(),
    contact: {
      name: contactName?.trim(),
      phone: contactPhoneNumber?.trim(),
      email: contactEmail?.trim(),
    },
    verification: {
      verificationStatus,
      notes: notes?.trim(),
    },
  };
};
export const mapMerchantFormValuesToData = async (merchantData: MerchantFormValues): Promise<MerchantUpdatePayload> => {
  const { id, name, externalId, category, phone, address, city, state, country, zip, verification, contact } =
    merchantData;

  let logo = merchantData.logo;

  if (!(typeof logo === 'string')) {
    try {
      logo = await uploadImageFile(logo);
    } catch (e) {
      logo = '';
    }
  }

  return {
    id: id || '',
    externalId: externalId.trim(),
    name: name.trim(),
    category: category || '',
    logoUrl: (logo as string) || '',
    phoneNumber: phone,
    addressLine: address.trim(),
    addressCity: city.trim(),
    addressState: state,
    addressCountry: country,
    addressZipCode: zip.trim(),
    contactName: contact.name?.trim() || '',
    contactPhoneNumber: contact.phone || '',
    contactEmail: contact.email?.trim() || '',
    verificationStatus: verification?.verificationStatus || MERCHANT_VERIFICATION_STATUSES.PENDING,
    notes: verification?.notes?.trim() || '',
  };
};

export const getOfferScheduleInitValues = (): OfferScheduleFormValues => {
  const today = new Date();
  const todayEpoch = (today.getTime() - today.getMilliseconds()) / 1000;
  return {
    startDate: todayEpoch,
    endDate: todayEpoch + 86359, //86359 for 1 day in epoch
    targetBudget: '0',
    budgetNote: '',
    budgetConsumed: '0',
  };
};

export const mapOfferDataToFormValues = (offerData: Offer, merchantName: string): OfferAllFormValues => {
  const {
    id,
    title,
    description,
    additionalNote,
    verificationStatus,
    spendThreshold,
    earnCap,
    notes,
    desiredStartDate,
    desiredEndDate,
    targetBudget,
    budgetNotes,
  } = offerData;

  const offer = {
    ...OFFER_FORM_INIT_VALS,
    id,
    merchantName: merchantName?.trim() || '',
    title: title?.trim() || '',
    description: description?.trim() || '',
    additionalNote: additionalNote?.trim() || '',
    verification: {
      verificationStatus: verificationStatus || OFFER_VERIFICATION_STATUSES.PENDING,
      notes: notes?.trim() || '',
    },
    spendThreshold: String(spendThreshold || 0),
    earnCap: String(earnCap || 0),
  };

  const schedule = {
    ...getOfferScheduleInitValues(),
    startDate: desiredStartDate,
    endDate: desiredEndDate,
    targetBudget: String(targetBudget || 0),
    budgetNote: budgetNotes?.trim() || '',
  };

  return {
    offer,
    schedule,
  };
};

export const mapOfferFormValuesToData = (
  merchantId: string,
  { offer, schedule }: OfferAllFormValues,
  journeyInstanceId?: string,
  journeyTemplateId?: string,
): OfferUpdatePayload => {
  const { id, title, description, additionalNote, spendThreshold, earnCap, verification, type, redemptionLimit } =
    offer;
  const { startDate: desiredStartDate, endDate: desiredEndDate, targetBudget, budgetNote: budgetNotes } = schedule;

  return {
    merchantId,
    id: id || '',
    title: title.trim(),
    description: description.trim(),
    additionalNote: additionalNote?.trim() || '',
    spendThreshold: parseFloat(String(spendThreshold).substring(1).replaceAll(',', '')),
    earnCap: parseFloat(String(earnCap).substring(1).replaceAll(',', '')),
    verificationStatus: verification?.verificationStatus || OFFER_VERIFICATION_STATUSES.PENDING,
    notes: verification?.notes?.trim() || '',
    desiredStartDate,
    desiredEndDate,
    targetBudget: parseFloat(String(targetBudget).substring(1).replaceAll(',', '')),
    budgetNotes: budgetNotes?.trim() || '',
    // Not available in backend yet
    type: type?.trim() || 'Cashback',
    redemptionLimit: redemptionLimit?.trim() || 'Multiple Use',
    journeyInstanceId,
    journeyTemplateId,
  };
};

export const mapMerchantCategoriesToOptions = (merchantCategories: MerchantCategory[]) => {
  return merchantCategories.map((category) => ({
    key: category.id,
    name: category.name,
  }));
};

// ToDo: Support preferred rule
export const mapMerchantOfferToRulePayload = (
  merchant: Merchant,
  journeyTemplate: ExperienceTemplate,
  projectSubdomain: string,
): SaveRuleAction['payload'] | undefined => {
  const rule = journeyTemplate.steps?.[0].audience?.restricted;
  if (!rule || !rule.payload) return;
  const rulePayload = {
    ...rule.payload,
    id: '',
    logic: '1',
    name: '',
    predicates: {
      1: {
        name: 'boolEq',
        parameters: [
          {
            type: 'context',
            plugin: `ctx.${projectSubdomain}.merchant-offer`,
            attribute: `query.preferredCategory.${merchant.category}`,
          },
          {
            type: 'constant',
            value: 'true',
            dataType: 'bool',
          },
        ],
      },
    },
    visibility: 'draft',
  };

  return {
    type: 'restricted',
    templateId: rule?.templateId ?? '',
    id: '',
    templateType: journeyTemplate?.metadata?.templateType ?? '',
    refId: rule?.refId ?? '',
    stepIdx: 0,
    rulePayload,
    shouldSyncStartScheduleWithRuleBody: false,
  };
};

type ContentLocation = {
  lat: number;
  lng: number;
  metadata: {
    address: string;
    placeId?: string;
  };
};

export const mapMerchantOfferToContentCreationPayload = (
  merchant: Merchant,
  offer: Offer,
  journeyTemplate: ExperienceTemplate,
  defaultLangCode: string,
  projectSubdomain: string,
  location: ContentLocation,
): ContentCreationPayload | undefined => {
  const contentPrototype = journeyTemplate.steps?.[0].actions.find(
    (a) => a.actionType === CONTENT_ACTION_TYPES.INSTANCE,
  )?.payload as ContentPrototype;
  const contentTemplate = contentPrototype?.payload?.contentTemplate;
  if (!contentTemplate) return;
  // TODO: not a huge fan of hardcoding, but as of our schema rn ... its gonna require a mapping anyway :dead:
  const activeStatusContext = `ctx.${projectSubdomain}.merchant-offer.query.isActive.${offer.id}`;
  const data = {
    localizations: {
      [defaultLangCode]: {
        merchant: merchant.name,
        merchantImage: merchant.logoUrl ?? '',
        offerTitle: offer.title,
        activationStatus: `{{${activeStatusContext}}}`,
      },
    },
    details: {
      localizations: {
        [defaultLangCode]: {
          howToRedeem: offer.description,
          terms: `This offer is valid only at ${merchant.name} in ${merchant.addressCity}, ${merchant.addressState}. Cannot be combined with other offers. Cashback will be applied to your total bill at checkout.`,
        },
      },
      secondaryButton: {
        link: `universalLink://?ios=${encodeURIComponent(
          `http://maps.apple.com/?address=${location.metadata.address.replaceAll(' ', '+')}`,
        )}&android=${encodeURIComponent(
          `https://www.google.com/maps/place/${location.metadata.address.replaceAll(' ', '+')}`,
        )}`,
      },
      calendarImage: 'https://file-manager.demo.flybits.com/file-manager/E4F6223E-B754-4396-8C9B-1BF26931545C.png',
    },
    location,
    activeStatusContext,
    activatedImage:
      'https://file-manager.development.flybits.com/file-manager-v3-dev/4C210BBD-3091-4554-AFC4-9C1DD61C2BDD.svg',
    inActiveImage:
      'https://file-manager.development.flybits.com/file-manager-v3-dev/D4B3565A-51F1-4088-AC57-F2FEA34D344B.svg',
    offerStartDate: new Date(offer.desiredStartDate * 1000).toISOString(),
    offerEndDate: new Date(offer.desiredEndDate * 1000).toISOString(),
  };
  const contentName = `${offer.title} - ${merchant.name}`;
  const labels = contentTemplate?.labels || [];
  labels.push(merchant.category, 'default-zone');

  return {
    accessType: 'default',
    data,
    iconUrl: merchant.logoUrl || contentTemplate?.iconUrl || '',
    labels,
    localizations: {
      [defaultLangCode]: {
        name: contentName,
        description: contentTemplate?.localizations?.[defaultLangCode]?.description || '',
      },
    },
    templateId: contentTemplate?.id || '',
    templatePrototypeId: contentPrototype.id || '',
    templateType: contentTemplate?.type || '',
    ...contentTemplate.defaultValues,
  };
};
