import React, { useEffect, useLayoutEffect, useRef } from 'react';
import {
  FlightButton,
  FlightImageUploader,
  FlightSelect,
  FlightTextArea,
  FlightTextInput,
} from '@flybits/design-system';
import './MerchantForm.scss';
import { Field, Form, Formik, getIn } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
// import ScrollToFormikErrorField from 'components/Shared/ScrollToFormikErrorField/ScrollToFormikErrorField';
import {
  MerchantCategory,
  MerchantFormValues,
  MERCHANT_VERIFICATION_STATUSES,
} from 'pages/MerchantPortal/merchant-portal.types';
import {
  US_STATES,
  MerchantVerificationStatusOptions,
  MerchantValidationSchema,
} from 'pages/MerchantPortal/merchant-portal.constants';
import { mapMerchantCategoriesToOptions } from 'pages/MerchantPortal/merchant-portal.helpers';
import { useAppSelector as useSelector } from 'hooks/reduxHooks';

const MERCHANT_FORM = 'merchant-form';
const CLASSES = {
  CONTENT: `${MERCHANT_FORM}__content`,
  CONTENT_LEFT: `${MERCHANT_FORM}__content__left`,
  CONTENT_RIGHT: `${MERCHANT_FORM}__content__right`,
  CONTENT_FOOTER: `${MERCHANT_FORM}__content__footer`,
  CONTENT_GROUP: `${MERCHANT_FORM}__content__group`,
  CONTENT_GROUP_ADDRESS: `${MERCHANT_FORM}__content__group__address`,
};

// TODO: (PET-2834) Add masking feature to design-system so that we can reduce ref boilerplate
const PHONE_MASK_NA = '(___) ___ - ____';
const maskPhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  const val = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

  let formattedNumber = PHONE_MASK_NA;

  Array.from(val).forEach((c) => {
    formattedNumber = formattedNumber.replace('_', c);
  });

  return formattedNumber;
};

interface FormProps {
  onSubmit: (values: MerchantFormValues) => void;
  data: MerchantFormValues;
  categories: MerchantCategory[];
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  isEditMode?: boolean;
}
interface SelectOptionProps {
  key: string;
  name: string;
}

const formattedStates = US_STATES.map(({ code, name }, index) => {
  return { key: code, value: index, name };
});
const COUNTRIES = [{ key: 'us', value: 'us', name: 'United States' }];
function MerchantForm(props: FormProps) {
  const { onSubmit, data, categories, setDirty, isEditMode = false } = props;
  const isFBAdmin = useSelector((state) => state.auth.user?.level && state.auth.user.level >= 21);
  const history = useHistory();
  const { pid } = useParams<{ pid: string }>();
  const phoneRef = useRef<HTMLInputElement | null>(null);
  const contactPhoneRef = useRef<HTMLInputElement | null>(null);
  const categoryOptions = mapMerchantCategoriesToOptions(categories);
  const handleCancel = () => {
    history.push(`/project/${pid}/merchants`);
  };
  return (
    <Formik
      initialValues={data}
      validationSchema={MerchantValidationSchema}
      validateOnChange
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {function DisplayForm({
        values,
        errors,
        touched,
        isValid,
        dirty,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) {
        useLayoutEffect(() => {
          if (phoneRef.current && document.activeElement === phoneRef.current) {
            const digits = Array.from(phoneRef.current.value.replace(/\D/g, ''));
            const caretPostion = digits.length ? phoneRef.current.value.lastIndexOf(digits[digits.length - 1]) + 1 : 1;
            phoneRef.current.setSelectionRange(caretPostion, caretPostion);
          }
          if (contactPhoneRef.current && document.activeElement === contactPhoneRef.current) {
            const digits = Array.from(contactPhoneRef.current.value.replace(/\D/g, ''));
            const caretPostion = digits.length
              ? contactPhoneRef.current.value.lastIndexOf(digits[digits.length - 1]) + 1
              : 1;
            contactPhoneRef.current.setSelectionRange(caretPostion, caretPostion);
          }
        }, [values]);

        useEffect(() => {
          setDirty(dirty);
        }, [dirty]);

        return (
          <Form className={MERCHANT_FORM} autoComplete="off">
            <header>
              <h2>Merchant Profile</h2>
              <p>Please provide the following information about the merchant</p>
            </header>
            <main className={CLASSES.CONTENT}>
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>About the merchant</h3>
                  <p>
                    Please provide the following information about the merchant to help us identify and categorize the
                    business correctly
                  </p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="merchant-name">
                    Merchant name<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="name"
                    width="100%"
                    value={values.name}
                    as={FlightTextInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.name && touched.name}
                  />
                  {!!errors.name && touched.name ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.name}
                    </span>
                  ) : (
                    <span>Enter the official name of the merchant</span>
                  )}
                  <label htmlFor="merchant-id">
                    Merchant ID<span>*</span>
                  </label>
                  <Field
                    type="number"
                    name="externalId"
                    width="100%"
                    value={values.externalId}
                    as={FlightTextInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.externalId && touched.externalId}
                  />
                  {!!errors.externalId && touched.externalId ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.externalId}
                    </span>
                  ) : (
                    <span>
                      Enter the unique Merchant ID. This is a number the merchant should have this number assigned by
                      their acquirer
                    </span>
                  )}
                  <label htmlFor="merchant-category">
                    Category<span>*</span>
                  </label>
                  <Field
                    type="select"
                    name="category"
                    hasLabelAnimation
                    label="Select a category"
                    arialabel="Select a category"
                    width="100%"
                    options={categoryOptions}
                    selected={categoryOptions.find((category) => category.key === values.category) ?? null}
                    dropdownMaxHeight="250px"
                    as={FlightSelect}
                    hasError={!!errors.category && touched.category}
                    handleOptionClick={(value: SelectOptionProps) => {
                      setFieldValue('category', value.key);
                    }}
                  />
                  {!!errors.category && touched.category ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.category}
                    </span>
                  ) : (
                    <span>Select the category that best describes the merchant</span>
                  )}
                  <label htmlFor="merchant-icon">Merchant logo</label>
                  <Field
                    name="logo"
                    as={FlightImageUploader}
                    onChange={(file: File) => {
                      setFieldTouched('logo', true);
                      setFieldValue('logo', file);
                    }}
                    imageUrl={typeof values.logo === 'string' ? values.logo : ''}
                    selectedFile={typeof values.logo === 'string' ? undefined : values.logo}
                  />
                  <span>Maximum file size is 100 MB</span>
                  <label htmlFor="merchant-phone">
                    Phone number<span>*</span>
                  </label>
                  <Field
                    type="number"
                    name="phone"
                    width="100%"
                    value={values.phone}
                    placeholderText={PHONE_MASK_NA}
                    inputRef={phoneRef}
                    as={FlightTextInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched('phone', true);
                      setFieldValue('phone', maskPhoneInput(e));
                    }}
                    onBlur={() => {
                      if (values.phone === PHONE_MASK_NA) {
                        setFieldTouched('phone', true);
                        setFieldValue('phone', '');
                      }
                    }}
                    onFocus={() => {
                      if (!values.phone) {
                        setFieldTouched('phone', true);
                        setFieldValue('phone', PHONE_MASK_NA);
                      }
                    }}
                    hasError={!!errors.phone && touched.phone}
                  />
                  {!!errors.phone && touched.phone ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.phone}
                    </span>
                  ) : (
                    <span>Enter the merchant contact number here</span>
                  )}
                  <div className={CLASSES.CONTENT_GROUP_ADDRESS}>
                    <div>
                      <label htmlFor="merchant-country">
                        Country<span>*</span>
                      </label>
                      <Field
                        type="select"
                        name="country"
                        hasLabelAnimation
                        label="Select a country"
                        arialabel="Select a country"
                        width="100%"
                        // TODO: This should be filled with "real" data
                        options={COUNTRIES}
                        // TODO: This is temporary selection check
                        selected={
                          values.country
                            ? {
                                key: values.country,
                                name: COUNTRIES.find((s) => s.key === values.country)?.name || values.country,
                              }
                            : null
                        }
                        dropdownMaxHeight="250px"
                        as={FlightSelect}
                        hasError={!!errors.country && touched.country}
                        handleOptionClick={(value: SelectOptionProps) => {
                          setFieldValue('country', value.key);
                        }}
                      />
                      {!!errors.country && touched.country ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {errors.country}
                        </span>
                      ) : (
                        <span className="info">Pick the merchant&apos;s country</span>
                      )}
                    </div>
                    <div>
                      <label htmlFor="merchant-address">
                        Address<span>*</span>
                      </label>
                      <Field
                        type="text"
                        name="address"
                        width="100%"
                        value={values.address}
                        as={FlightTextInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={!!errors.address && touched.address}
                      />
                      {!!errors.address && touched.address ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {errors.address}
                        </span>
                      ) : (
                        <span className="info">Enter the merchant address here</span>
                      )}
                    </div>
                  </div>
                  <div className={CLASSES.CONTENT_GROUP}>
                    <label htmlFor="merchant-city">
                      City<span>*</span>
                      <Field
                        type="text"
                        name="city"
                        width="100%"
                        value={values.city}
                        as={FlightTextInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={!!errors.city && touched.city}
                        errorMessage={
                          <>
                            <b>Error:</b> {errors.city}
                          </>
                        }
                      />
                    </label>
                    <label htmlFor="merchant-state">
                      State<span>*</span>
                      <Field
                        type="select"
                        name="state"
                        hasLabelAnimation
                        label="Select a state"
                        arialabel="Select a state"
                        width="100%"
                        // TODO: This should be filled with "real" data
                        options={formattedStates}
                        // TODO: This is temporary selection check
                        selected={
                          values.state
                            ? {
                                key: values.state,
                                name: formattedStates.find((s) => s.key === values.state)?.name || values.state,
                              }
                            : null
                        }
                        dropdownMaxHeight="250px"
                        as={FlightSelect}
                        hasError={!!errors.state && touched.state}
                        handleOptionClick={(value: SelectOptionProps) => {
                          setFieldValue('state', value.key);
                        }}
                      />
                    </label>
                    <label htmlFor="merchant-city">
                      Zip code<span>*</span>
                      <Field
                        type="text"
                        name="zip"
                        width="100%"
                        value={values.zip}
                        as={FlightTextInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hasError={!!errors.zip && touched.zip}
                        errorMessage={
                          <>
                            <b>Error:</b> {errors.zip}
                          </>
                        }
                      />
                    </label>
                  </div>
                </div>
              </section>
              <hr />
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>Merchant Contact Person</h3>
                  <p>
                    Enter the details of the primary contact person responsible for handling any needs or communications
                  </p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="merchant-contact-name">Contact person</label>
                  <Field
                    type="text"
                    name="contact.name"
                    width="100%"
                    value={values.contact.name}
                    as={FlightTextInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.contact?.name && touched.contact?.name}
                  />
                  {!!errors.contact?.name && touched.contact?.name ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.contact?.name}
                    </span>
                  ) : (
                    <span>Enter the name of the primary contact person</span>
                  )}
                  <label htmlFor="merchant-contact-phone">Phone number</label>
                  <Field
                    type="text"
                    name="contact.phone"
                    width="100%"
                    inputRef={contactPhoneRef}
                    value={values.contact?.phone}
                    placeholderText={PHONE_MASK_NA}
                    as={FlightTextInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldTouched('contact.phone', true);
                      setFieldValue('contact.phone', maskPhoneInput(e));
                    }}
                    onBlur={() => {
                      if (values.contact?.phone === PHONE_MASK_NA) {
                        setFieldTouched('contact.phone', true);
                        setFieldValue('contact.phone', '');
                      }
                    }}
                    onFocus={() => {
                      if (!values.contact?.phone) {
                        setFieldTouched('contact.phone', true);
                        setFieldValue('contact.phone', PHONE_MASK_NA);
                      }
                    }}
                    hasError={!!errors.contact?.phone && touched.contact?.phone}
                  />
                  {!!errors.contact?.phone && touched.contact?.phone ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.contact?.phone}
                    </span>
                  ) : (
                    <span>Enter the phone number of the primary contact person</span>
                  )}
                  <label htmlFor="merchant-contact-email">Email</label>
                  <Field
                    type="email"
                    name="contact.email"
                    width="100%"
                    value={values.contact?.email}
                    as={FlightTextInput}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.contact?.email && touched.contact?.email}
                  />
                  {!!errors.contact?.email && touched.contact?.email ? (
                    <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                      <b>Error:</b> {errors.contact?.email}
                    </span>
                  ) : (
                    <span>Enter the email address of the primary contact person</span>
                  )}
                </div>
              </section>
              {isEditMode && isFBAdmin && (
                <>
                  <hr />
                  <section>
                    <div className={CLASSES.CONTENT_LEFT}>
                      <h3>Merchant Verification Status</h3>
                      <p>Only Admin users can make changes to this field</p>
                    </div>
                    <div className={CLASSES.CONTENT_RIGHT}>
                      <label htmlFor="merchant-verification-status">
                        Verification Status<span>*</span>
                      </label>
                      <Field
                        type="select"
                        name="verification.verificationStatus"
                        hasLabelAnimation
                        label="Select a status"
                        width="100%"
                        options={MerchantVerificationStatusOptions}
                        selected={MerchantVerificationStatusOptions.find(
                          (opt) => opt.key === values.verification?.verificationStatus,
                        )}
                        dropdownMaxHeight="250px"
                        as={FlightSelect}
                        hasError={
                          !!getIn(errors, 'verification.verificationStatus') &&
                          getIn(touched, 'verification.verificationStatus')
                        }
                        handleOptionClick={(value: SelectOptionProps) => {
                          setFieldValue('verification.verificationStatus', value.key);
                        }}
                      />
                      {!!getIn(errors, 'verification.verificationStatus') &&
                      getIn(touched, 'verification.verificationStatus') ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {getIn(errors, 'verification.verificationStatus')}
                        </span>
                      ) : (
                        <span>Select the merchant verification status</span>
                      )}
                      <label htmlFor="merchant-verification-notes">Notes</label>
                      <Field
                        type="text"
                        name="verification.notes"
                        width="100%"
                        value={values.verification?.notes}
                        as={FlightTextArea}
                        disabled={values.verification?.verificationStatus !== MERCHANT_VERIFICATION_STATUSES.FAILED}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        hideCharCounter
                        hasError={!!getIn(errors, 'verification.notes') && getIn(touched, 'verification.notes')}
                      />
                      {!!getIn(errors, 'verification.notes') && getIn(touched, 'verification.notes') ? (
                        <span role="alert" aria-atomic="true" className="flight-text-input__error-message">
                          <b>Error:</b> {getIn(errors, 'verification.notes')}
                        </span>
                      ) : (
                        <span>Add any additional notes about the verification status of this merchant</span>
                      )}
                    </div>
                  </section>
                </>
              )}
              <section className={CLASSES.CONTENT_FOOTER}>
                <FlightButton theme="secondary" onClick={handleCancel} label={`Cancel`} />
                <FlightButton
                  theme="primary"
                  onClick={handleSubmit}
                  label={isEditMode ? `Save` : `Next`}
                  disabled={!isValid || (isEditMode && !dirty)}
                />
              </section>
            </main>
            {/* Currently disabling scrolling - does not make sense for live validation */}
            {/* <ScrollToFormikErrorField /> */}
          </Form>
        );
      }}
    </Formik>
  );
}

export default MerchantForm;
