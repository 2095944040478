import './ExperienceSelector.scss';
import React, { useState, useEffect } from 'react';
import { FlightTextInput } from '@flybits/design-system';
import { ExperienceSelectorItem } from 'pages/AnalyticsV2/types';

const MAIN_CLASS = 'experience-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  SELECT: `${MAIN_CLASS}__select`,
};

interface ExperienceSelectorProps {
  experiences?: ExperienceSelectorItem[];
  selectedExperiencesIds?: string[];
  onChange?: (experiences: ExperienceSelectorItem[]) => void;
  isMultiple?: boolean;
  onValidation: (isValid: boolean) => void;
}

/**
 * ExperienceSelector component allows users to search and select experiences from a list.
 *
 * @param {ExperienceSelectorProps} props - The properties for the ExperienceSelector component.
 * @param {ExperienceSelectorItem[]} [props.experiences] - An optional array of experience items to display in the selector.
 * @param {string[]} [props.selectedExperiencesIds] - An optional array of selected experience IDs.
 * @param {(exps: ExperienceSelectorItem[]) => void} [props.onChange] - An optional callback function that is called when the selected experiences change.
 * @param {boolean} [props.isMultiple] - An optional boolean indicating if multiple selections are allowed.
 * @param {function} props.onValidation - Callback function to send validation status to parent component.
 *
 */
export default function ExperienceSelector({
  experiences,
  selectedExperiencesIds,
  isMultiple,
  onChange,
  onValidation,
}: ExperienceSelectorProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState<string[]>(selectedExperiencesIds || []);
  useEffect(() => {
    const isValid = selected.length > 0;
    onValidation(isValid);
  }, [selected, onValidation]);
  return (
    <div className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="experience-list">
        Select an Experience <span>*</span>
      </label>
      <FlightTextInput
        labelId={'experience-search'}
        iconInput="search"
        width="100%"
        value={searchTerm}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(evt.target.value)}
        placeholderText="Search"
      />
      <select
        className={CLASSES.SELECT}
        id="experience-list"
        aria-labelledby="experience-list"
        multiple={isMultiple}
        size={5}
        defaultChecked={false}
        value={selected.length ? selected : undefined}
        onChange={(e) => {
          const selectedValue = Array.from(e.target.selectedOptions).map((option) => option.value);
          setSelected(selectedValue);
          onChange && onChange(experiences?.filter((experience) => selectedValue.includes(experience.name)) ?? []);
        }}
      >
        {experiences
          ?.filter((experience) => experience.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
          .map(({ key, name }) => (
            <option key={key} value={name} selected={selected?.includes(name)}>
              {name}
            </option>
          ))}
      </select>
    </div>
  );
}
