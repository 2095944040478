import React from 'react';
import { ResponsiveLine, Serie } from '@nivo/line';
import { TLineGraph } from 'pages/AnalyticsV2/types';

type TLineGraphProps = {
  responseData: TLineGraph;
};

const LineGraph: React.FC<TLineGraphProps> = ({ responseData }) => {
  const { xAxis, yAxis } = responseData.moduleConfig.chartConfig;
  const transformedData: Serie[] = [];
  responseData.data.forEach((rawDatum) => {
    const index = transformedData.findIndex((td) => td.id === rawDatum.name);

    if (index === -1)
      transformedData.push({ id: rawDatum.name, data: [{ x: new Date(rawDatum.x * 1000), y: rawDatum.y }] });
    else {
      transformedData[index].data.push({ x: new Date(rawDatum.x * 1000), y: rawDatum.y });
    }
  });

  return (
    <div style={{ height: '243px' }}>
      <ResponsiveLine
        data={transformedData}
        margin={{ top: 10, right: 10, bottom: 40, left: 50 }}
        xScale={{ type: 'time', format: `'%y, %b %d` }}
        yScale={{
          type: 'linear',
          stacked: true,
        }}
        xFormat={`time:%Y %b %d`}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickValues: 5,
          legend: xAxis,
          legendOffset: 32,
          legendPosition: 'middle',
          // TODO (PET-3697): Figure out with design what we want this label to look like based on the interval
          format: `%m-%d '%y`,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickValues: 5,
          legend: yAxis,
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        colors={'#0851df'}
        enableArea={true}
        enablePoints={false}
        enableGridY={false}
        enableGridX={false}
        useMesh={true}
      />
    </div>
  );
};

export default LineGraph;
