import './GraphTile.scss';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MenuIcon from 'assets/icons/menu-icon.svg';
import { FlightButton, FlightOverflowMenu, FlightTooltip } from '@flybits/design-system';
import { DropdownOptions } from 'interface/shared/dropdown.interface';
import useConfirmModal from 'hooks/useConfirmModal';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as IconDeleteChart } from 'assets/icons/icon-delete-chart.svg';
import { ReactComponent as IconDownloadData } from 'assets/icons/icon-download-data.svg';
import { BarGraphData, LineGraphData, TAnalyticsRouteParams, TBarGraph, TLineGraph } from 'pages/AnalyticsV2/types';
import Skeleton from 'react-loading-skeleton';
import BarGraph from 'pages/AnalyticsV2/CustomAnalytics/Dashboard/GraphTile/graphs/BarGraph';
import LineGraph from 'pages/AnalyticsV2/CustomAnalytics/Dashboard/GraphTile/graphs/LineGraph';
import TableGraph from 'pages/AnalyticsV2/CustomAnalytics/Dashboard/GraphTile/graphs/TableGraph';
import useExportGraph from 'pages/AnalyticsV2/CustomAnalytics/hooks/useExportGraph';
import experienceGraphService from 'services/api/experience-graph.api';
import { VISUALIZER_OPTIONS } from '../../CreateOrEditChart/constants';
import { useThunkDispatch } from 'hooks/reduxHooks';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
  id: string;
  title: string;
  description?: string;
  graphType?: VISUALIZER_OPTIONS;
  // ToDo: remove any when the graphData type is fixed
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphData: TBarGraph | TLineGraph | any;
  previewMode?: boolean;
}

// CLASSES
const CLASS_MAIN = 'graph-tile';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  HEADER: `${CLASS_MAIN}__header`,
  GRAPH: `${CLASS_MAIN}__graph`,
  GRAPH_NO_DATA: `${CLASS_MAIN}__graph__no-data`,
  GRAPH_UNSUPPORTED: `${CLASS_MAIN}__graph__unsupported`,
  CONFIRMATION_MODAL: `${CLASS_MAIN}__confirmation-modal`,
};

const HEADER = {
  TOPBAR: `${CLASSES.HEADER}__topbar`,
  TITLE: `${CLASSES.HEADER}__title`,
  MENU: `${CLASSES.HEADER}__menu`,
  MENU_ICON: `${CLASSES.HEADER}__menu-icon`,
  DESCRIPTION: `${CLASSES.HEADER}__description`,
};

const removeTileConfirmDialogProps: ConfirmationDialogProps = {
  theme: ConfirmationModalTypes.DELETE,
  className: CLASSES.CONFIRMATION_MODAL,
  icon: <IconDeleteChart />,
  title: 'Are you sure you want to delete this chart?',
  description: 'Deleting this chart will remove it from your dashboard. ',
  primaryAction: { value: 'Delete chart' },
  secondaryAction: { value: 'Cancel' },
};

const downloadTileConfirmDialogProps: ConfirmationDialogProps = {
  icon: <IconDownloadData />,
  title: 'Download chart',
  description:
    'Note that the chart data will be downloaded based on the metrics you have selected. The format will be in CSV.',
  primaryAction: {
    value: 'Download',
  },
  secondaryAction: {
    value: 'Cancel',
  },
};

function GraphTile({ id, title, description = '', graphType, graphData, previewMode = false }: Props) {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { pid } = useParams<TAnalyticsRouteParams>();
  const [RemoveTileConfirmModal, showRemoveTileConfirmModal] = useConfirmModal(removeTileConfirmDialogProps);
  const handleRemoveTile = useCallback(async () => {
    if (await showRemoveTileConfirmModal()) {
      // TODO: add loading state change from PET-3706
      try {
        await experienceGraphService.deleteExperienceGraph(graphData?.moduleConfig?.id);
        queryClient.invalidateQueries(['experience-graphs']);
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Graph "${title}" removed`, type: 'success' },
        });
      } catch (error) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: `Graph "${title}" could not be removed`, type: 'error' },
        });
      }
    }
  }, [dispatch, graphData?.moduleConfig?.id, queryClient, showRemoveTileConfirmModal, title]);

  const [exportGraphData, setExportGraphData] = useState<Record<string, string>[]>([]);
  const { ExportGraphConfirmModal, handleExportGraph } = useExportGraph({
    confirmModalProps: downloadTileConfirmDialogProps,
    graphType: graphType ?? VISUALIZER_OPTIONS.BAR,
    exportGraphData,
  });

  const handleEditGraph = useCallback(() => {
    history.push(`/project/${pid}/analytics/v2/experiences/${id}`);
  }, [history, pid, id]);

  useEffect(() => {
    const chartData = graphData.data || [];

    switch (graphType) {
      case VISUALIZER_OPTIONS.BAR:
        const barData = chartData as BarGraphData;
        setExportGraphData(
          barData.map((dataPoint) => ({
            'Journey ID': dataPoint.journey_id,
            Name: dataPoint.name,
            Value: String(dataPoint.value),
          })),
        );
        return;
      case VISUALIZER_OPTIONS.LINE:
        const lineData = chartData as LineGraphData;
        setExportGraphData(
          lineData.map((dataPoint) => ({
            Name: dataPoint.name,
            X: String(dataPoint.x),
            Y: String(dataPoint.y),
          })),
        );
        return;
      case VISUALIZER_OPTIONS.TABLE:
        // ToDo: chart config `table` doesn't exist in the system yet
        const tableData = chartData as { journey_id: string; name: string; value: number }[];
        setExportGraphData(
          tableData.map((dataPoint) => ({
            'Journey ID': dataPoint.journey_id,
            Name: dataPoint.name,
            Value: String(dataPoint.value),
          })),
        );
        return;
    }
  }, [graphType, graphData]);

  const contextMenuOptions: Array<Array<DropdownOptions>> = useMemo(
    () => [
      [
        {
          key: 1,
          name: 'Edit Graph',
          disabled: false,
          onClick: handleEditGraph,
        },
        {
          key: 2,
          name: 'Export CSV',
          disabled: false,
          onClick: handleExportGraph,
        },
        {
          key: 3,
          name: 'Remove',
          disabled: false,
          onClick: handleRemoveTile,
        },
      ],
    ],
    [handleRemoveTile, handleExportGraph, handleEditGraph],
  );

  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const shouldShowToolTip = (reference: React.RefObject<HTMLDivElement>) => {
    if (reference.current && reference.current.textContent && headerRef.current) {
      // Since I couldn't rely on clientWidth and scrollWidth I had to create the below mathematical way to determine when it should show the tooltip it won't work 100% but it will be pretty close to it.
      // It is based on the width of the header field and the proportion of character it fits on certain amount of pixels.
      return headerRef.current.clientWidth / reference.current.textContent.length < 4.8;
    } else {
      return false;
    }
  };

  return (
    <main className={CLASSES.BODY}>
      <div className={CLASSES.HEADER} ref={headerRef}>
        <div className={HEADER.TOPBAR}>
          <FlightTooltip description={title} direction="bottom" isEnabled={shouldShowToolTip(titleRef)}>
            <div className={HEADER.TITLE} ref={titleRef} id="title">
              {previewMode && !title && <Skeleton width={'100%'} />}
              {title}
            </div>
          </FlightTooltip>
          <div className={HEADER.MENU}>
            <img className={HEADER.MENU_ICON} alt="graph-menu" src={MenuIcon} />
            <FlightOverflowMenu
              direction={'bottom'}
              isOpenRight={false}
              disabled={previewMode}
              theme={'dark'}
              optionGroups={contextMenuOptions}
            />
          </div>
        </div>
        <FlightTooltip description={description} direction="bottom" isEnabled={shouldShowToolTip(descriptionRef)}>
          <div className={HEADER.DESCRIPTION} ref={descriptionRef} id="description">
            {previewMode && !description && <Skeleton width={'50%'} />}
            {description}
          </div>
        </FlightTooltip>
      </div>
      <div className={CLASSES.GRAPH}>
        {previewMode ? (
          <Skeleton width={'100%'} height={'100%'} />
        ) : graphType === VISUALIZER_OPTIONS.UNSUPORTED ? (
          <div className={CLASSES.GRAPH_UNSUPPORTED}>
            <p>This type of graph is from an old version of analytics and it is not supported anymore.</p>
            <p>
              Click{' '}
              <FlightButton theme="link" label={'here'} onClick={() => handleEditGraph()} ariaLabel="edit graph" /> to
              edit.
            </p>
          </div>
        ) : !graphData.data?.length ? (
          <div className={CLASSES.GRAPH_NO_DATA}>No data available</div>
        ) : graphType === VISUALIZER_OPTIONS.BAR ? (
          <BarGraph responseData={graphData} />
        ) : graphType === VISUALIZER_OPTIONS.LINE ? (
          <LineGraph responseData={graphData} />
        ) : graphType === VISUALIZER_OPTIONS.TABLE ? (
          <TableGraph
            // mock headers
            headers={[
              { key: 'name', name: 'Experience' },
              { key: 'value', name: 'Reach' },
            ]}
            responseData={graphData}
          />
        ) : null}
      </div>
      <RemoveTileConfirmModal />
      <ExportGraphConfirmModal />
    </main>
  );
}

export default GraphTile;
