import './MetricsSelector.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { FlightSelect } from '@flybits/design-system';
import { METRIC_OPTIONS } from '../constants';
import { ChartConfigState } from 'pages/AnalyticsV2/types';

const MAIN_CLASS = 'metrics-selector';
const CLASSES = {
  LABEL: `${MAIN_CLASS}__label`,
  DD: `${MAIN_CLASS}__select`,
};

type MetricOption = Omit<(typeof METRIC_OPTIONS)[number], 'value'> & {
  value?: (typeof METRIC_OPTIONS)[number]['value'];
};
type MetricValue = MetricOption['value'];
type TMetricsSelectorProps = {
  chartConfig: ChartConfigState;
  onChange: (metric?: MetricValue) => void;
  onValidation: (isValid: boolean) => void;
};

export default function MetricsSelector({ chartConfig, onChange, onValidation }: TMetricsSelectorProps) {
  const { metrics } = chartConfig;

  const initialOption = useMemo(() => {
    let initOption: MetricOption = {
      key: 999,
      name: 'None Selected',
      value: undefined,
    };

    if (metrics) {
      initOption =
        METRIC_OPTIONS.find((o) => o.value?.entity === metrics.entity && o.value?.metric === metrics.metric) ||
        initOption;
    }
    return initOption;
  }, [metrics]);

  const [selectedOption, setSelectedOption] = useState<MetricOption>(initialOption);

  useEffect(() => {
    const isValid = !!selectedOption?.value;
    onValidation(isValid);
  }, [selectedOption, onValidation]);

  return (
    <section className={MAIN_CLASS}>
      <label className={CLASSES.LABEL} htmlFor="select-interval">
        Select a metric
      </label>
      <FlightSelect
        label=""
        className={`${CLASSES.DD}`}
        options={METRIC_OPTIONS}
        selected={selectedOption}
        width={'100%'}
        handleOptionClick={(option: (typeof METRIC_OPTIONS)[number]) => {
          setSelectedOption(option);
          onChange && onChange(option.value);
        }}
      />
    </section>
  );
}
