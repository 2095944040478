import React from 'react';
import './Preview.scss';
import { BarGraphData, ChartConfigState, LineGraphData } from 'pages/AnalyticsV2/types';
import GraphTile from 'pages/AnalyticsV2/CustomAnalytics/Dashboard/GraphTile/GraphTile';
import { serializeChartConfig } from 'pages/AnalyticsV2/helpers';
import { VISUALIZER_OPTIONS } from '../constants';
const MAIN_CLASS = 'chart-preview';
const CLASSES = {
  TITLE: `${MAIN_CLASS}__title`,
  CONTENT: `${MAIN_CLASS}__content`,
  NO_PREVIEW: `${MAIN_CLASS}__content__empty`,
};

type TPreviewProps = {
  chartConfig: ChartConfigState;
  data?: BarGraphData | LineGraphData;
};

export default function Preview({ chartConfig, data }: TPreviewProps) {
  const { title, description, visualizationType } = chartConfig;
  const isEmpty = Object.values(chartConfig).every((value) => !value);
  const graphData = data
    ? {
        data,
        moduleConfig: serializeChartConfig(chartConfig),
      }
    : null;
  return (
    <div className={MAIN_CLASS}>
      <h2>Preview</h2>
      {isEmpty ? (
        <div className={CLASSES.CONTENT}>
          <div className={CLASSES.NO_PREVIEW}>Make selections in the left panels to begin seeing previews</div>
        </div>
      ) : visualizationType === VISUALIZER_OPTIONS.UNSUPORTED ? (
        <div className={CLASSES.CONTENT}>
          <div className={CLASSES.NO_PREVIEW}>This type of graph is not supported.</div>
        </div>
      ) : (
        <GraphTile
          title={title ?? ''}
          description={description}
          graphType={visualizationType ?? undefined}
          graphData={graphData}
          previewMode={visualizationType && data ? false : true}
          id={`preview-${chartConfig?.selectedExperiences?.[0] ?? title}`}
        />
      )}
    </div>
  );
}
