import { useQuery } from '@tanstack/react-query';
import LabelsAPI from 'services/api/labels.api';

const labelsAPI = new LabelsAPI();
export const LABELS_QUERY_KEY = 'labels';

// TODO: currently implemented with limit=10000, but should be updated to use pagination
export default function useLabels(search?: string) {
  const { data, isLoading, isError } = useQuery({
    queryKey: [LABELS_QUERY_KEY],
    queryFn: () => labelsAPI.getLabels(),
    refetchOnWindowFocus: false,
  });

  const filteredLabels = search ? data?.data.filter((label) => label.startsWith(search)) : data?.data;

  return {
    labels: filteredLabels,
    isLoading,
    isError,
  };
}
