import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Redux
import * as ExperienceActions from 'store/templated-experience/templated-experience.action';
import { saveJourneyInstance, deactivateJourneyInstance, activateJourneyInstance } from 'store/journey/journey.thunk';
import { getIsJourneyActiveOrScheduled, getJourneyStatusLabels, isJourneyDirty } from 'store/journey/journey.selector';
import { journeyStatusNames, journeyStatusTagTypes } from 'store/journey/journey.constants';
import { JOURNEY_STATUSES, JOURNEY_STATUS_LABELS } from 'store/journey/journey.type';
import { getIsAudienceEmpty } from 'store/rule/rule.selector';

// Custom components
import FileAPI from 'services/api/file.api';
import ApprovalStatusData from '../ApprovalStatusData/ApprovalStatusData';
import ApprovalRequestSlideOut from 'components/ExperienceCanvas/ApprovalRequestSlideOut/ApprovalRequestSlideOut';
import { FlightTag, FlightTextInput, FlightTooltip, getIcon } from '@flybits/design-system';

import ConfirmModal, { TConfirmModalProps } from 'components/Modal/ConfirmModal/ConfirmModal';
import InfoDropdown from '../InfoDropdown/InfoDropdown';
import SaveIcon from 'pages/ExperienceCanvas/assets/icons/DraftIcon';
import LaunchIcon from 'pages/ExperienceCanvas/assets/icons/LaunchIcon';
// import FlowIcon from 'pages/ExperienceCanvas/assets/icons/FlowIcon';
import ChevronLeftIcon from 'pages/ExperienceCanvas/assets/icons/ChevronLeftIcon';
import ScheduleIcon from 'pages/ExperienceCanvas/assets/icons/ScheduleIcon';
import DeactivateIcon from 'pages/ExperienceCanvas/assets/icons/DeactivateIcon';
import ErrorIcon1 from 'pages/ExperienceCanvas/assets/icons/ErrorIcon1';
import ErrorIcon from 'pages/ExperienceCanvas/assets/icons/ErrorIcon';

// Custom Hooks
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useThunkDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/reduxHooks';
import useActions from 'hooks/useActions';

// Utils
import { convertDateToTimezone, getWarningOnLaunchScheduled } from 'helpers/templated-experience.helper';

// Types
import { EVENT_KEYS } from 'types/events';
import { ActionTypes } from 'store/actionTypes';
import {
  CONFIRMATION_MODALS_TITLES,
  TExperienceCanvasRouteParams,
  ERROR_TYPES,
  FILE_STATUS,
} from 'pages/ExperienceCanvas/types';
import { JourneyState } from 'store/journey/journey.type';
import { PaginationResponse } from 'interface/shared/api.interface';
import { CtxDataFile } from 'interface/file/file.interface';
import IconUploadPreview from 'components/ExperienceCanvas/IconUploadPreview/IconUploadPreview';
import useApprovalSettings from 'hooks/useApprovalSettings';
import { getNonBlockingErrorCategories, validateJourney } from 'validators/ExperienceCanvas/journey.validator';
import { getBackendJourneyErrors } from 'helpers/error.helper';
import ErrorListModal from 'components/Modal/ErrorListModal/ErrorListModal';
import UnsavedPrompt from 'components/Shared/UnsavedPrompt/UnsavedPrompt';
import { ConfirmationDialogProps, ConfirmationModalTypes } from 'components/Shared/shared.types';
import { ReactComponent as IconLaunchRocket } from 'assets/images/image-launch-rocket.svg';
import { ReactComponent as IconLanguage } from 'assets/icons/language-v2.svg';
import useConfirmModal from 'hooks/useConfirmModal';

type PrimaryHeaderType = {
  journeyInstance: JourneyState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (showLoading?: any) => Promise<void>;
};

const PrimaryHeader = ({ journeyInstance, refetch }: PrimaryHeaderType): JSX.Element | null => {
  const fileAPI = useMemo(() => new FileAPI(), []);

  const { flags } = useFeatureFlag();
  const history = useHistory();
  const { pid: projectId, id: journeyId } = useRouteMatch<TExperienceCanvasRouteParams>().params;

  const teActions = useActions(ExperienceActions);
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [isJourneySaving, setJourneySaving] = useState(false);

  const [isApprovalSlideOutOpen, setIsApprovalSlideOutOpen] = useState(false);

  const toggleIsApprovalSlideOutOpen = () => {
    setIsApprovalSlideOutOpen((prevState) => !prevState);
  };

  // Journey Data
  const journeyStatus: string = journeyInstance.status;
  const isJourneyScheduled = journeyStatus === JOURNEY_STATUSES.SCHEDULED;
  const journeyStatusLabels = useSelector((state) => getJourneyStatusLabels(state));
  const isJourneyActiveOrScheduled = useSelector((state) => getIsJourneyActiveOrScheduled(state.te));
  const filesUUID = useSelector((state) => state.te.journey.metadata?.filesUUID || '');
  const isListBasedTemplate = useSelector(
    (state) =>
      state.te.journey.templateName?.toLowerCase().includes('list-based') ||
      state.te.journey.steps.reduce((prev, curr) => prev || curr.type === 'list-based', false),
  );
  const isDirty = useSelector((state) => isJourneyDirty(state));
  const [experienceIsLaunching, setExperienceIsLaunching] = useState(false);
  const [experienceName, setExperienceName] = useState(journeyInstance?.name || '');
  const [experienceNameError, setExperienceNameError] = useState('');
  const isAudienceEmpty = useSelector((state) => getIsAudienceEmpty(state.te, 0 /* step index is fudged to 0 */));
  const initialConfirmModalProps: TConfirmModalProps = {
    isVisible: false,
    title: '',
    content: <></>,
  };

  const { settings: approvalSettings } = useApprovalSettings();
  // fallback value set to false in case the actual value fails to retrieve from the backend
  const approvalRequired = approvalSettings?.approvalRequired ?? false;
  const isJourneyApprovalRequired = flags['tx_maker_checker'] || approvalRequired;

  const [isLaunching, setIsLaunching] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState({ ...initialConfirmModalProps });

  const nonBlockingErrorCategories = useSelector((state) => getNonBlockingErrorCategories(state));
  const launchConfirmationDialogProps = useMemo(() => {
    const confirmationDialogProps: ConfirmationDialogProps = {
      theme: ConfirmationModalTypes.LAUNCH,
      icon: <IconLaunchRocket />,
      title: 'Launch Experience',
      description: (
        <>
          “Launch” activates this experience for all users. You can <b>deactivate</b> it but <b>not revert to Draft.</b>
        </>
      ),
      primaryAction: {
        value: isAudienceEmpty ? 'Launch to everyone' : 'Launch to target audience',
      },
      secondaryAction: {
        value: 'Cancel',
      },
    };

    if (nonBlockingErrorCategories.has('localization')) {
      confirmationDialogProps.infoBanner = {
        icon: <IconLanguage />,
        title: 'Incomplete language versioning',
        description: 'The other language version isn’t complete. Do you still want to launch?',
      };
    }

    return confirmationDialogProps;
  }, [isAudienceEmpty, nonBlockingErrorCategories]);
  const [LaunchConfirmModal, showLaunchConfirmModal] = useConfirmModal(launchConfirmationDialogProps);

  const isJourneyValid = useSelector((state) => validateJourney(state));
  const isJourneyErrorStatus = useSelector((state) => state.te.journey.status === JOURNEY_STATUSES.ERROR);
  const isJourneyApproved = useSelector((state) => state.te.journey.isApproved);

  const { data: processedCtxFilesRes } = useQuery<PaginationResponse<CtxDataFile>>({
    queryKey: ['ctx-data-file', filesUUID, FILE_STATUS.PROCESSING_SUCCESS],
    queryFn: async () =>
      await fileAPI.getAllCtxDataFiles({
        offset: 0,
        limit: 1,
        tag: filesUUID,
        status: FILE_STATUS.PROCESSING_SUCCESS,
      }),
    enabled: !!filesUUID && isListBasedTemplate,
  });

  const { data: uploadedCtxFilesRes } = useQuery<PaginationResponse<CtxDataFile>>({
    queryKey: ['ctx-data-file', filesUUID, FILE_STATUS.UPLOADED],
    queryFn: async () =>
      await fileAPI.getAllCtxDataFiles({
        offset: 0,
        limit: 1,
        tag: filesUUID,
        status: FILE_STATUS.UPLOADED,
      }),
    enabled: !!filesUUID && isListBasedTemplate,
  });

  const numUploadedFiles = uploadedCtxFilesRes?.data?.length;
  const numProcessedFiles = processedCtxFilesRes?.data?.length;
  const hasPendingFiles = numUploadedFiles ? numUploadedFiles < 1 : numProcessedFiles ? numProcessedFiles < 1 : true;

  // Colors
  const headerIconFill = '#2371f1';
  const headerIconDisabledFill = '#697186';
  const deactivateIconFill = '#FF541E';
  const errorIcon1Fill = '#EB7E24';
  const errorIconFill = '#FF6E46';
  const saveIconFill = experienceNameError.length ? headerIconDisabledFill : headerIconFill;

  const handleClickGoBack = () => {
    history.push(`/project/${projectId}/${window.location.search}`);
  };

  const clearJourney = useCallback(() => {
    dispatch({
      type: ActionTypes.CLEAR_JOURNEY,
    });
  }, [dispatch]);

  const checkExperienceNameUniqueness = useCallback(
    async (experienceName: string) => {
      const trimmedExperienceName = experienceName.trim();

      if (!trimmedExperienceName) {
        setExperienceNameError('Experience name cannot be empty');
        return;
      }
      const { data: _matchingInstances } = await teActions.fetchInstances({
        search: trimmedExperienceName,
        searchExact: true,
      });

      if (!_matchingInstances || _matchingInstances.error) {
        dispatch({
          type: 'SHOW_SNACKBAR',
          payload: { content: 'Something went wrong while retrieving all experiences', type: ERROR_TYPES.ERROR },
        });
        return;
      }

      if (_matchingInstances.length > 0) {
        for (const _instance of _matchingInstances) {
          if (_instance.id !== journeyId) {
            setExperienceNameError('Experience name is not unique');
            dispatch({
              type: 'SHOW_SNACKBAR',
              payload: { content: 'Experience name is not unique', type: ERROR_TYPES.ERROR },
            });
          }
        }
      } else {
        setExperienceNameError('');
      }

      dispatch({
        type: 'UPDATE_JOURNEY',
        payload: {
          name: trimmedExperienceName,
        },
      });
    },
    [dispatch, journeyId, teActions],
  );

  /*
  Once in an error state, leaving by editing a step (FV or Touchpoint) and returning, the
  setExperienceNameError will become empty upon returning to the page. This check, onLoad,
  will return the error iff the error belongs to a duplicate name of an existing journey.
  */
  useEffect(() => {
    checkExperienceNameUniqueness(journeyInstance?.name ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedHandleChangeExperienceName = useMemo(
    () => debounce((experienceName: string) => checkExperienceNameUniqueness(experienceName), 500),
    [checkExperienceNameUniqueness],
  );

  const handleSaveExperience = async () => {
    if (experienceNameError.length) {
      return;
    }

    // maybe the schedule calculations should be abstracted out
    const schedule = journeyInstance?.schedule;
    const activatedAt = journeyInstance?.activatedAt;

    if (isJourneyScheduled && !schedule?.start && !schedule?.end) {
      setConfirmModalProps({
        title: CONFIRMATION_MODALS_TITLES.LAUNCH_IMMEDIATELY,
        isVisible: true,
        content: (
          <>
            <p>This experience does not have a schedule associated with it.</p>
            <p>
              As a result it will launch <strong>immediately</strong> and be visible to your customers.
            </p>
            <p>
              If you wish to add a schedule then click on &quot;<strong>Cancel</strong>&quot; and add a schedule.
            </p>
          </>
        ),
        contentIcon: <LaunchIcon fill={headerIconFill} />,
        primaryButton: {
          name: 'Okay',
          disabled: false,
          onClick: async () => {
            // or should we automatically update this as they remove the schedule?
            dispatch({
              type: 'UPDATE_JOURNEY',
              payload: {
                status: JOURNEY_STATUSES.DRAFT,
              },
            });
            setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false });
          },
        },
      });
    } else if (isJourneyActiveOrScheduled && activatedAt) {
      const activationDate = moment(activatedAt * 1000)?.format('MMM DD, YYYY [at] h:mmA');
      setConfirmModalProps({
        title: CONFIRMATION_MODALS_TITLES.CANT_EDIT_ACTIVE_EXPERIENCE,
        isVisible: true,
        content: (
          <>
            <p>
              Your experience has been active since <strong>{activationDate}</strong>
            </p>
            <p>
              <strong>Live experiences cannot be edited.</strong>
            </p>
            <p>Please Deactivate the experience to make changes to it.</p>
          </>
        ),
        contentIcon: <ErrorIcon1 fill={errorIcon1Fill} />,
        primaryButton: {
          name: 'Okay',
          disabled: false,
          onClick: () => setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false }),
        },
      });
    } else if (journeyStatus === JOURNEY_STATUSES.INACTIVE && journeyStatusLabels.launch.isDisabled) {
      setConfirmModalProps({
        title: CONFIRMATION_MODALS_TITLES.CANT_SAVE_INVALID_EXPERIENCE,
        isVisible: true,
        content: (
          <>
            <p>
              <strong>Inactive experiences must be valid to be saved.</strong>
            </p>
            <p>Please resolve errors all errors before saving.</p>
            <p>Touchpoint errors can be seen by clicking the status footers of their respective cards.</p>
            <p>
              If present, other errors can be seen by clicking the error icon on the top left area of the card canvas.
            </p>
          </>
        ),
        contentIcon: <ErrorIcon1 fill={errorIcon1Fill} />,
        primaryButton: {
          name: 'Okay',
          disabled: false,
          onClick: () => setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false }),
        },
      });
    } else {
      setJourneySaving(true);

      await dispatch(saveJourneyInstance());

      if (journeyInstance.id) {
        refetch(false);
      }

      setJourneySaving(false);
    }
  };

  const handleLaunchExperience = async () => {
    if (
      isLaunching ||
      experienceNameError.length ||
      journeyStatusLabels.launch.isDisabled ||
      (isListBasedTemplate && hasPendingFiles)
    ) {
      return;
    }
    setIsLaunching(true);
    const schedule = journeyInstance?.schedule;
    const {
      startDate,
      endDate,
      newTitle: warningTitle,
      newMessage: warningMessage,
    } = getWarningOnLaunchScheduled(schedule);
    const scheduleStartUnix = convertDateToTimezone(schedule?.start || 0, schedule?.timezone || '');
    const scheduleEndUnix = convertDateToTimezone(schedule?.end || 0, schedule?.timezone || '');
    const currentTimeUnix = moment().unix();

    if (schedule?.start && scheduleStartUnix.local_unix > currentTimeUnix) {
      setConfirmModalProps({
        title: warningTitle,
        isVisible: true,
        isLaunching: true,
        isAudienceEmpty,
        content: (
          <>
            <p>{warningMessage}</p>
            <p>
              <strong>Start Date: </strong> {startDate}
            </p>
            <p>
              <strong>End Date: </strong> {endDate}
            </p>
            {isListBasedTemplate && hasPendingFiles ? (
              <p>
                <strong>This Journey has at least one uploaded file that has not been processed yet.</strong>
              </p>
            ) : null}
          </>
        ),
        primaryButton: {
          name: 'Confirm',
          disabled: false,
          onClick: async () => {
            await dispatch(saveJourneyInstance(JOURNEY_STATUSES.SCHEDULED));
            if (journeyInstance.id) refetch(false);
            setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false });
          },
        },
      });
    } else if (
      (schedule?.start && scheduleStartUnix.local_unix < moment().unix()) ||
      (schedule?.end && scheduleEndUnix.local_unix < currentTimeUnix)
    ) {
      setConfirmModalProps({
        title: 'Expired Schedule',
        isVisible: true,
        content: (
          <>
            <p>You can&apos;t launch an experience with an expired schedule</p>
            <p>To launch, edit your schedule {!schedule.start ? 'to add start date' : 'to a current date/time'}</p>
          </>
        ),
        primaryButton: {
          name: 'Edit Schedule',
          disabled: false,
          // ToDo: Trigger Edit Schedule modal or the Experience Schedule Slide Out (EC-467)
          // ToDo: Connect journey action to Edit Schedule modal primary action onClick handler
          onClick: () => setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false }),
        },
      });
    } else {
      if (flags['tx_localization_v2']) {
        if (await showLaunchConfirmModal()) {
          setExperienceIsLaunching(true);
          if (journeyInstance.isApproved) {
            await dispatch(activateJourneyInstance(journeyInstance.id ?? ''));
          } else {
            await dispatch(saveJourneyInstance(JOURNEY_STATUSES.ACTIVE));
          }
          setExperienceIsLaunching(false);
        }
      } else {
        setConfirmModalProps({
          title: CONFIRMATION_MODALS_TITLES.SAVE_AND_ACTIVATE,
          isVisible: true,
          isLaunching: true,
          isAudienceEmpty,
          content: (
            <>
              <p>
                This experience will save as <strong>Active</strong> and will become visible to your users.
              </p>
              <p>
                You may deactivate it at any time but <strong>cannot revert to Draft</strong>.
              </p>
              {isListBasedTemplate && hasPendingFiles ? (
                <p>
                  <strong>This Journey has at least one uploaded file that has not been processed yet.</strong>
                </p>
              ) : null}
            </>
          ),
          primaryButton: {
            name: 'Confirm',
            disabled: false,
            onClick: async () => {
              setExperienceIsLaunching(true);
              if (journeyInstance.isApproved) {
                await dispatch(activateJourneyInstance(journeyInstance.id ?? ''));
              } else {
                await dispatch(saveJourneyInstance(JOURNEY_STATUSES.ACTIVE));
              }
              setExperienceIsLaunching(false);
              setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false });
            },
          },
          cancelButton: {
            name: 'Cancel',
            onClick: () => setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false }),
          },
        });
      }
    }

    await queryClient.invalidateQueries(['active-contents']);
    setIsLaunching(false);
  };

  const handleDeactivateExperience = async () => {
    setConfirmModalProps({
      title: CONFIRMATION_MODALS_TITLES.DEACTIVATE_EXPERIENCE,
      isVisible: true,
      contentIcon: <DeactivateIcon fill={deactivateIconFill} />,
      content: (
        <>
          <p>You are about to deactivate your experience immediately.</p>
          <p>Once deactivated, it will no longer be visible to your customers according to the schedule.</p>
          <p>Are you sure you would like you deactivate it?</p>
        </>
      ),
      primaryButton: {
        name: 'Confirm',
        disabled: false,
        onClick: async () => {
          await dispatch(deactivateJourneyInstance(journeyInstance.id ?? ''));
          await queryClient.invalidateQueries(['active-contents']);
          setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false });
        },
      },
      cancelButton: {
        name: 'Cancel',
        onClick: () => setConfirmModalProps({ ...initialConfirmModalProps, isVisible: false }),
      },
    });
  };

  const LaunchButtonIcon: React.FC = () => {
    return journeyStatusLabels.launch.statusLabel === JOURNEY_STATUS_LABELS.SCHEDULE ? (
      <ScheduleIcon />
    ) : journeyStatusLabels.launch.statusLabel === JOURNEY_STATUS_LABELS.ERROR ? (
      <ErrorIcon1 />
    ) : (
      <>{getIcon('rocket', { width: '26px', height: '26px' })}</>
    );
  };

  const handleChangeJourneyIcon = (iconUrl: string) => {
    dispatch({
      type: 'UPDATE_JOURNEY',
      payload: {
        metadata: {
          ...journeyInstance?.metadata,
          iconUrl,
        },
      },
    });
  };

  const isFileUploadNeeded = isListBasedTemplate && hasPendingFiles;

  let launchButtonTooltipDesc = 'The experience launch is in progress';
  if (experienceNameError.length) {
    launchButtonTooltipDesc = 'Rename your journey to save/launch';
  } else if (isJourneyApprovalRequired && !isJourneyApproved) {
    launchButtonTooltipDesc = 'You have to send for approval before it can launch';
  } else if (!isJourneyValid) {
    launchButtonTooltipDesc = 'Journey is incomplete. You need to fill out all parts to save/launch';
  } else if (isJourneyErrorStatus) {
    launchButtonTooltipDesc = 'The journey has errored out';
  } else if (isFileUploadNeeded) {
    launchButtonTooltipDesc = 'You need to upload at least one file to launch';
  }

  const isLaunchDisabled =
    isLaunching ||
    experienceNameError.length ||
    (isJourneyApprovalRequired && !isJourneyApproved) ||
    !isJourneyValid ||
    isJourneyErrorStatus ||
    isFileUploadNeeded;

  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const { topLevelErrors } = useSelector((state) => {
    const beErrors = Array.isArray(state.te.journey.errors) ? state.te.journey.errors : [];
    return getBackendJourneyErrors(beErrors, state);
  });

  return (
    <>
      <div className="overview-container__header">
        {/* ---------- EXPERIENCE FEATURES ---------- */}
        <div className="overview-container__header__left-section">
          <div
            className="overview-header-item-container"
            aria-label="go back to experiences listing page"
            tabIndex={0}
            role="button"
            onClick={handleClickGoBack}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === EVENT_KEYS.ENTER) {
                handleClickGoBack();
              }
            }}
          >
            <ChevronLeftIcon fill={headerIconFill} />
          </div>
          {(journeyInstance?.metadata?.iconUrl || flags['tx_demo']) && (
            <div className="overview-header-journey-icon-container">
              <IconUploadPreview
                iconUrl={journeyInstance?.metadata?.iconUrl || ''}
                onSuccess={(iconUrl) => iconUrl && handleChangeJourneyIcon(iconUrl)}
              />
            </div>
          )}
          <div
            aria-label="enter or edit experience name"
            className="overview-container__header__left-section__experience-name-container"
          >
            <FlightTooltip
              direction="bottom"
              description={experienceName || journeyInstance.templateName || '<Please enter the experience name>'}
            >
              <FlightTextInput
                theme={'lite'}
                className="experience-name-input"
                value={experienceName}
                width={'370px'}
                placeholderText={journeyInstance.templateName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const name = e.target.value || '';
                  setExperienceName(name);
                  debouncedHandleChangeExperienceName(name);
                }}
                trailingIconTooltipDescription={experienceNameError}
                hasError={experienceNameError.length || !experienceName}
                label={journeyInstance.templateName}
              />
            </FlightTooltip>
          </div>
          <>
            {/* View Details : TOOLTIP Beside Input box */}
            <InfoDropdown templateName={journeyInstance?.templateName || ''} />

            {/* STATUS */}
            <div
              className="overview-header-status-tag"
              aria-label={`experience status is ${journeyStatusNames[journeyStatus]}`}
              role="status"
            >
              <FlightTag type={journeyStatusTagTypes[journeyStatus]} label={journeyStatusNames[journeyStatus]} />
            </div>
          </>
        </div>
        {/* ---------- EXPERIENCE ACTION BUTTONS ---------- */}
        <div className="overview-container__header__right-section">
          {isJourneyApprovalRequired && <ApprovalStatusData toggleApprovalSlideOut={toggleIsApprovalSlideOutOpen} />}
          <FlightTooltip isEnabled={experienceNameError.length} description={launchButtonTooltipDesc} direction="left">
            <div
              className={`overview-header-btn-with-text ${
                experienceNameError.length || isJourneySaving ? 'overview-header-btn-with-text--disabled' : ''
              }`}
              aria-label={journeyStatusLabels.save.statusLabel}
              tabIndex={0}
              role="button"
              onClick={handleSaveExperience}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  handleSaveExperience();
                }
              }}
            >
              {isJourneySaving ? (
                <span className="overview-header-btn-with-text__loading-icon">{getIcon('loading', {})}</span>
              ) : (
                <SaveIcon ariaHidden={true} fill={saveIconFill} />
              )}
              <span>{journeyStatusLabels.save.statusLabel}</span>
            </div>
          </FlightTooltip>
          {isJourneyActiveOrScheduled ? (
            <div
              className="overview-header-btn-with-text"
              aria-label="deactivate experience"
              tabIndex={0}
              role="button"
              onClick={handleDeactivateExperience}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === EVENT_KEYS.ENTER) {
                  handleDeactivateExperience();
                }
              }}
            >
              <DeactivateIcon fill={headerIconFill} />
              <span>Deactivate</span>
            </div>
          ) : (
            <FlightTooltip isEnabled={isLaunchDisabled} description={launchButtonTooltipDesc} direction="left">
              <div
                className={`overview-header-btn-with-text ${
                  isLaunchDisabled ? 'overview-header-btn-with-text--disabled' : ''
                }`}
                aria-label={journeyStatusLabels.launch.statusLabel}
                tabIndex={0}
                role="button"
                onClick={handleLaunchExperience}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === EVENT_KEYS.ENTER) {
                    handleLaunchExperience();
                  }
                }}
              >
                <LaunchButtonIcon />
                <span>{journeyStatusLabels.launch.statusLabel}</span>
              </div>
            </FlightTooltip>
          )}
          {!!topLevelErrors.length && (
            <button className="overview-header-top-level-error-indicator" onClick={() => setErrorModalVisible(true)}>
              <ErrorIcon className="error-list-modal__list-row-icon" fill={errorIconFill} />
            </button>
          )}
        </div>
      </div>
      <ErrorListModal isVisible={isErrorModalVisible} onClose={() => setErrorModalVisible(false)} />
      <ConfirmModal {...confirmModalProps} setModalProps={setConfirmModalProps} isLoading={experienceIsLaunching} />
      <LaunchConfirmModal />
      <UnsavedPrompt
        when={isDirty}
        unblockPaths={['/overview', '/flow-builder', '/touchpoint']}
        dialogProps={{
          description: 'This will undo all the information you have entered since you started.',
        }}
        callback={clearJourney}
      />
      <ApprovalRequestSlideOut
        isOpen={isApprovalSlideOutOpen}
        onClickClose={toggleIsApprovalSlideOutOpen}
        refetch={refetch}
      />
    </>
  );
};

export default PrimaryHeader;
