export enum ConfirmationModalTypes {
  DELETE = 'delete',
  LAUNCH = 'launch',
}

export enum ConfirmationModalActionStyles {
  ROW = 'row',
  COLUMN = 'column',
}

export interface ConfirmationModalProps extends React.ComponentProps<'dialog'> {
  isOpen: boolean;
  closeModal: () => void;
  theme?: ConfirmationModalTypes;
  icon: JSX.Element;
  title: string;
  description: string | JSX.Element;
  primaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
  secondaryAction: {
    value: string;
    onClickHandler?: () => void;
  };
  isLoading?: boolean;
  confirmationInput?: {
    placeholderText: string;
    matchText: string;
    label?: string | JSX.Element;
  };
  className?: string;
  actionStyle?: ConfirmationModalActionStyles;
  warningMessage?: string;
  infoBanner?: {
    icon?: JSX.Element;
    title: string;
    description: string;
  };
}

export type ConfirmationDialogProps = Omit<ConfirmationModalProps, 'isOpen' | 'closeModal'>;
