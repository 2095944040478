import { InferType } from 'yup';
import {
  MerchantValidationSchema,
  OfferScheduleValidationSchema,
  OfferValidationSchema,
} from './merchant-portal.constants';

export type MerchantsRouteParams = Partial<{
  pid: string;
  merchantid: string;
  offerid: string;
}>;

export type MerchantTableHeader =
  | {
      key: keyof Merchant;
      name: string;
      hideTooltip: boolean;
      isVisible: boolean;
      isSortable: boolean;
    }
  | {
      key: 'data';
      isVisible: boolean;
    };

export type MerchantTableData = {
  key: string;
  data: Merchant;
  name: React.JSX.Element;
  category: string | undefined;
  verificationStatus: React.JSX.Element;
  offers: React.JSX.Element;
};

export enum MERCHANT_VERIFICATION_STATUSES {
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
  DELETED = 'deleted',
  PENDING_DELETION = 'pending-deletion',
}

export enum OFFER_VERIFICATION_STATUSES {
  PENDING = 'pending',
  VERIFIED = 'verified',
  FAILED = 'failed',
}

export enum OFFER_STATUSES {
  INACTIVE = 'inactive',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  ACTIVE = 'active',
  ERROR = 'error',
}

export type Offer = {
  id: string;
  merchantId: string;
  title: string;
  description: string;
  additionalNote: string;
  verificationStatus: OFFER_VERIFICATION_STATUSES;
  spendThreshold: number;
  earnCap: number;
  notes: string;
  desiredStartDate: EpochTimeStamp;
  desiredEndDate: EpochTimeStamp;
  targetBudget: number;
  budgetConsumed: number;
  budgetNotes: string;
  journeyTemplateId?: string;
  journeyInstanceId?: string;
  journeyInstanceStatus?: OFFER_STATUSES;
  createdAt?: EpochTimeStamp;
  updatedAt?: EpochTimeStamp;
  createdBy?: string;
  // Not available in backend yet
  type?: string;
  redemptionLimit?: string;
};

export type Merchant = {
  id: string;
  name: string;
  externalId: string;
  category: string;
  logoUrl: string;
  phoneNumber: string;
  addressLine: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressZipCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactEmail: string;
  verificationStatus: MERCHANT_VERIFICATION_STATUSES;
  notes: string;
  createdAt?: EpochTimeStamp;
  updatedAt?: EpochTimeStamp;
  createdBy?: string;
  offers?: Offer[];
};

export type MerchantCategory = {
  id: string;
  name: string;
  description: string;
};

export type MerchantCreationPayload = Omit<Merchant, 'id' | 'offers'>;
export type OfferCreationPayload = Omit<Offer, 'id' | 'verificationStatus' | 'type' | 'merchantId' | 'budgetConsumed'>;
export type MerchantAndOfferCreationPayload = Omit<MerchantCreationPayload, 'offers'> & {
  offers: OfferCreationPayload[];
};

export type MerchantUpdatePayload = Omit<Merchant, 'createdAt' | 'updatedAt' | 'createdBy'>;
export type OfferUpdatePayload = Omit<Offer, 'budgetConsumed' | 'createdAt' | 'updatedAt' | 'createdBy'>;

export type MerchantFormValues = InferType<typeof MerchantValidationSchema>;

export type OfferFormValues = InferType<typeof OfferValidationSchema>;
export type OfferScheduleFormValues = InferType<typeof OfferScheduleValidationSchema>;
export interface OfferAllFormValues {
  offer: OfferFormValues;
  schedule: OfferScheduleFormValues;
}

export enum STATUS_ENTITIES {
  MERCHANT_VERIFICATION_STATUS = 'merchant-verification-status',
  OFFER_VERIFICATION_STATUS = 'offer-verification-status',
  OFFER_STATUS = 'offer-status',
}

export type STATUS_INFO = {
  [key in MERCHANT_VERIFICATION_STATUSES | OFFER_VERIFICATION_STATUSES | OFFER_STATUSES]: {
    title: string;
    description: string;
  };
};

export type STATUS_MAP_TYPE = {
  [key in STATUS_ENTITIES]: STATUS_INFO;
};
