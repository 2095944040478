import { useEffect } from 'react';
import { getCurrentTimestampInMs } from 'helpers/common.helper';

type UseDownloadCSV = {
  data: Record<string, string>[];
  filenamePrefix: string;
};

const useDownloadCSV = ({ data, filenamePrefix }: UseDownloadCSV) => {
  useEffect(() => {
    if (!data || !data.length) {
      return;
    }

    // Convert JSON data to CSV
    const csvHeaders = Object.keys(data[0]).join(','); // Use keys of the first object as headers
    const csvRows = data.map((row) =>
      Object.values(row)
        .map((value) => (typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value)) // Escape quotes in strings
        .join(','),
    );

    const csvContent = [csvHeaders, ...csvRows].join('\n');

    // Create a downloadable blob
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a temporary anchor link for download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filenamePrefix}_data_${getCurrentTimestampInMs()}.csv`);
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    return () => {
      // Clean up temporary anchor link
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };
  }, [data, filenamePrefix]);
};

export default useDownloadCSV;
