import './CustomAnalyticsDashboard.scss';
import React from 'react';
import { ReactComponent as AddGraphImage } from 'assets/images/experience-analytics.svg';
import { FlightButton } from '@flybits/design-system';
import { useHistory, useParams } from 'react-router-dom';
import GraphTile from './GraphTile/GraphTile';
import BarDataMock from './mocks/bar-graph-data.json';
import LineDataMock from './mocks/line-graph-data.json';
import TableDataMock from './mocks/table-graph.json';
import { VISUALIZER_OPTIONS } from '../CreateOrEditChart/constants';
import useExperienceGraphsData from 'pages/AnalyticsV2/CustomAnalytics/hooks/useExperienceGraphsData';
import { genUUID } from 'helpers/common.helper';
import { TAnalyticsRouteParams } from 'pages/AnalyticsV2/types';

// CLASSES
const CLASS_MAIN = 'custom-analytics-dashboard';
const CLASSES = {
  BODY: `${CLASS_MAIN}__body`,
  GRID: `${CLASS_MAIN}__grid`,
  TILE: `${CLASS_MAIN}__tile`,
  TILE_ADD_CHART: `${CLASS_MAIN}__tile__add-chart`,
  CHART: `${CLASS_MAIN}__chart`,
  ADD_CHART: `${CLASS_MAIN}__add-chart`,
};
const getValidGraphType = (graphType: string) => {
  switch (graphType.toLowerCase()) {
    case VISUALIZER_OPTIONS.BAR:
      return VISUALIZER_OPTIONS.BAR;
    case VISUALIZER_OPTIONS.LINE:
      return VISUALIZER_OPTIONS.LINE;
    case VISUALIZER_OPTIONS.TABLE:
      return VISUALIZER_OPTIONS.TABLE;
    default:
      return VISUALIZER_OPTIONS.UNSUPORTED;
  }
};
function CustomAnalyticsDashboard() {
  const history = useHistory();
  const { pid } = useParams<TAnalyticsRouteParams>();

  const { graphs, graphsData } = useExperienceGraphsData();

  return (
    <main className={CLASSES.BODY}>
      <div className={CLASSES.GRID}>
        {graphs
          // Most recently updated order
          .sort((graphA, graphB) => graphB.updatedAt - graphA.updatedAt)
          .map((graph, index) => (
            <div key={graph.id} className={CLASSES.TILE}>
              <GraphTile
                id={graph.id}
                title={graph.name}
                description={graph.description}
                graphType={getValidGraphType(graph.chartConfig.type)}
                graphData={graphsData[index]}
              />
            </div>
          ))}

        {/* Mock graphs */}
        <div className={CLASSES.TILE}>
          <GraphTile
            id={genUUID()}
            title="This mock bar graph has a very long title, it is huge, great title, it will certain overflow for sure! but just in case it does not overflow I will type more"
            description="The mock bar graph description is also very long, so long that it will overflow so I need to keep adding characters until I'm certain it had overflown"
            graphType={VISUALIZER_OPTIONS.BAR}
            graphData={BarDataMock}
          />
        </div>
        <div className={CLASSES.TILE}>
          <GraphTile
            id={genUUID()}
            title="Mock line graph"
            description="Mock line graph description"
            graphType={VISUALIZER_OPTIONS.LINE}
            graphData={LineDataMock}
          />
        </div>
        <div className={CLASSES.TILE}>
          <GraphTile
            id={genUUID()}
            title="Mock table graph"
            description="Mock Push reach over the past 7 days based on experience name"
            graphType={VISUALIZER_OPTIONS.TABLE}
            graphData={TableDataMock}
          />
        </div>
        {/* Mock graphs */}

        <div className={CLASSES.TILE_ADD_CHART}>
          <div className={CLASSES.ADD_CHART}>
            <AddGraphImage />
            <h6>Experience Analytics</h6>
            <p>Track metrics across different experiences to see how they’re performing.</p>
            <FlightButton
              label="Add Chart"
              iconLeft="add"
              size="small"
              onClick={() => history.push(`/project/${pid}/analytics/v2/experiences/create`)}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default CustomAnalyticsDashboard;
